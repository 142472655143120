import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Popper,
  Box,
  LinearProgress,
  IconButton,
  Typography,
  Tooltip
} from '@material-ui/core';
import { Delete, InsertDriveFile } from '@material-ui/icons';
import Button from 'GVComponents/Button';
import ReportIcon from '@material-ui/icons/Report';
import SubjectIcon from '@material-ui/icons/Subject';

const useStyles = makeStyles(theme => ({
  fileLine: {
    display: 'grid',
    gridTemplateColumns: '10% 20% 35% 5% 20% 10%',
    gridTemplateRows: '2rem',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center'
  },
  fileIcon: {
    gridColumn: '1',
    fontSize: '1rem'
  },
  fileName: {
    gridColumn: '2'
  },
  fileState: {
    gridColumn: '3'
  },
  fileProgressBar: {
    gridColumn: '3'
  },
  fileProgress: {
    gridColumn: '4'
  },
  wordCount: {
    gridColumn: '5',
    textAlign: 'center'
  },
  deleteButton: {
    gridColumn: '6',
    marginLeft: 'auto',
    '&:hover > *': {
      color: '#f48335',
      transition: '0.15s'
    }
  },
  deleteIcon: {
    transition: '0.5s'
  },
  deleteContainer: {
    borderRadius: '0',
    padding: '1vmin'
  },
  fileSeperator: {
    gridColumn: '1/6',
    margin: '0.5vh',
    width: '100%',
    borderBottom: '1px solid #DADCE0'
  }
}));

export default function LeanFileLine(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDelete, setOpenDelete] = useState(false);

  
  const toggleOpenDelete = event => {
    setAnchorEl(event.currentTarget);
    setOpenDelete(!openDelete);
  };

  return (
    <Box className={classes.fileLine}>
      {props.fileDescriptor.isDocument == false ? <InsertDriveFile className={classes.fileIcon} /> : <SubjectIcon/> }
      <Typography className={classes.fileName}>
        {props.fileDescriptor.file.name}
      </Typography>
      <Box display="flex" flexDirection="column">
        {props.fileDescriptor.state !== 'Complete' ? (
          <Typography varient="body2" className={classes.fileState}>
            {props.fileDescriptor.state}
          </Typography>
        ) : null}
        {(() => {
          switch (props.fileDescriptor.state) {
            case 'Uploading':
              return (
                <LinearProgress
                  className={classes.fileProgressBar}
                  variant="determinate"
                  value={props.fileDescriptor.progress}
                />
              );
            case 'Processing':
              return <LinearProgress className={classes.fileProgressBar} />;
            case 'Complete':
            default:
              return null;
          }
        })()}
      </Box>
      {props.fileDescriptor.state === 'Uploading' ? (
        <Typography varient="body2" className={classes.fileProgress}>
          {props.fileDescriptor.progress + '%'}
        </Typography>
      ) : null}
      <Typography className={classes.wordCount}>
      {props.fileDescriptor.hasUnselectables === true ? (
        <Tooltip title="This file has unselectable areas">
        <ReportIcon  />
        </Tooltip>
      ) : null}
      {props.fileDescriptor.hasUnselectables === true ? (
        <span>&nbsp;&nbsp;</span>
      ) : null }
        {(props.fileDescriptor.wordCount !== -1 && props.fileDescriptor.wordCount != 0) ? (
          props.fileDescriptor.wordCount + ' words' + ' (' + props.fileDescriptor.detectedLanguage.toUpperCase() + ') '
          
        ) : (
          props.fileDescriptor.detectedLanguage.toUpperCase()
        )}
       
      </Typography>
      <IconButton className={classes.deleteButton} onClick={toggleOpenDelete}>
        <Delete className={classes.deleteIcon} />
      </IconButton>
      <Popper
        placement="bottom"
        anchorEl={anchorEl}
        disablePortal={false}
        open={openDelete}
        modifiers={{
          flip: {
            enabled: false
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent'
          }
        }}
        transition
      >
        <Paper elevation={4} className={classes.deleteContainer}>
          <Typography>
            Are you sure you want to delete {props.fileDescriptor.fileName}?
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            marginTop="1vmin"
            justifyItems="space-evenly"
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={toggleOpenDelete}
              styles={{ marginRight: '1vmin' }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => props.onDelete(props.fileDescriptor.id)}
              disableElevation
            >
              Delete File
            </Button>
          </Box>
        </Paper>
      </Popper>
      <div className={classes.fileSeperator}></div>
    </Box>
  );
}
