import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Typography, Box, Radio } from '@material-ui/core';
import DateTimeSelect from 'components/dateTimeSelect';

const useStyles = makeStyles(() => ({
  dateContainer: {
    borderRadius: '0',
    marginBottom: '2vh',
    padding: '1vmin',
    width: '40vw'
  },
  dateContainerSelect: {
    backgroundColor: 'rgb(211, 211, 211)'
  },
  dateContainerUnSelected: {
    backgroundColor: 'rgba(211, 211, 211, 0.5)',
    cursor: 'pointer'
  }
}));

export default function Deadline({ onDateTimeChange, onTimezoneChange }) {
  const classes = useStyles();
  const [autoDeadline, setAutoDeadline] = useState(true);
  const [deadlineDate, setDeadlineDate] = useState(null);
  const [timezone, setTimezone] = useState('');

  useEffect(() => {
    if (autoDeadline) {
      onDateTimeChange(null);
      return;
    }
    onDateTimeChange(deadlineDate);
    onTimezoneChange(timezone);
  }, [autoDeadline, deadlineDate, timezone]);

  return (
    <Box
      display="flex"
      flexGrow={1}
      flexDirection="column"
      justifyContent="center"
    >
      <Box
        className={clsx(
          classes.dateContainer,
          { [classes.dateContainerUnSelected]: !autoDeadline },
          { [classes.dateContainerSelect]: autoDeadline }
        )}
        onClick={() => {
          setAutoDeadline(true);
        }}
      >
        <Box display="flex" direction="row" alignItems="center">
          <Radio
            checked={autoDeadline}
            onChange={() => setAutoDeadline(true)}
            label="Auto"
          />
          <Typography variant="body1">Auto (best price)</Typography>
        </Box>
      </Box>
      <Box
        className={clsx(
          classes.dateContainer,
          {
            [classes.dateContainerUnSelected]: autoDeadline
          },
          {
            [classes.dateContainerSelect]: !autoDeadline
          }
        )}
        onClick={() => {
          setAutoDeadline(false);
        }}
      >
        <Box display="flex" direction="row" alignItems="center">
          <Radio
            checked={!autoDeadline}
            onChange={() => setAutoDeadline(false)}
            label="Delivery Guarenteed by"
          />
          <Typography variant="body1">Client delivery date</Typography>
        </Box>
        <DateTimeSelect
          onDateTimeChange={setDeadlineDate}
          onTimezoneChange={setTimezone}
          disabled={autoDeadline}
        />
      </Box>
    </Box>
  );
}
