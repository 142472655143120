import React, { Component } from 'react';
import universalStyles from "../../css/universalZ.module.css";
import serviceLevelStyles from "../../css/serviceLevelZ.module.css";
import Amplify, { API } from 'aws-amplify';
// import {IconContext} from "react-icons";
// import {FaCheck} from "react-icons/fa";

class LeanServiceLevel extends Component {
    state = {
        // levels: [
        //     {
        //         heading: "Standard",
        //         subHeading: "Fast Translation by native speakers", 
        //         specs: ["Dedicated Account Manager available 24/7", "Translation by mother tongue linguist and quality review", 
        //                 "Translation memory (TM) and glossary updates"],
        //         price: "259.67"
        //     }, {
        //         heading: "Professional",
        //         subHeading: "Professional quality translation", 
        //         specs: ["Dedicated Account Manager available 24/7", "Translation by mother tongue linguist and quality review", 
        //                 "Translation memory (TM) and glossary updates", "Formatting and layout", "Access to client portal *My Donut*"],
        //         price: "359.20"
        //     }, {
        //         heading: "Premium",
        //         subHeading: "Professional plus extra proofreading", 
        //         specs: ["Dedicated Account Manager available 24/7", "Translation by mother tongue linguist and quality review", 
        //                 "Translation memory (TM) and glossary updates", "Formatting and layout", "Access to client portal *My Donut*", 
        //                 "Proofreading by a second mother tongue linguist", "Desktop Publishing", "Certification by company or translator"],
        //         price: "552.41"
        //     }
        // ],
        recomended: "Professional",
        services: [],
        selectedItem: "",
        serviceIcons: {
            1: 'fas fa-cogs',
            2: 'far fa-edit',
            3: 'far fa-smile',
            4: 'fas fa-glasses'
          },
        lineItems: [],
        price: ["TBD", "TBD", "TBD"]
     }

     retrieveServices = async () => {
        const apiName = 'TransportAPI';
        const path = '/Test/order-services/servicelevel';
        const session = await Amplify.Auth.currentSession();
        const token = session.idToken.jwtToken;
        API.get(apiName, path, { headers: { Authorization: token }, 
          queryStringParameters: { package_type_id: 2 } })
          .then(response => {
            console.log(response);
            this.setState({
                services: response
            }, () => this.getLines());
          })
          .catch(error => {
            console.log(error);
          });
      };

      getPrice = async () => {

        let prices = [[],[],[]];
          let tl = "";
          //Loop through all of the selected target languages and create a list
        for (let i=0;i<this.props.quote.target_languages.length;i++){
          tl = tl + this.props.quote.target_languages.map(
            language => language.isocode + ','
          )[i];
        }

        // Prep the APU call
        const apiName = 'TransportAPI';
        const path = '/Test/order-services/salesorder/pricing';
        const session = await Amplify.Auth.currentSession();
        const token = session.idToken.jwtToken;
        let dt = null;     
            
        if (this.props.quote.deadline_date != null) dt = this.props.quote.deadline_date.toString();
        
        console.log (dt);

        for (let i=0;i<3;i++){
            console.log ("calling ");

        API.get(apiName, path, { headers: { Authorization: token }, 
            queryStringParameters: { 
                // Pass in the quote request settings that effect the price
                source_language: this.props.quote.source_language.map(
                    language => language.isocode
                  )[0],
                target_language : tl,
                rule_group_id : null,
                deadline_date : dt,
                word_count : this.props.quote.word_count,
                rep_count: null,
                price : null, 
                servicelevel_id : i+5,
                company_id: null,
                content_type: this.props.quote.content_type && this.props.quote.content_type.content_type_name } })
            .then(response => {
                console.log (response);
                // Force the price to show 2 decimal places
                //var result = JSON.parse(response);
                console.log (response);
                prices[i] = (Math.round(response.price * 100) / 100).toFixed(2);
                this.setState({
                    price: prices
                })
                
                if (this.props.quote.deadline_date === null)
                    {
                        this.props.onChangeDate(response.deadline_date);
                    }

                this.setState({
                        recomended: response.rec_service_level
                });
                
            })
          .catch(error => {
            console.log(error);
          });
        };
      };

      getLines = async () =>{
          let lineItems= [[], [], []];
          for (let i=0;i<this.state.services.length;i++){
            this.getLineItems(this.state.services[i].id)
            .then(response => {
               lineItems[i] = response;
               this.setState({
                lineItems: lineItems
            });
            })
            .catch(error => {
                console.log(error);
                return error;
            });
          }
      }

      getLineItems = async (packageid) => {
        const apiName = 'TransportAPI';
        const path = '/Test/order-services/servicelevel/features';
        const session = await Amplify.Auth.currentSession();
        const token = session.idToken.jwtToken;
        return API.get(apiName, path, {
          headers: { Authorization: token },
          queryStringParameters: { packageid: packageid }
        });
      };


      updateSelectedItem = newSelectedItem => {
        if (newSelectedItem.disabled !== true) {
          if (this.state.selectedItem === newSelectedItem) {
            return;
          }
          this.setState({
              selectedItem: newSelectedItem
          });
          
          this.props.onChange(newSelectedItem);
          this.props.onChangePrice(this.state.price[newSelectedItem.id-5]);
        }
      };

    handleSelectedIndex = index => {
        if (this.state.selectedIndex !== index){
            this.setState({
                selectedIndex: index
            });
        }
        else{
            this.setState({
                selectedIndex: 0
            });
        }
    }

    giveList = (index) => {
        if (this.state.lineItems[index]){
            let li = [];
        for (let i=0;i<this.state.lineItems[index].length;i++){
            li.push(
                <li key={this.state.lineItems[index][i].feature_desc}  className={`pb-2 ${serviceLevelStyles.li} ${universalStyles.clGrey}`}>{this.state.lineItems[index][i].feature_desc}</li>
            )
        }
        return li;
        }
        else {
            return null;
        }
    }

    componentDidMount(){
        this.retrieveServices();
        if (this.props.quote.has_unselectables == false) this.getPrice();
    }
    
    render() { 
        return ( 
            <div>
                <div>
                    <h1 className={`${serviceLevelStyles.mainHeading} ${universalStyles.clBlue}`}>Select the content type of the translation and we will recomend you the best service option!</h1>
                </div>
                <div className={`${serviceLevelStyles.mainContainer} d-flex justify-content-center`}>
                    <div className={`${serviceLevelStyles.levelsParentContainer} d-flex flex-column flex-md-row justify-content-md-between justify-content-lg-around`}>
                        {this.state.services.map((service, index) => {
                            return (
                                <div key={service.package_name} className={`${serviceLevelStyles.levelsContainer}`}>
                                    <div className={`d-flex justify-content-center p-2 ${this.state.recomended === service.package_name ? serviceLevelStyles.recomended : universalStyles.clWhite}`}>
                                        <div>Recommended</div>
                                    </div>
                                    <div className={`d-flex flex-column ${this.state.recomended === service.package_name ? serviceLevelStyles.recomendedLevel:null} ${serviceLevelStyles.belowRecomendContainer}`}> 
                                        <div className={`mb-auto ${serviceLevelStyles.headingContainer}`}>
                                            <label className={`radio-inline mt-2 ml-2`}><input className={`${serviceLevelStyles.radio}`} type="radio" name="optradio" onClick={() => {this.updateSelectedItem({
                                                id: service.id,
                                                label: service.package_name,
                                                tooltip: service.package_desc,
                                                icon: this.state.serviceIcons[1]
                                            });}}/><span className={`ml-2 font-weight-bold ${universalStyles.clBlue} ${serviceLevelStyles.heading}`}>{service.package_name}</span></label>
                                            <div className={`${serviceLevelStyles.subHeadingContainer} ${universalStyles.clGrey}`}>
                                                {service.package_desc}
                                            </div>
                                            <div className={`${serviceLevelStyles.specContainer}`}>
                                                <ul>
                                                    {
                                                        this.giveList(index)
                                                    }
                                                </ul>
                                            </div>
                                        </div>    
                                        <div className={`d-flex justify-content-center ${serviceLevelStyles.priceContainer}`}>
                                            <div className={`${serviceLevelStyles.price} ${universalStyles.clBlue}`}>&pound;{this.state.price[index]}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
         );
    }
}
 
export default LeanServiceLevel;