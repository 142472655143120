import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/fr';
import enCommon from 'locales/en/common';
import frCommon from 'locales/fr/common';
import enAuthentication from 'locales/en/authentication';
import frAuthentication from 'locales/fr/authentication';
import enValidation from 'locales/en/validation';
import frValidation from 'locales/fr/validation';
import enGlossary from 'locales/en/glossary';
import frGlossary from 'locales/fr/glossary';
import enNav from 'locales/en/nav';
import frNav from 'locales/fr/nav';
import enDashboard from 'locales/en/dashboard';
import frDashboard from 'locales/fr/dashboard';
import enContactInformation from 'locales/en/contactInformation';
import frContactInformation from 'locales/fr/contactInformation';
import enQuote from 'locales/en/quote';
import frQuote from 'locales/fr/quote';
import enFileUpload from 'locales/en/fileUpload';
import frFileUpload from 'locales/fr/fileUpload';
import enProject from 'locales/en/project';
/*
      fr: {
        common: frCommon,
        glossary: frGlossary,
        nav: frNav,
        authentication: frAuthentication,
        validation: frValidation,
        dashboard: frDashboard,
        contactInformation: frContactInformation,
        quote: frQuote,
        fileUpload: frFileUpload
      },
 */
export default i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        common: enCommon,
        glossary: enGlossary,
        nav: enNav,
        authentication: enAuthentication,
        validation: enValidation,
        dashboard: enDashboard,
        contactInformation: enContactInformation,
        quote: enQuote,
        fileUpload: enFileUpload,
        project: enProject
      }
    },
    fallbackLng: 'en',
    debug: process.env.REACT_APP_I18N_DEBUG,

    // have a common namespace used around the full app
    ns: ['common', 'authentication', 'validation'],
    defaultNS: 'common',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });
moment.locale(i18n.language);
