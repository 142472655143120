import React, { useState, useEffect } from 'react';
import Amplify, { API } from 'aws-amplify';
import {
  Work,
  LocalCafe,
  SentimentVerySatisfied,
  Business
} from '@material-ui/icons';
import BigRadio from 'components/bigRadio.js';

export default function Tone({ selectedTone, ...props }) {
  const [toneIcons] = useState({
    1: <LocalCafe />,
    2: <Work />,
    3: <SentimentVerySatisfied />,
    4: <Business />
  });
  const [tones, setTones] = useState([]);

  const items = () => {
    return tones.map(tone => {
      return {
        id: tone.id,
        label: tone.tone_name,
        icon: toneIcons[tone.id]
      };
    });
  };

  const retrieveTones = async () => {
    const apiName = 'TransportAPI';
    const path = '/Test/language-services/tone';
    const session = await Amplify.Auth.currentSession();
    const token = session.idToken.jwtToken;
    API.get(apiName, path, { headers: { Authorization: token } })
      .then(response => {
        console.log(response);
        setTones(response);
      })
      .catch(error => {
        console.log(error.response);
      });
  };

  useEffect(() => {
    if (tones.length === 0) {
      retrieveTones();
    }
  });

  return <BigRadio selectedItem={selectedTone} items={items()} {...props} />;
}
