import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Typography,
  Button,
  InputAdornment,
  LinearProgress
} from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { MailOutline } from '@material-ui/icons';
import Authentication from 'views/generic/authentication';
import AuthenticationForm from 'components/authenticationForm';
import AuthenticationCompleted from 'components/authenticationCompleted';

export default function Login() {
  const { t } = useTranslation();
  let history = useHistory();

  return (
    <Authentication>
      <Formik
        initialValues={{
          email: ''
        }}
        initialStatus={{ completed: false }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(t('validation:enterValidEmail'))
            .required(t('validation:emailRequired'))
        })}
        onSubmit={async ({ email }, { setSubmitting, setStatus }) => {
          try {
            await Auth.forgotPassword(email);
            setStatus({ completed: true });
          } catch (err) {
            console.log(err);
          }
          setSubmitting(false);
        }}
      >
        {({ status, isSubmitting }) => (
          <AuthenticationForm
            completed={status.completed}
            completedView={
              <AuthenticationCompleted
                title={t('authentication:checkEmail')}
                icon={<MailOutline fontSize="large" />}
                message={t('authentication:checkEmailReset')}
                buttonText={t('authentication:login')}
                redirectTo="/login"
              />
            }
          >
            <Typography color="primary" variant="h2">
              <Trans i18nKey="authentication:forgotPasswordHeader" />
            </Typography>
            <TextField
              name="email"
              id="outlined-textarea"
              label={t('authentication:email')}
              type="email"
              color="primary"
              margin="normal"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutline />
                  </InputAdornment>
                )
              }}
            />
            {isSubmitting && <LinearProgress />}
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              type="submit"
            >
              <Trans i18nKey="authentication:sendRecoveryEmail" />
            </Button>
            <Button color="secondary" onClick={() => history.push('/login')}>
              <Trans i18nKey="authentication:login" />
            </Button>
          </AuthenticationForm>
        )}
      </Formik>
    </Authentication>
  );
}
