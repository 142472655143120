import React, { Component } from "react";
// import Order from "./order";
import universalStyles from "../css/universal.module.css";
import CustomizedInputs from "./billing";
import { Grid } from "@material-ui/core";

class Root extends Component {
  state = {};
  render() {
    return (
<div>
{/* <Grid container lg={2} xs={2}>
  <Order />
</Grid> */}
<Grid
  container
  lg={12}
  xs={12}
  style={{
    padding: "2vh",
    // margin: "4vh",
    backgroundColor: "#fff",
    // borderRadius: "10px"
  }}
>
  <CustomizedInputs />
</Grid>
</div>
    );
  }
}

export default Root;
