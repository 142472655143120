import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import LanguageSwitcher from 'components/languageSwitcher';
import logo from 'media/img/logo_global.svg';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    width: '100vw',
    height: '100vh'
  },
  authentcationContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '5vmin',
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'space-evenly'
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  logoSection: {
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      height: '20vh',
      padding: theme.spacing(2)
    },
    [theme.breakpoints.up('md')]: {
      flex: 1,
      width: '50%'
    }
  },
  authentcationSection: {
    [theme.breakpoints.up('md')]: {
      flex: 1
    }
  },
  loginInputContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('md')]: {
      maxWidth: '50vh'
    }
  }
}));
export default function Authentication({ children }) {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.root}>
        <div>
          {/*<LanguageSwitcher className={classes.languageSwitcher} />*/}
        </div>
        <div className={classes.authentcationContainer}>
          <img
            className={classes.logoSection}
            src={logo}
            alt={t('common:globalVoicesLogoAlt')}
          />
          <section className={classes.authentcationSection}>
            <div className={classes.loginInputContainer}>{children}</div>
          </section>
        </div>
      </div>
    </React.Fragment>
  );
}
