import React, { useState, useEffect } from 'react';
import Amplify, { API } from 'aws-amplify';
import { loadCSS } from 'fg-loadcss';
import BigRadio from 'components/bigRadio.js';

export default function ServiceSelect(props) {
  const [services, setServices] = useState([]);
  const [serviceIcons] = useState({
    1: 'fas fa-align-left',
    2: 'far fa-comment-alt',
    3: 'far fa-comment-alt',
    4: 'fas fa-play',
    5: 'fas fa-search'
  });

  useEffect(() => {
    if (services.length === 0) {
      retrieveServices();
    }
  });

  useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
      document.querySelector('#font-awesome-css')
    );
  }, []);

  const retrieveServices = async () => {
    const apiName = 'TransportAPI';
    const path = '/Test/order-services/ordertype';
    const session = await Amplify.Auth.currentSession();
    const token = session.idToken.jwtToken;
    API.get(apiName, path, { headers: { Authorization: token } })
      .then(response => {
        console.log(response);
        setServices(response);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const items = () => {
    return services.map(service => {
      return {
        id: service.id,
        label: service.ordertype_name,
        tooltip: service.ordertype_desc,
        icon: serviceIcons[service.id],
        disabled: service.id != 1
      };
    });
  };

  return <BigRadio items={items} {...props} />;
}
