import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Router from 'Router';
import MuseoSerif300Font from '../media/fonts/museoserif300.woff2';
import MuseoSerif700Font from '../media/fonts/museoserif700.woff2';
import MuseoSans300Font from '../media/fonts/museosans300.woff2';
import MuseoSans700Font from '../media/fonts/museosans700.woff2';

const museoSerif300 = {
  fontFamily: 'museo',
  fontStyle: 'normal',
  fontDisplay: 'auto',
  fontWeight: 300,
  src: `
    url(${MuseoSerif300Font}) format('woff2')
  `
};

/*
const museoSerif700 = {
  fontFamily: 'museo',
  fontStyle: 'normal',
  fontDisplay: 'auto',
  fontWeight: 700,
  src: `
    url(${MuseoSerif700Font}) format('woff2')
  `,
};
*/

const museoSans300 = {
  fontFamily: 'museo-sans',
  fontStyle: 'normal',
  fontDisplay: 'auto',
  fontWeight: 300,
  src: `
    url(${MuseoSans300Font}) format('woff2')
  `
};
/*
const museoSans700 = {
  fontFamily: 'museo-sans',
  fontStyle: 'normal',
  fontDisplay: 'auto',
  fontWeight: 700,
  src: `
    url(${MuseoSans700Font}) format('woff2')
  `,
};
*/
const theme = createMuiTheme({
  palette: {
    primary: { main: '#263e80' },
    secondary: { main: '#f48335', contrastText: '#FFFFFF' },
    primaryAltBackColor: { main: '#66CD00', contrastText: '#FFFFFF' },
    info: { main: '#ffffff' }
  },

  typography: {
    fontFamily: 'museo-sans,sans-serif',
    fontSize: 12,
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    h1: {
      fontFamily: 'museo,serif'
    },
    h2: {
      fontFamily: 'museo,serif'
    },
    h3: {
      fontFamily: 'museo,serif'
    },
    h4: {
      fontFamily: 'museo,serif'
    },
    h5: {
      fontFamily: 'museo,serif'
    },
    h6: {
      fontFamily: 'museo,serif'
    }
  },

  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [museoSans300, museoSerif300]
      }
    }
  }
});

export default function Theme() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router />
    </ThemeProvider>
  );
}
