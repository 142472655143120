import React, { useState, useEffect } from "react";
import Amplify, { API } from 'aws-amplify';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {  Save } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    downloadZIPButton:{
        marginTop: "auto",
        width: "20vmin",
        alignSelf: "center",
    }
  }
));

export default function EditOrderLineItems(props){
    const [lineItems, setLineItems] = useState([]);
    const classes = useStyles();
    var refresh = props.refresh;
    const [lineItemUpdate, setLineItemsUpdate] = useState({
        "id": props.orderId,
        "price" : null,
    });
 
    const setUpdate = (fieldID,value)=> {
        lineItemUpdate[fieldID] = value;
        setLineItemsUpdate(lineItemUpdate);
        updateLineItem();
}



    const getLineItems = async() =>{
        const apiName = 'TransportAPI';
        const path = '/Test/order-services/salesorder/line';
        const session = await Amplify.Auth.currentSession();
        const token = session.idToken.jwtToken
        API.get(apiName, path,{ headers: {Authorization: token},  queryStringParameters: { orderid: props.orderId }}).then(response => {
            console.log(response)
            setLineItems(response);
        }).catch(error => {
            console.log(error.response)
        });
    }
    
    const updateLineItem = async() =>{
        const apiName = 'TransportAPI';
        const path = '/Test/order-services/salesorder/line';
        let session = await Amplify.Auth.currentSession();
        let token = session.idToken.jwtToken
      //  console.log(quoteUpdate);    
        API.put(apiName, path,{ body: lineItemUpdate, headers: {Authorization: token} }).then(response => {
        }).catch(error => {
            console.log(error.response)
        }); 
        
        getLineItems()
    }


    useEffect(()=>{
        if(lineItems.length === 0){
            getLineItems()
        }      

        if(refresh === true ){      
            getLineItems()
            console.log("");
        }
   
    });

    return(
        <Paper>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Source Language</TableCell>
                        <TableCell>Target Language</TableCell>
                        <TableCell>Word Count</TableCell>
                        <TableCell>Rate</TableCell>
                        <TableCell>Repetition Count</TableCell>
                        <TableCell>Rep. Rate</TableCell>
                        <TableCell>Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                        {lineItems.map((lineItem, index) => 
                            <TableRow key={index}>
                              <TableCell scope="row">{lineItem.src_language_name}</TableCell>
                              <TableCell>{lineItem.target_language_name}</TableCell>
                              <TableCell>{lineItem.word_count}</TableCell>
                              <TableCell>{lineItem.rate}</TableCell>
                              <TableCell>{lineItem.rep_count}</TableCell>
                              <TableCell>{lineItem.rep_rate}</TableCell>   
                              <TableCell>{lineItem.price}</TableCell>       
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}
