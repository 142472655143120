import React, { useCallback, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import uuidv4 from 'uuid/v4';
import clsx from 'clsx';
import Amplify, { API } from 'aws-amplify';
import Box from '@material-ui/core/Box';
import { useDropzone } from 'react-dropzone';
import DescriptionIcon from '@material-ui/icons/Description';
import Typography from '@material-ui/core/Typography';
import FileLine from 'components/fileLine';

const useStyles = makeStyles(theme => ({
  uploadButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 'auto',
    alignItems: 'center',
    backgroundColor: '#D3D3D3',
    flexGrow: 1,
    width: '100%',
    borderRadius: 0,
    cursor: 'pointer',
    '&:hover > $uploadIcon': {
      color: '#f48335',
      transition: '0.4s'
    },
    '& > $uploadIcon': {
      transition: '0.4s ease-out'
    }
  },
  uploadButtonSmall: {
    flexGrow: 0,
    height: '7vh'
  },
  uploadIcon: {
    fontSize: '6rem'
  },
  uploadIconSmall: {
    fontSize: '1.4rem'
  },
  fileListContainer: {}
}));

export default function FileUpload({
  uploading,
  onChange,
  onChangeWordCountAdd,
  onChangeWordCountRemove,
  uploadTo,
  quoteID,
  projectOwner,
  enableWordCount,
  maxFiles,
  wc
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [files, setFiles] = useState([]);

  const [open, setOpen] = React.useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    onChange(files.map(file => file.id));
    if (uploading !== undefined) {
      files.find(fileDescriptor => fileDescriptor.state !== 'Completed')
        ? uploading(true)
        : uploading(false);
    }
  }, [files]);

  function addFile(file, state, wordCount) {
    let fileDescriptor = {
      id: uuidv4(),
      file: file,
      state: state,
      progress: 0,
      wordCount: wordCount
    };
    setFiles(files => [...files, fileDescriptor]);
    return fileDescriptor;
  }

  async function getWordCount(fileId) {
    const apiName = 'TransportAPI';
    let path;
    path = '/Test/document-services/processing'; 


    const session = await Amplify.Auth.currentSession();
    const token = session.idToken.jwtToken;
    try {
      let response = await API.get(apiName, path, {
        headers: { Authorization: token },
        timeout: 40000,
        queryStringParameters: { filekey: fileId, subfolderid: quoteID }
      });

        if (response['wordcount'] !== undefined) {
          updateFile(fileId, {
            wordCount: response.wordcount,
            state: 'Completed'
          });
        }
    } catch (error) {
      console.log(error);
    }
  }

  function updateFile(fileId, updatedAttributes) {
  
    setFiles(files =>
      files.map(file =>
        file.id === fileId ? Object.assign({}, file, updatedAttributes) : file
      )
    );
  }

  function removeFile(fileId) {
    
    onChangeWordCountRemove(files.filter(file => (file.id === fileId ? file.wordCount : 0))[0].wordCount);
    setFiles(files => files.filter(file => (file.id !== fileId ? file : null)));

  }

  const getUploadLink = async (fileID, fileName) => {
    const apiName = 'TransportAPI';
    const path = '/Test/file-services/uploadlink/' + uploadTo;
    const session = await Amplify.Auth.currentSession();
    const token = session.idToken.jwtToken;

    try {
      return await API.get(apiName, path, {
        headers: { Authorization: token },
        queryStringParameters: {
          fileid: fileID,
          projectid: quoteID,
          ownerid: projectOwner,
          filename: fileName
        }
      });
    } catch (error) {
      console.log(error.response);
      return null;
    }
  };

  const uploadFile = async fileDescriptor => {
    let uploadLink = await getUploadLink(
      fileDescriptor.id,
      fileDescriptor.file.name
    );

    console.log(uploadLink);
    if (!uploadLink) {
      //this reall should be an error
      console.log('Bugging out');
      return;
    }
    let request = new XMLHttpRequest();
    request.onreadystatechange = function() {
      if (request.readyState == 4) {
        if (enableWordCount) {
          updateFile(fileDescriptor.id, { state: 'Processing' });
          getWordCount(fileDescriptor.id);
          return;
        }
        updateFile(fileDescriptor.id, { state: 'Completed' });
      }
    };
    request.upload.onprogress = function(e) {
      var done = e.position || e.loaded,
        total = e.totalSize || e.total;
      console.log(Math.floor((done / total) * 100));
      updateFile(fileDescriptor.id, {
        progress: Math.floor((done / total) * 100)
      });
    };
    request.open('PUT', uploadLink.Link);
    request.setRequestHeader('x-amz-meta-filename', fileDescriptor.file.name);
    request.send(fileDescriptor.file);
  };

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach((file, index) => {
      if (maxFiles) {
        if (index + 1 > maxFiles) {
          return;
        }
      }
      //check if file has already been added
      let fileDescriptor = addFile(file, 'Uploading', -1);
      uploadFile(fileDescriptor);
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <React.Fragment>
      {files.length ? (
        <Box className={classes.fileListContainer} alignItems="center">
          {files.map((fileDescriptor, index) => {
            return (
              <FileLine
                key={index}
                fileDescriptor={fileDescriptor}
                onDelete={fileId => removeFile(fileId)}
              />
            );
          })}
          
        </Box>
      ) : null}

        
      <div
        className={clsx(classes.uploadButton, {
          [classes.uploadButtonSmall]: files.length
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <DescriptionIcon
          className={clsx(classes.uploadIcon, {
            [classes.uploadIconSmall]: files.length
          })}
        />
      

        <Typography variant="body1">
          {!files.size
            ? t('fileUpload:dragAndDrop')
            : t('fileUpload:dragAndDropMore')}
        </Typography>
      </div>

    </React.Fragment>
  );
}
