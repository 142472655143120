import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import uuidv4 from 'uuid/v4';
import Amplify, { API } from 'aws-amplify';
import { UserContext } from 'components/UserContext';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, TextField, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import MultiLineTypography from 'components/multiLineTypography';
import Button from 'GVComponents/Button.js';
import ServiceSelect from 'components/serviceSelect.js';
import ServiceLevelSelect from 'components/serviceLevelSelect.js';
import LeanServiceLevel from 'components/lean/leanServiceLevel.js';
import ContentType from 'components/contentType.js';
import FileUpload from 'components/fileUpload.js';
import LeanFileUpload from 'components/lean/leanFileUpload.js';
import LanguageSelect from 'components/languageSelect.js';
import LeanLanguageSelect from 'components/lean/leanLanguageSelect.js';
import LanguageSelectTable from 'components/LanguageSelectTable.js';
import Deadline from 'components/deadline.js';
import QuoteSection from 'components/quoteSection.js';
import Tone from 'components/tone.js';
import Billing from 'components/billingParent.js';
import Note from 'components/note.js';
import HelpMessage from 'components/helpMessage.js';
import LeanHelpMessage from 'components/lean/leanHelpMessage.js';
import QuoteBreakdown from 'components/quoteBreakdown.js';
import SubmitTracker from 'components/submitTracker.js';
import './quote.css';
import UnselectablesFilesDialog from 'components/unselectablesFilesDialog'

const useStyles = makeStyles(() => ({
  mainHeader: {
    marginBottom: '1vmin'
  },
  center: {
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  helpMessage: {
    marginTop: 'auto'
  },
  breakdownSection: {
    marginTop: '9.5vh',
    marginLeft: '5vmin'
  }
}));

export default function Quote() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();


  const userInfo = useContext(UserContext);
  
  const [buttonSuffixes, setButtonSuffixes] = useState([]);

  const updateButtonSuffixes = () => {
    setButtonSuffixes([
      '',
      t('quote:buttonSuffixChooseYourLanguage'),
      t('quote:buttonSuffixContent'),
      t('quote:buttonSuffixDelivery'),
      t('quote:buttonSuffixService'),
      t('quote:buttonSuffixTone'),
      t('quote:buttonSuffixBilling')
    ]);
  };

  const [uploading, setUploading] = useState(false);
  const [stepNames, setStepNames] = useState([]);
  const updateStepName = () => {

    if (userInfo.groups.includes('general_public')) {
      setStepNames([
        t('quote:startYourProject'),
        t('quote:translationProject'),
        t('quote:languages'),
        t('quote:contentType'),
        t('quote:deliveryDate'),
        t('quote:serviceLevel'),
        t('quote:tone'),
        t('quote:billing')
      ])
    }
    else
      {
      setStepNames([
        t('quote:startYourProject'),
        t('quote:translationProject'),
        t('quote:languages'),
        t('quote:contentType'),
        t('quote:deliveryDate'),
        t('quote:serviceLevel'),
        t('quote:tone')
      ])
    }
  
  };

  const [currentStep, setCurrentStep] = useState(0);

  const nextStep = () => {
    if (validateEntry(currentStep) != 1) {
      if (currentStep === 6 && !userInfo.groups.includes('general_public')) {
        setCurrentStep(prevActiveStep => prevActiveStep + 2);
      }
      else
      {
        setCurrentStep(prevActiveStep => prevActiveStep + 1);
      }
      handleErrorClose();
    }
  };

  const previousStep = () => {
    setCurrentStep(prevActiveStep => prevActiveStep - 1);
  };

  const [quote, setQuote] = useState({
    salesorder_id: uuidv4(),
    file_list: [],
    tone: null,
    package: null,
    content_type: null,
    source_language: [],
    target_languages: [],
    deadline_date: null,
    timezone: '',
    ordertype: null,
    order_name: '',
    external_reference: '',
    notes: '',
    word_count: '',
    fileword_count: '',
    rec_service_level: null,
    detected_language: '',
    has_unselectables: false,
    accepted_price : null,
    billing_name:'',
    billing_first_name:'',
    billing_last_name:'',
    billing_email:'',
    billing_address1:'',
    billing_address2:'',
    billing_city:'',
    billing_postcode:'',
    billing_country_id:null,
    billing_vat_number:'',
    billing_record_type:'',
    billing_password:'',
  });

  const [errorOpen, setErrorOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleError = text => {
    setErrorMessage(text);
    setErrorOpen(true);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorOpen(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const validateEntry = id => {
    let result = 0; // 0 = OK, 1 = ERROR, 2 = WARNING
    let text = '';
    
    switch (id) {
      case 0: //service
        break;

        case 99:
          if (quote.detected_language == 'ERROR') {

            text =
            'You have multiple source languages in your files.';
          result = 1;
        }

        break;

      case 1: //files
        if (quote.file_list.length == 0 && quote.word_count == '') {
          text = 'You have not uploaded any files or entered a word count';
          result = 1;
        } else if (uploading) {
          text =
            'Files are still uploading wait for them to complete before proceeding';
          result = 1;
        } else if (quote.file_list.length != 0 && quote.word_count != '') {
          text = 'You must either upload files OR supply a word count';
          result = 1;
        } else if (quote.word_count != '' && isNaN(quote.word_count)) {
          text = 'Word count must be a number';
          result = 1;
        } else if (
          quote.word_count != '' &&
          (quote.word_count > 1000000 || quote.word_count < 1)
        ) {
          text =
            'Invalid word count value. Enter a value between 1 and 1,000,000';
          result = 1;
        }

        if (quote.detected_language == 'ERROR') {

            text =
            'You have multiple source languages in your files.';
          result = 1;
        }

        
        if (quote.has_unselectables == true && result != 1) {

          handleClickOpen();
          result = 2;
      }

      if (result != 1 && quote.file_list.length != 0) {

        updateQuote ('word_count')(quote.fileword_count);

      }

        break;

      case 2: //language
      console.log (quote.target_languages);
      console.log (quote.source_language);


        if (
          quote.source_language.length == 0 &&
          quote.target_languages.length == 0
        ) {
          text = 'You must enter a source language and target languages';
          result = 1;
        } else if (quote.source_language.length == 0) {
          text = 'Missing source language';
          result = 1;
        } else if (quote.target_languages.length == 0) {
          text = 'Missing target language(s)';
          result = 1;
        } else if  (quote.target_languages.find((element) => {return element.isocode === quote.source_language[0].isocode})) {
          text = 'You cannot translate to and from the same language';
          result = 1;
        }

        break;

      case 3: //content type
        break;

      case 4: // delivery
        break;
      
      case 5: // service level
        if (quote.package == null) {
          text = 'You must select a service level';
          result = 1;
        }
        break;

      case 6: // tone
        if (quote.notes.length > 10000) {
          text =
            'The notes seem a little long! Notes must be less than 10,000 characters';
          result = 1;
        }

        break; // in case we add more steps!
    }

    if (result != 0) {
      console.log(text);
      handleErrorClose();
      handleError(text);
    }

    return result;
  };

  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const updateQuote = fieldID => {
    return value => {
      setQuote(quote => {
        return Object.assign({}, quote, { [fieldID]: value });
      });
    };
  };

  const updateFileCount = fieldID => {
  
    return value => {
      setQuote(quote => {
        return Object.assign({}, quote, { [fieldID]: Number(value) + Number(quote.fileword_count) });
      });
    };
  };

  const removeFileCount = fieldID => {
  
    return value => {
      setQuote(quote => {
        return Object.assign({}, quote, { [fieldID]: Number(quote.fileword_count) - Number(value)});
      });
    };
  };

  const updateDetectedLanguage = fieldID => {
  
    return value => {
      setQuote(quote => {
        return Object.assign({}, quote, { [fieldID]: value });
      });
    };
  };

  const updateHasUnselectables = fieldID => {
  
    return value => {
      setQuote(quote => {
        return Object.assign({}, quote, { [fieldID]: value });
      });
    };
  };

  const sendQuote = async () => {
    setSubmitting(true);
    let quoteBody = {
      salesorder_id: quote.salesorder_id,
      file_list: quote.file_list,
      tone_id: quote.tone !== null ? quote.tone.id : null,
      package_id: quote.package !== null ? quote.package.id : null,
      content_types: quote.content_type !== null ? [quote.content_type.id] : [],
      source_language: quote.source_language.map(
        language => language.isocode
      )[0],
      target_languages: quote.target_languages.map(
        language => language.isocode
      ),
      deadline_date: quote.deadline_date,
      timezone: quote.timezone,
      address_id: null,
      order_name: quote.order_name,
      external_reference: quote.external_reference,
      notes: quote.notes,
      word_count: quote.word_count,
      accepted_price : quote.accepted_price,
      billing_first_name: quote.billing_first_name,
      billing_last_name:quote.billing_last_name,
      billing_email:quote.billing_email,
      billing_address1:quote.billing_address1,
      billing_address2:quote.billing_address2,
      billing_city:quote.billing_city,
      billing_postcode:quote.billing_postcode,
      billing_country_id:quote.billing_country_id,
      billing_vat_number:quote.billing_vat_number,
      billing_record_type:quote.billing_record_type,
      billing_password:quote.billing_password,
    };
    console.log(quoteBody);
    const apiName = 'TransportAPI';
    const path = '/Test/order-services/salesorder';
    let session = await Amplify.Auth.currentSession();
    let token = session.idToken.jwtToken;
    API.post(apiName, path, {
      body: quoteBody,
      headers: { Authorization: token }
    })
      .then(resp => {
        console.log(resp);
        setSubmitting(false);
        setSubmitted(true);
      })
      .catch(err => {
        console.log(err);
        setSubmitting(false);
        setSubmitError(
          'There was a problem submitting your quote please try again'
        );
      });
  };

  const [steps, setSteps] = useState([]);
  const updateSteps = () => {
    setSteps([
      <QuoteSection
        key={t('quote:serviceSelectHeader')}
        title={t('quote:serviceSelectHeader')}
      >
        <ServiceSelect className={classes.center} onChange={nextStep} />
      </QuoteSection>,
      <QuoteSection
        key={t('quote:sendUsContentHeader')}
        title={t('quote:sendUsContentHeader')}
      >
        {userInfo.groups.includes('general_public') ? 
        <LeanFileUpload
          quoteID={quote.salesorder_id}
          uploadTo="tmp"
          enableWordCount
          uploading={setUploading}
          onChange={updateQuote('file_list')}
          onChangeWordCountAdd={updateFileCount('fileword_count')}
          onChangeDetectedLanguageAdd={updateDetectedLanguage('detected_language')}
          onChangeHasUnselectablesAdd={updateHasUnselectables('has_unselectables')}
          onChangeWordCountRemove={removeFileCount('fileword_count')}
          wc={quote.fileword_count}
        />  :
        <FileUpload
        quoteID={quote.salesorder_id}
        uploadTo="tmp"
        enableWordCount
        uploading={setUploading}
        onChange={updateQuote('file_list')}
        onChangeWordCountAdd={updateFileCount('fileword_count')}
        onChangeWordCountRemove={removeFileCount('fileword_count')}
        wc={quote.fileword_count}
      />
      }
        <Box display="flex" flexDirection="row" margin="1vmin">
          <MultiLineTypography variant="body1">
            {t('quote:dontHaveFiles')}
          </MultiLineTypography>
          <TextField
            onChange={event => {
              updateQuote('word_count')(event.target.value);
            }}
            value={quote.word_count}
            variant="outlined"
            label={t('quote:wordCount')}
            style={{ marginLeft: 'auto' }}
          />
        </Box>
        {(userInfo.groups.includes('general_public') || userInfo.groups.includes('candy')) ? 
        <LeanHelpMessage
          className={classes.helpMessage}
          header={t('quote:helpHeader')}
          message={t('quote:helpMessage')}
        /> :
        <HelpMessage
        className={classes.helpMessage}
        header={t('quote:helpHeader')}
        message={t('quote:helpMessage')}
      />}
      </QuoteSection>,

      (userInfo.groups.includes('general_public') ? <QuoteSection
        key={t('quote:languagesHeader')}
        title={t('quote:languagesHeader')}
      >
        <LeanLanguageSelect
          heading={t('quote:from')}
          value={quote.source_language}
          detectedLanguage={quote.detected_language}
          singleLanguage
          onChange={updateQuote('source_language')}
        />
        <LanguageSelect
          heading={t('quote:to')}
          value={quote.target_languages}
          onChange={updateQuote('target_languages')}
        />
        <LanguageSelectTable 
          quote={quote}
          onChange={updateQuote('target_languages')}
        />
      </QuoteSection> :

      <QuoteSection
      key={t('quote:languagesHeader')}
      title={t('quote:languagesHeader')}
    >
      <LanguageSelect
        heading={t('quote:from')}
        value={quote.source_language}
        singleLanguage
        onChange={updateQuote('source_language')}
      />
      <LanguageSelect
        heading={t('quote:to')}
        value={quote.target_languages}
        onChange={updateQuote('target_languages')}
      />
    </QuoteSection>),

      <QuoteSection
        key={t('quote:contentTypeHeader')}
        title={t('quote:contentTypeHeader')}
      >
        <ContentType
          value={quote.content_type}
          onChange={updateQuote('content_type')}
        />
      </QuoteSection>,
      <QuoteSection
        key={t('quote:deadlineHeader')}
        title={t('quote:deadlineHeader')}
      >
        <Deadline
          onDateTimeChange={updateQuote('deadline_date')}
          onTimezoneChange={updateQuote('timezone')}
        />
      </QuoteSection>,
      (!userInfo.groups.includes('general_public') ? <QuoteSection
          key={t('quote:serviceHeader')}
          title={t('quote:serviceHeader')}
        >
          <ServiceLevelSelect
            className={classes.center}
            selectedServiceLevel={quote.package}
            onChange={updateQuote('package')}
          />
      </QuoteSection> :
      <QuoteSection
      key={t('quote:serviceHeader')}
      title={t('quote:serviceHeader')}
      >
      {/* <ServiceLevelSelectGeneric
        className={classes.center}
        selectedServiceLevel={quote.package}
        quote
        onChange={updateQuote('package')}
      /> */}
        <LeanServiceLevel 
        selectedServiceLevel={quote.package}
        quote={quote}
        onChange={updateQuote('package')}
        onChangePrice={updateQuote('accepted_price')}
        onChangeDate={updateQuote('deadline_date')}
        />
      </QuoteSection>)
      ,
      <QuoteSection
        key={t('quote:notesToneHeader')}
        title={t('quote:notesToneHeader')}
      >
        <Note currentNotes={quote.notes} onChange={updateQuote('notes')} />
        <Tone selectedTone={quote.tone} onChange={updateQuote('tone')} />
      </QuoteSection>,

      (userInfo.groups.includes('general_public') ? <QuoteSection
      key={t('quote:Billing Information')}
      title={t('quote:Billing Information')}
      >
        <Billing 
        onChangeFirstName={updateQuote('billing_first_name')}
        onChangeLastName={updateQuote('billing_last_name')}
        onChangeEmail={updateQuote('billing_email')}
        onChangePassword={updateQuote('billing_password')}
        onChangeAddress1={updateQuote('billing_address1')}
        onChangeAddress2={updateQuote('billing_address2')}
        onChangeCity={updateQuote('billing_city')}
        onChangePostcode={updateQuote('billing_postcode')}
        onChangeCountryId={updateQuote('billing_country_id')}
        onChangeVatNumber={updateQuote('billing_vat_number')}
        />
      </QuoteSection> :
      <br/>
      )

      ,
      <QuoteSection key={t('quote:submitHeader')}>
        <SubmitTracker
          submitted={submitted}
          submitting={submitting}
          submitError={submitError}
          projectID={quote.salesorder_id}
        />
      </QuoteSection>
    ]);
  };
  useEffect(() => {
    updateSteps();
    updateStepName();
    updateButtonSuffixes();
  }, [quote, submitted, submitting, submitError, i18n.language]);

  useEffect(() => {
    updateSteps();
    updateButtonSuffixes();
  }, []);


  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="quoteBackground">
      
      <Snackbar
        anchorOrigin={{ vertical:'top' , horizontal:'center' }}
        open={errorOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>

      <Box display="flex" flexDirection="row" justifyContent="center">
        
        <Box display="flex" flexDirection="column">
          <Box minHeight="2.571428571428571rem">
            <MultiLineTypography
              color="secondary"
              containerClassName={classes.mainHeader}
              variant="h3"
            >
              {stepNames[currentStep]}
            </MultiLineTypography>
          </Box>
          {steps[currentStep]}
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            {currentStep > 2 && !(currentStep >= 8) ? (
              <Button
                variant="contained"
                color="secondary"
                onClick={previousStep}
                disabled={currentStep === 0}
              >
                <Typography>Back</Typography>
              </Button>
            ) : <br/> }
            

            { (currentStep === 7 && userInfo.groups.includes('general_public')) ||
              (currentStep === 6 && !userInfo.groups.includes('general_public')) ? (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  nextStep();
                  sendQuote();
                }}
              >
                <Typography>Submit</Typography>
              </Button>
            ) : 
            (currentStep >= 1 && !(currentStep >= 8) ? 
              <Button variant="contained" color="secondary" onClick={nextStep}>
                <Typography>
                  {
                    t('quote:next')
                  }
                  {
                    buttonSuffixes[currentStep]
                  }
                </Typography>
              </Button>
            : null )}
          </Box>
        </Box>
        <QuoteBreakdown
          quote={quote}
          step={currentStep}
          showStep={1}
          className={classes.breakdownSection}
        />
      </Box>
      <UnselectablesFilesDialog open={open} onClose={handleClose} />
    </div>
  );
}
