import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Amplify, { API } from 'aws-amplify';
import { GetApp, InsertDriveFile } from '@material-ui/icons';
import {
  Fab,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  fileLine: {
    '&:not(:last-child)': {
      borderBottom: '1px solid #dadce0'
    }
  }
}));

export default function FileList({ className, projectOwner, orderId, type, totalwords }) {
  const classes = useStyles();
  const [files, setFiles] = useState([]);

  const getFiles = async () => {
    const apiName = 'TransportAPI';
    const path = '/Test/file-services/filelist/' + type;
    const session = await Amplify.Auth.currentSession();
    const token = session.idToken.jwtToken;
    API.get(apiName, path, {
      headers: { Authorization: token },
      queryStringParameters: { ownerid: projectOwner, subfolderid: orderId }
    })
      .then(response => {
        console.log(projectOwner)
        console.log('fileList: ' + type);
        console.log(response);
        setFiles(response);
      })
      .catch(error => {
        console.log(error);
      });
  };
  const getDownloadLink = async (fileID, projectID, fileName) => {
    const apiName = 'TransportAPI';
    const path = '/Test/file-services/downloadlink/' + type;
    const session = await Amplify.Auth.currentSession();
    const token = session.idToken.jwtToken;
    try {
      return await API.get(apiName, path, {
        headers: { Authorization: token },
        queryStringParameters: {
          ownerid: projectOwner,
          fileid: fileID,
          projectid: projectID,
          filename: fileName
        }
      });
    } catch (error) {
      console.log(error.response);
      return null;
    }
  };

  const onDownloadClick = async (fileID, projectID, fileName) => {
    let downloadLink = await getDownloadLink(fileID, projectID, fileName);
    window.location.href = downloadLink;
  };

  useEffect(() => {
      getFiles();
  }, [projectOwner, orderId]);
  
  useEffect(() => {
    if (files.length === 0) {
      getFiles();
    }
  }, []);

  return (
    <List className={className}>
      {files.map(file => (
        <ListItem className={classes.fileLine} key={file.Id}>
          <ListItemAvatar>
            <Avatar>
              <InsertDriveFile />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={file.Name}
            secondary={file.Word_count ? 'Wordcount' + file.Word_count : null}
          />
          <Fab
            color="primary"
            size="small"
            aria-label="download"
            onClick={() => {
              onDownloadClick(file.Id, file.ProjectId, file.Name);
            }}
          >
            <GetApp />
          </Fab>
        </ListItem>
      ))}
    </List>
  );
}
