import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Auth } from 'aws-amplify';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Typography,
  Button,
  InputAdornment,
  LinearProgress
} from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Lock } from '@material-ui/icons';
import Authentication from 'views/generic/authentication';
import AuthenticationForm from 'components/authenticationForm';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ResetPassword() {
  const { t } = useTranslation();
  let history = useHistory();
  let query = useQuery();

  let forceReset = false;
  const resetCode = query.get('code');
  const username = query.get('username');

  let user;
  if (history.location.state) {
    user = history.location.state.user;
  }
  if (user) {
    console.log(user);
    forceReset = true;
  } else if (!resetCode || !username) {
    history.push('/');
  }

  return (
    <Authentication>
      <Formik
        initialValues={{
          password: '',
          passwordConfirmation: ''
        }}
        validationSchema={Yup.object({
          password: Yup.string()
            .min(8, t('validation:minPasswordLengthNotMet'))
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
              t('validation:passwordRequirementsNotMet')
            )
            .required(t('validation:enterNewPassword')),
          passwordConfirmation: Yup.string()
            .oneOf([Yup.ref('password')], t('validation:passwordsDontMatch'))
            .required(t('validation:enterConfirmPassword'))
        })}
        onSubmit={async ({ password }, { setSubmitting, setErrors }) => {
          try {
            if (forceReset) {
              await Auth.completeNewPassword(user, password);
              history.push('/');
            } else {
              await Auth.forgotPasswordSubmit(username, resetCode, password);
              history.push('/login');
            }
          } catch (err) {
            switch (err.code) {
              case 'InvalidPasswordException':
              case 'InvalidParameterException':
                setErrors({
                  password: t('validation:passwordNotMetFull')
                });
                break;
              default:
                console.log(err);
            }
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <AuthenticationForm>
            <Typography color="primary" variant="h2">
              {forceReset
                ? t('authentication:passwordResetRequired')
                : t('authentication:passwordReset')}
            </Typography>
            <TextField
              name="password"
              id="outlined-textarea"
              label={t('authentication:password')}
              type="password"
              color="primary"
              margin="normal"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                )
              }}
            />
            <TextField
              name="passwordConfirmation"
              id="outlined-textarea"
              label={t('authentication:passwordConfirmation')}
              type="password"
              color="primary"
              margin="normal"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                )
              }}
            />
            {isSubmitting && <LinearProgress />}
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              type="submit"
            >
              <Trans i18nKey="authentication:resetPassword" />
            </Button>
            <Button color="secondary" onClick={() => history.push('/login')}>
              <Trans i18nKey="authentication:login" />
            </Button>
          </AuthenticationForm>
        )}
      </Formik>
    </Authentication>
  );
}
