import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import {
  Typography,
  Button,
  InputAdornment,
  LinearProgress
} from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import MuiPhoneNumber from 'material-ui-phone-number';
import { AccountCircle, Business } from '@material-ui/icons';
import Authentication from 'views/generic/authentication';
import AuthenticationForm from 'components/authenticationForm';

export default function Login() {
  const { t } = useTranslation();
  let history = useHistory();
  let user = history.location.state.user;
  if (!user) {
    history.push('/');
    return;
  }

  return (
    <Authentication>
      <Formik
        initialValues={{
          name: '',
          company: '',
          phoneNumber: ''
        }}
        initialStatus={{ completed: false }}
        validationSchema={Yup.object({
          name: Yup.string().required(t('validation:enterName')),
          company: Yup.string(),
          phoneNumber: Yup.string()
        })}
        onSubmit={async (
          { name, company, phoneNumber },
          { setSubmitting, setErrors }
        ) => {
          try {
            await Auth.updateUserAttributes(user, {
              name: name,
              'custom:company': company,
              phone_number: phoneNumber
            });
            user.refreshSession(
              user.signInUserSession.refreshToken,
              (err, session) => {
                console.log('session', err, session);
                user.setSignInUserSession(session);
                history.push('/');
              }
            );
          } catch (err) {
            console.log(err);
            setErrors({ name: t('validation:genericValidationError') });
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <AuthenticationForm>
            <Typography color="primary" variant="h2">
              <Trans i18nKey="authentication:updateAttributes" />
            </Typography>
            <TextField
              name="name"
              id="outlined-textarea"
              label={t('authentication:name') + ' *'}
              color="primary"
              margin="normal"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                )
              }}
            />
            <TextField
              name="company"
              id="outlined-textarea"
              label={t('authentication:company')}
              color="primary"
              margin="normal"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Business />
                  </InputAdornment>
                )
              }}
            />
            <Field name="phoneNumber">
              {({ form: { setFieldValue }, meta }) => (
                <MuiPhoneNumber
                  label={t('authentication:phone')}
                  id="outlined-textarea"
                  variant="outlined"
                  margin="normal"
                  color="primary"
                  error={meta.error}
                  helperText={meta.error}
                  defaultCountry={'gb'}
                  inputClass={TextField}
                  onChange={e => {
                    setFieldValue('phoneNumber', e.replace(/ /g, ''));
                  }}
                />
              )}
            </Field>
            {isSubmitting && <LinearProgress />}
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              type="submit"
            >
              <Trans i18nKey="authentication:complete" />
            </Button>
          </AuthenticationForm>
        )}
      </Formik>
    </Authentication>
  );
}
