import React, { useCallback, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import uuidv4 from 'uuid/v4';
import clsx from 'clsx';
import Amplify, { API } from 'aws-amplify';
import Box from '@material-ui/core/Box';
import { useDropzone } from 'react-dropzone';
import DescriptionIcon from '@material-ui/icons/Description';
import Typography from '@material-ui/core/Typography';
import LeanFileLine from 'components/lean/leanFileLine';
import SupportedFilesDialog from 'components/supportedFilesDialog'
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { TextField, Grid } from '@material-ui/core';



const useStyles = makeStyles(theme => ({
  underline: {
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    }

  },
  
  uploadButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 'auto',
    alignItems: 'center',
    backgroundColor: '#D3D3D3',
    flexGrow: 1,
    width: '100%',
    borderRadius: 0,
    cursor: 'pointer',
    '&:hover > $uploadIcon': {
      color: '#f48335',
      transition: '0.4s'
    },
    '& > $uploadIcon': {
      transition: '0.4s ease-out'
    }
  },
  uploadButtonSmall: {
    flexGrow: 0,
    height: '7vh'
  },
  uploadIcon: {
    fontSize: '6rem'
  },
  uploadIconSmall: {
    fontSize: '1.4rem'
  },
  fileListContainer: {}
}));

export default function LeanFileUpload({
  uploading,
  onChange,
  onChangeWordCountAdd,
  onChangeDetectedLanguageAdd,
  onChangeHasUnselectablesAdd,
  onChangeWordCountRemove,
  uploadTo,
  quoteID,
  projectOwner,
  enableWordCount,
  maxFiles,
  wc
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [files, setFiles] = useState([]);

  const [open, setOpen] = React.useState(false);
  const [textField, setTextField] = React.useState('');

  const handleTextFieldChange = (e) => { 
    if(e.target.value.toString().length ==2)
    {
      setTextField(e.target.value.toString().split("").reverse().join(""))
    }
    else
    setTextField(e.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    onChange(files.map(file => file.id));
    if (uploading !== undefined) {
      files.find(fileDescriptor => fileDescriptor.state !== 'Completed')
        ? uploading(true)
        : uploading(false);
    }
	
	    let ds = '';
      let dl = false;

      if (files.length > 0) 
      {

        ds = files[0].detectedLanguage;

      for (var x = 0; x < files.length; x ++)
        {
          console.log ('US=' + files[x].hasUnselectables);

          for (var y = 0; y < files.length; y ++)
          {
            console.log ('DL=' + files[x].detectedLanguage);
            console.log ('DL=' + files[y].detectedLanguage);

            if (files[x].detectedLanguage != files[y].detectedLanguage) 
            {

              ds = 'ERROR';
            }
          }

          if (files[x].hasUnselectables == true) {

            dl = true;

            }
      }
      console.log ('DS = ' + ds);

      onChangeHasUnselectablesAdd(dl);
      onChangeDetectedLanguageAdd (ds);

  } 
  }, [files]);

  function addFile(file, state, wordCount, detectedLanguage, hasUnselectables, isDocument) {
    let fileDescriptor = {
      id: uuidv4(),
      file: file,
      state: state,
      progress: 0,
      wordCount: wordCount,
      detectedLanguage : detectedLanguage,
      hasUnselectables: hasUnselectables,
      isDocument:isDocument
    };
    setFiles(files => [...files, fileDescriptor]);
    return fileDescriptor;
  }

  async function getWordCount(fileId) {
    const apiName = 'TransportAPI';
    let path;
    path = '/Test/document-services/leanprocessing';
    
    console.log ('Starting word count');

    const session = await Amplify.Auth.currentSession();
    const token = session.idToken.jwtToken;
    try {
      let response = await API.get(apiName, path, {
        headers: { Authorization: token },
        timeout: 40000,
        queryStringParameters: { filekey: fileId, subfolderid: quoteID }
      });

      
        var result = JSON.parse(response);

        if (result.WordCount !== undefined) {
          console.log ('lean result');
          console.log (result.WordCount);
          if (result.WordCount != 'WORD COUNT ERROR') {
  
            onChangeWordCountAdd (result.WordCount);
            onChangeDetectedLanguageAdd (result.DetectedSourceLanguage);
            onChangeHasUnselectablesAdd (result.HasUnselectables);           
			
            updateFile(fileId, {
              wordCount: result .WordCount,
              detectedLanguage : result.DetectedSourceLanguage,
              hasUnselectables: result.HasUnselectables,
              state: 'Completed'
            });
          }
          else
            {
              onChangeHasUnselectablesAdd (true);           
              updateFile(fileId, {
                wordCount: 0,
                detectedLanguage : 'Error',
                hasUnselectables:false,
                state: 'Completed'
              });              
            }
        }
        else
            {
              onChangeHasUnselectablesAdd (true);
              updateFile(fileId, {
                wordCount: 0,
                detectedLanguage : 'Error',
                hasUnselectables:false,
                state: 'Completed'
              });
            }
          
    } catch (error) {
      console.log ('WORD COUNT ERROR');
      console.log(error);
      onChangeHasUnselectablesAdd (true);           
          updateFile(fileId, {
                wordCount: 0,
                detectedLanguage : 'Error',
                hasUnselectables:false,
                state: 'Completed'
          });             
    }
  }

  function updateFile(fileId, updatedAttributes) {
  
    setFiles(files =>
      files.map(file =>
        file.id === fileId ? Object.assign({}, file, updatedAttributes) : file
      )
    );
  }

  function removeFile(fileId) {
    
	 let dl = '';
    let us = false;


    onChangeWordCountRemove(files.filter(file => (file.id === fileId ? file.wordCount : 0))[0].wordCount);
    setFiles(files => files.filter(file => (file.id !== fileId ? file : null)));
	
	{
      for (var x = 0; x < files.length; x ++)
        {
          console.log ('US=' + files[x].hasUnselectables);

          for (var y = 0; y < files.length; y ++)
          {
            console.log ('DL=' + files[x].detectedLanguage);
            console.log ('DL=' + files[y].detectedLanguage);

            if (files[x].detectedLanguage != files[y].detectedLanguage) 
            {

                dl = 'ERROR';
            }
            else if (dl != 'ERROR') dl= files[x].detectedLanguage;
          }
          if (files[x].detectedLanguage == true) {

            us = true;

        }
    }

  }

    onChangeDetectedLanguageAdd (dl);
    onChangeHasUnselectablesAdd (us);

  }

  const getUploadLink = async (fileID, fileName) => {
    const apiName = 'TransportAPI';
    const path = '/Test/file-services/uploadlink/' + uploadTo;
    const session = await Amplify.Auth.currentSession();
    const token = session.idToken.jwtToken;

    try {
      return await API.get(apiName, path, {
        headers: { Authorization: token },
        queryStringParameters: {
          fileid: fileID,
          projectid: quoteID,
          ownerid: projectOwner,
          filename: fileName
        }
      });
    } catch (error) {
      console.log(error.response);
      return null;
    }
  };

  const CancelText = async () => {

    setTextField('');

  }

const CreateDocument = async () => {
      const apiName = 'TransportAPI';
      const path = '/Test/document-services/createdocument';
      const session = await Amplify.Auth.currentSession();
      const token = session.idToken.jwtToken;
      
      var d = new Date();
      var timestamp = d.getFullYear().toString() + (d.getMonth() + 1).toString() + d.getDay().toString() + d.getHours().toString() + d.getMinutes().toString() + d.getSeconds().toString() + d.getMilliseconds().toString();
      let sourceFilename = 'Text ' + timestamp + '.txt';
      var file = {id:'', name: sourceFilename};

      console.log ('source = ' + sourceFilename);

      let fileDescriptor = addFile(file, 'Saving text ... ', -1, '', false, true);
      
      updateFile(fileDescriptor.id, { state: 'Processing'});

      //fileDescriptor.file.name = sourceFilename;

      let docBody = {
        id: quoteID,
        content: encodeURI (textField),
        filename : fileDescriptor.id,
        sourceFilename : sourceFilename
      };

        let response = await API.post(apiName, path, {
          headers: { Authorization: token },
          body: docBody,
        });

        if (response != '') {
          console.log ('File = ' + response.Filename);
          console.log ('ID = ' + fileDescriptor.id);

          setTextField('');
          updateFile(fileDescriptor.id, { state: 'Processing' });
          file.id = await getWordCount(fileDescriptor.id);
          
          updateFile(fileDescriptor.id, { state: 'Completed', file:file });
        }
      

    };
	
  const uploadFile = async fileDescriptor => {
    let uploadLink = await getUploadLink(
      fileDescriptor.id,
      fileDescriptor.file.name
    );

    console.log(uploadLink);
    if (!uploadLink) {
      //this reall should be an error
      console.log('Bugging out');
      return;
    }
    let request = new XMLHttpRequest();
    request.onreadystatechange = function() {
      if (request.readyState == 4) {
        if (enableWordCount) {
          updateFile(fileDescriptor.id, { state: 'Processing' });
          getWordCount(fileDescriptor.id);
          return;
        }
        updateFile(fileDescriptor.id, { state: 'Completed' });
      }
    };
    request.upload.onprogress = function(e) {
      var done = e.position || e.loaded,
        total = e.totalSize || e.total;
      console.log(Math.floor((done / total) * 100));
      updateFile(fileDescriptor.id, {
        progress: Math.floor((done / total) * 100)
      });
    };
    request.open('PUT', uploadLink.Link);
    request.setRequestHeader('x-amz-meta-filename', fileDescriptor.file.name);
    request.send(fileDescriptor.file);
  };

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach((file, index) => {
      if (maxFiles) {
        if (index + 1 > maxFiles) {
          return;
        }
      }
      //check if file has already been added
      let fileDescriptor = addFile(file, 'Uploading', -1,'',false, false);
      uploadFile(fileDescriptor);
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });


  


 return (
    <React.Fragment>
      {files.length ? (
        <Box className={classes.fileListContainer} alignItems="center">
          {files.map((fileDescriptor, index) => {
            return (
              <LeanFileLine
                key={index}
                fileDescriptor={fileDescriptor}
                onDelete={fileId => removeFile(fileId)}
              />
            );
          })}
        </Box>
      ) : null}
      {files.length ? (
        <Box display="flex" flexDirection="row-reverse" alignItems="right">
          <Box>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Box>
          <Box>
            Total words: {wc} in {files.length} file(s)
          </Box>
        </Box>
      ) : null}

      {textField.toString().length === 0 ? (
        <div
          style={{ height: '30vh' }}
          className={clsx(classes.uploadButton, {
            [classes.uploadButtonSmall]: files.length
          })}
          // {...getRootProps()}
        >
          <Grid container xs={12} spacing={1}>
            <Grid item xs={5}>
              <TextField
                id="standard-multiline-flexible"
                placeholder="Enter text here, or"
                multiline
                rowsMax={6}
                value={textField}
                onChange={handleTextFieldChange}
                autoFocus={true}
                InputProps={{ classes }}
                style={{ width: '8rem' }}
              />
              <Button
                // className={clsx(classes.uploadButton, {
                //   [classes.uploadButtonSmall]: files.length
                // })}
                {...getRootProps()}
                size="medium"
                variant="contained"
              >
                <input {...getInputProps()} /> Upload
              </Button>
            </Grid>

            <Grid item xs={7} {...getRootProps()} />
            <Grid item xs={12} {...getRootProps()} />
            <Grid item xs={12} {...getRootProps()} />
          </Grid>
        </div>
      ) : (
        <div>
          <div
            style={{ height: '30vh', marginBottom: '2vh' }}
            className={clsx(classes.uploadButton, {
              [classes.uploadButtonSmall]: files.length
            })}
          >
            <Grid container xs={12} spacing={2}>
              <Grid item lg={12}>
                <TextField
                  id="standard-multiline-flexible"
                  placeholder="Enter Text Here, or"
                  multiline
                  rowsMax={11}
                  value={textField}
                  onChange={handleTextFieldChange}
                  autoFocus={true}
                  fullWidth
                  InputProps={{ classes }}
                />
              </Grid>
             
            </Grid>
            <Box display="flex" flexDirection="row" alignItems="center">
            <Button
                  // className={clsx(classes.uploadButton, {
                  //   [classes.uploadButtonSmall]: files.length
                  // })}
                  onClick={CreateDocument}
                  size="medium"
                  variant="contained"
                >
                  <input {...getInputProps()} /> Save Text
              </Button>

              <Button
                  // className={clsx(classes.uploadButton, {
                  //   [classes.uploadButtonSmall]: files.length
                  // })}
                  onClick={CancelText}
                  size="medium"
                  variant="contained"
                >
                  <input {...getInputProps()} /> Cancel
              </Button>
              </Box>
              
          </div>
          {textField &&
              <Typography style={{ fontWeight: 'bold', textAlign: 'right' }}>
                words {(textField ? textField.match(/\S+/g) : '').length}
              </Typography>}
          <div
            style={{ height: '10vh' }}
            className={clsx(classes.uploadButton, {
              [classes.uploadButtonSmall]: files.length
            })}
            {...getRootProps()}
          >
            
            <Grid container xs={12} spacing={2}>
              <Grid item xs={7}>
                <Typography
                  style={{ textAlign: 'right', paddingTop: '1vh' }}
                  variant="body1"
                  component="h2"
                >
                  To add more, click and type, or
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Button
                  // className={clsx(classes.uploadButton, {
                  //   [classes.uploadButtonSmall]: files.length
                  // })}
                  {...getRootProps()}
                  size="medium"
                  variant="contained"
                >
                  <input {...getInputProps()} /> Upload
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
      <Box width={400}>
        <Button variant="text" color="primary" onClick={handleClickOpen}>
          <HelpOutlineIcon />
          You can send us almost any file format to translate
        </Button>
      </Box>
      <SupportedFilesDialog open={open} onClose={handleClose} />
    </React.Fragment>
  );
}
