import React, {useState, useEffect, useRef} from 'react';
import {useTranslation, Trans} from 'react-i18next';
import {Auth} from 'aws-amplify';
import {makeStyles} from '@material-ui/core/styles';
import {Redirect} from 'react-router-dom';
import {
  Popper,
  IconButton,
  MenuItem,
  MenuList,
  ClickAwayListener,
  Grow,
  Paper,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles(theme => ({
  userPicture: {
    borderRadius: '50%',
  },
  userPopover: {
    zIndex: theme.zIndex.drawer + 2,
  },
}));

export default function ProfileWidget() {
  useTranslation();
  const classes = useStyles();

  const [user, setUser] = useState(undefined);
  const [signedIn, setSignedIn] = useState(true);

  useEffect(() => {
    (async () => {
      let user = await Auth.currentAuthenticatedUser();
      setUser(user);
    })();
  }, []);

  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const logOut = async () => {
    try {
      await Auth.signOut();
      setSignedIn(false);
    } catch (e) {
      console.log(e);
    }
  };

  if (!signedIn) {
    return <Redirect to="/login" />;
  }

  if (user) {
    return (
      <React.Fragment>
        {user.attributes.picture ? (
          <img
            className={classes.userPicture}
            src={user.attributes.picture}
            alt="picture of user"
          />
        ) : (
          <IconButton
            color="primary"
            aria-label="profile"
            component="span"
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}>
            <AccountCircleIcon />
          </IconButton>
        )}
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          transformOrigin={{vertical: 'top', horizontal: 'right'}}
          role={undefined}
          className={classes.userPopover}
          transition>
          {({TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}>
                    <MenuItem onClick={logOut}>
                      <Trans i18nKey="glossary:logout" />
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </React.Fragment>
    );
  }
  return null;
}
