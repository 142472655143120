import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CoreChip from '@material-ui/core/Chip';

const useStyles = makeStyles({
    root: {
        borderRadius: 0,
    },
});

export default function Chip({className, ...props}) {
  const classes = useStyles();
  return <CoreChip className={clsx(classes.root, className)} {...props} />;
}
