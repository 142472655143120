import React, { useState,  useEffect } from "react"
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from "react-router-dom";
import Amplify, { API } from 'aws-amplify';
import { Typography, Box, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox} from '@material-ui/core';
import NotFound from "views/generic/notfound";
import { CloudDownload, Save } from '@material-ui/icons';
import EditOrderLineItems from "components/editOrderLineItems";
import LanguageDropDown from "components/languageDropDown";
import QuoteSection from 'components/quoteSection';
import FileUpload from 'components/fileUpload';
import FileList from 'components/fileList';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(theme => ({
    downloadZIPButton:{
        marginTop: "auto",
        width: "20vmin",
        alignSelf: "center",
    }
  }
));

export default function ClientConfiguration() {   
    const [lineItems, setLineItems] = useState([])
    const [ itemSummary, setItemSummary ] = useState({});
    const [open, setOpen] = React.useState(false);
    const [age, setAge] = React.useState('');

    const classes = useStyles();
    var refresh = false;
    let { id } = useParams();
    const [lineItemUpdate, setLineItemsUpdate] = useState({
        "id": id,
        "price" : null,
    });
    const [checked, setChecked] = React.useState({
          "standard" : true,
          "professional" : false,
          "Premium" : false,
          "All" : false,

    });

    const [schecked, setsChecked] = React.useState(true);

    const validUUID = (uuid)=> {
        let uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        if(uuidPattern.test(uuid)){
            return true;
        }
        return false;
    }
/*
    if(!validUUID(id)){
        return(
            <NotFound/>
        )
    }
*/

/*const handleChange = event => {
 // setAge(Number(event.target.value) || '');
};
*/

const handleClickOpen = () => {
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};
    const handleChange = (fieldID,value)=> {
        checked[fieldID] = value;
        setChecked(checked);
      }
    
      const updateAll = (value)=> {       
        checked["Standard","Premium","Professional","All"] = value;
        setChecked(checked);
      }

    const shandleChange = event => {
        setsChecked(event.target.checked);
      };
 
    return(
        <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h2" color="secondary" >Client Configuration </Typography>
            <QuoteSection title=" Default Client Price Tiers">
                <Paper>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Standard</TableCell>
                        <TableCell>Professional</TableCell>
                        <TableCell>Premium</TableCell>
                        <TableCell>All</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                            <TableRow>
                              <TableCell scope="row"><Checkbox checked={checked.standard.value} onChange={event => { handleChange("Standard", event.target.checked) } } value="primary"inputProps={{ 'aria-label': 'primary checkbox' }} /></TableCell>
                              <TableCell><Checkbox checked={checked.professional.value} onChange={event => { handleChange("Professional", event.target.checked) } } value="primary"inputProps={{ 'aria-label': 'primary checkbox' }} /></TableCell>
                              <TableCell><Checkbox checked={checked.Premium.value} onChange={event => { handleChange("Premium", event.target.checked) } }value="primary"inputProps={{ 'aria-label': 'primary checkbox' }} /></TableCell>
                              <TableCell><Checkbox checked={checked.All.value} onChange={event => { updateAll( event.target.checked) } } value="primary" inputProps={{ 'aria-label': 'primary checkbox' }} /></TableCell>
                            </TableRow>
                    </TableBody>
                </Table>     
                </TableContainer>          
        </Paper>
        <Paper title="Default">
            <FormGroup row>
              <FormControlLabel
               control={
              <Checkbox checked={schecked} onChange={shandleChange} value="checkedA" />
                       }
                  label="Enable Always Po"
               />
            <TextField id="outlined-basic" label="Always PO" variant="outlined" />
            <FormControlLabel
               control={
              <Checkbox checked={schecked} onChange={shandleChange} value="checkedA" />
                       }
                  label="Enable Repetition Discount"
               />
            <TextField id="outlined-basic" label="Repetition Discount" variant="outlined" />
            </FormGroup>
        </Paper>
        <Paper>
                 
        </Paper>
             
            </QuoteSection>
            <QuoteSection title="Custom Rates">
            <TableContainer>
                <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Source Language</TableCell>
                        <TableCell>Target Langauge</TableCell>
                        <TableCell>Rate</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                            <TableRow>
                              <TableCell scope="row"><LanguageDropDown></LanguageDropDown></TableCell>
                              <TableCell> <LanguageDropDown></LanguageDropDown>
                               </TableCell>
                              <TableCell></TableCell>
                              <TableCell> 
                            </TableCell>
                            </TableRow>
                    </TableBody>
                </Table>     
                </TableContainer>     

                <Button
                    className={classes.downloadZIPButton}
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<Save />}
                >
                    Save
                </Button>


            </QuoteSection>
        </Box>
    )
}
