import React from 'react';
import { Trans } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Typography, Box, Fab } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import logo from 'media/img/logo_global.svg';

const useStyles = makeStyles(theme => ({
  notFoundContainer: {
    height: '100vh'
  },
  logo: {
    height: '30vh'
  },
  notFoundHeader: {
    marginTop: '5vh'
  },
  notFoundDesc: {
    marginTop: '10vh'
  },
  homeButton: {
    marginTop: '10vh'
  }
}));

export default function InvalidProactisRedirect() {
  const classes = useStyles();
  return (
    <Box
      className={classes.notFoundContainer}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Link to="/">
        <img className={classes.logo} src={logo} />
      </Link>
      <Typography className={classes.notFoundHeader} variant="h1">
        Invalid proactis User
      </Typography>
      <Typography variant="h4" className={classes.notFoundDesc}>
        Your proactis account requires an email address to access this service.
      </Typography>
    </Box>
  );
}
