import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import {
  Box,
  Paper,
  IconButton,
  Typography,
  Collapse
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  barContainer: {
    borderRadius: 0,
    padding: '1vmin'
  },
  dropDownIcon: {
    marginLeft: 'auto'
  },
  dropDownDisable: {
    visibility: 'hidden'
  },
  hr: {
    height: 1,
    color: '#dadce0'
  }
}));

export default function DropDownSection(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(
    props.open !== undefined ? props.open : false
  );
  const [disableButton] = useState(
    props.disableButton !== undefined ? props.disableButton : false
  );
  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <Box display="flex" flexDirection="column" width="100%">
        <Paper className={classes.barContainer} elevation={2}>
          <Box display="flex" direction="row" alignItems="center">
            <Typography color="primary" variant="h5">
              {props.title}
            </Typography>
            <IconButton
              size="medium"
              className={clsx(
                { [classes.dropDownDisable]: disableButton },
                classes.dropDownIcon
              )}
              onClick={toggleOpen}
            >
              {open ? <ArrowDropUp /> : <ArrowDropDown />}
            </IconButton>
          </Box>
          <Collapse in={open}>
            <hr className={classes.hr} />
            {props.children}
          </Collapse>
        </Paper>
      </Box>
    </React.Fragment>
  );
}
