import React, { useState, useEffect } from 'react';
import Amplify, { API } from 'aws-amplify';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';

export default function OrderLineItems({ orderId }) {
  const [lineItems, setLineItems] = useState([]);
  const getLineItems = async () => {
    const apiName = 'TransportAPI';
    const path = '/Test/order-services/salesorder/line';
    const session = await Amplify.Auth.currentSession();
    const token = session.idToken.jwtToken;
    API.get(apiName, path, {
      headers: { Authorization: token },
      queryStringParameters: { orderid: orderId }
    })
      .then(response => {
        console.log(response);
        setLineItems(response);
      })
      .catch(error => {
        console.log(error.response);
      });
  };

  useEffect(() => {
    if (lineItems.length === 0) {
      getLineItems();
    }
  });

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left">Source Language</TableCell>
            <TableCell align="left">Target Language</TableCell>
            {lineItems.every(lineItem => lineItem.price >= 0) && (
              <TableCell align="right">Price</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {lineItems.map((lineItem, index) => (
            <TableRow key={index}>
              <TableCell scope="row" align="left">
                {lineItem.src_language_name}
              </TableCell>
              <TableCell align="left">
                {lineItem.target_language_name}
              </TableCell>
              {lineItem.price >= 0 && (
                <TableCell align="right">{'€' + lineItem.price}</TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
