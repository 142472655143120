import React, { useState, useEffect, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Amplify, { API } from 'aws-amplify';
import moment from 'moment';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Stepper,
  Step,
  StepLabel
} from '@material-ui/core';
import {
  AccessTime,
  CalendarToday,
  Work,
  LocalCafe,
  SentimentVerySatisfied,
  Business
} from '@material-ui/icons';
import NotFound from 'views/generic/notfound';
import DropDownSection from 'components/dropDownSection';
import OrderLineItems from 'components/orderLineItems';
import FileUpload from 'components/fileUpload';
import FileList from 'components/fileList.js';
import Button from 'GVComponents/Button.js';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(theme => ({
  projectContainer: {
    margin: '2vmin 10vmin'
  },
  mainSplit: {
    display: 'grid',
    borderRadius: 0,
    padding: '2vmin',
    gridColumnGap: '5vmin',
    gridRowGap: '2.5vmin',
    gridTemplateAreas: '"header header" "status status"',
    gridTemplateColumns: '1fr 1fr'
  },
  statusSection: {
    gridArea: 'status'
  },
  headerSection: {
    gridArea: 'header',
    borderBottom: '1px solid'
  },
  infoSection: {
    display: 'flex'
  },
  notes: {
    border: 'solid 1px #dadce0',
    padding: '1vmin',
    minHeight: '10vh'
  },
  status: {
    padding: 0
  },
  stepDescription: {
    display: 'block',
    textAlign: 'center'
  },
  actionButtons: {
    marginLeft: 'auto'
  }
}));

export default function ProjectManagement() {
  const { t } = useTranslation();
  const classes = useStyles();
  let { id } = useParams();

  const [statusStep, setStatusStep] = useState(0);
  const [projectSummary, setProjectSummary] = useState({});
  const ociFormEl = useRef(null);
  const [ociItemFields, setOCIItemFields] = useState(null);
  const [toneIcons] = useState({
    1: <LocalCafe className={classes.toneIcon} />,
    2: <Work className={classes.toneIcon} />,
    3: <SentimentVerySatisfied className={classes.toneIcon} />,
    4: <Business className={classes.toneIcon} />
  });

  const getProjectSummary = async () => {
    const apiName = 'TransportAPI';
    const path = '/Test/order-services/salesorder/summary';
    const session = await Amplify.Auth.currentSession();
    const token = session.idToken.jwtToken;
    API.get(apiName, path, {
      headers: { Authorization: token },
      queryStringParameters: { orderid: id }
    })
      .then(response => {
        console.log(response);
        setProjectSummary(response[0]);
      })
      .catch(error => {
        console.log(error.response);
      });
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseNo = () => {
    setOpen(false);

  };

  const handleCloseYes = () => {

    executeSaveSatus();

  };

  const [quoteUpdate, setUpdatefield] = useState({
    salesorder_id: id,
    word_count: null,
    repitition_count: null,
    status_id: null,
    external_reference: ''
  });

  const setUpdate = (fieldID, value) => {
    quoteUpdate[fieldID] = value;
    setUpdatefield(quoteUpdate);
  };

  const updateQuoteExternalReference = async () => {
    const apiName = 'TransportAPI';
    const path = '/Test/order-services/salesorder/externalreference';
    let session = await Amplify.Auth.currentSession();
    let token = session.idToken.jwtToken;
    //  console.log(quoteUpdate);
    API.put(apiName, path, {
      body: quoteUpdate,
      headers: { Authorization: token }
    })
      .then(response => {
        //getLineItems();
      })
      .catch(error => {
        console.log(error.response);
      });
  };
  const executeSaveSatus = async () => {
    const apiName = 'TransportAPI';
    const path = '/Test/order-services/salesorder/status';
    let session = await Amplify.Auth.currentSession();
    let token = session.idToken.jwtToken;
    //  console.log(quoteUpdate);
    handleCloseNo();
    if (projectSummary.orderstatus_name === 'Awaiting Authorisation') {
      updateQuoteExternalReference();
      setUpdate('status_id', 4);
    }

    if (projectSummary.orderstatus_name === 'Quote') setUpdate('status_id', 9);

    if (projectSummary.orderstatus_name === 'Review') setUpdate('status_id',5);

    API.put(apiName, path, {
      body: quoteUpdate,
      headers: { Authorization: token }
    })
      .then(response => {
        getProjectSummary();
      })
      .catch(error => {
        console.log(error.response);
      });
  };

  const updateStatus = async () => {

    handleClickOpen();

  };

  const ociAddtoBasket = async () => {
    const apiName = 'TransportAPI';
    const path = '/Test/external-api';
    let session = await Amplify.Auth.currentSession();
    let token = session.idToken.jwtToken;
    API.get(apiName, path, {
      headers: { Authorization: token },
      queryStringParameters: { id: id }
    })
      .then(fields => {
        setOCIItemFields(fields[0]);
      })
      .catch(error => {
        console.log(error.response);
      });
  };

  useEffect(() => {
    if (ociItemFields) {
      ociFormEl.current.submit();
      updateStatus();
    }
  }, [ociItemFields]);

  const validUUID = uuid => {
    let uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    if (uuidPattern.test(uuid)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (Object.entries(projectSummary).length !== 0) {
      return;
    }
    if (!validUUID(id)) {
      return;
    }
    getProjectSummary();
  }, []);

  //this wont work multilingual
  useEffect(() => {
    switch (projectSummary.orderstatus_name) {
      case 'Quote':
        setStatusStep(1);
        break;
      case 'Awaiting Authorisation':
        setStatusStep(2);
        break;
      case 'In Progress':
        setStatusStep(3);
        break;
      case 'Completed':
        setStatusStep(4);
        break;
      default:
        break;
    }
  }, [projectSummary]);

  if (!validUUID(id)) {
    return <NotFound />;
  }
  /*
                        <section className={classes.infoSection}>
                        <Typography>Tone:&emsp;&emsp;&emsp;&emsp;&emsp;</Typography>
                            <TextField variant="outlined" value={projectSummary.tone_name}></TextField>
                        </section>

                        <section className={classes.infoSection}>
                            <Typography>Notes:&nbsp;&nbsp;&emsp;&emsp;&emsp;&emsp;&nbsp;</Typography>
                            <TextField variant="outlined" value={projectSummary.notes}></TextField>
                        </section>
                        */
  return (
    <Box
      className={classes.projectContainer}
      display="flex"
      flexDirection="column"
    >
      <Typography variant="h2" color="secondary" gutterBottom>
        Project Management - {projectSummary.ordertype_name} Project
      </Typography>
      <Paper className={classes.mainSplit}>
        <section className={classes.headerSection}>
          <Box display="flex">
            <Typography variant="h3" color="primary" gutterBottom>
              {projectSummary.order_name ? projectSummary.order_name : id}
            </Typography>
            <Box className={classes.actionButtons}>
              {projectSummary.orderstatus_name === 'Quote' && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={ociAddtoBasket}
                >
                  Add to Cart
                </Button>
              )}
              {projectSummary.orderstatus_name === 'Quote' && (
                <Button variant="contained" color="secondary">
                  Reject
                </Button>
              )}
              {projectSummary.orderstatus_name === 'Awaiting Authorisation' && (
                <TextField
                  variant="outlined"
                  label="Purchase Order No"
                  onChange={event => {
                    setUpdate('external_reference', event.target.value);
                  }}
                ></TextField>
              )}
              {projectSummary.orderstatus_name === 'Awaiting Authorisation' && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={updateStatus}
                >
                  <Typography>Authorise</Typography>
                </Button>
              )}
            
            {projectSummary.orderstatus_name === 'Completed' && (
                <Button
                  variant="contained"
                  color="secondary"
                >
                  <Typography>ORDER DELIVERED</Typography>
                </Button>
              )}
          <br/>
            </Box>
          </Box>
        </section>
        <section className={classes.statusSection}>
          <Typography variant="h4" color="primary">
            Status
          </Typography>
          <Stepper
            className={classes.status}
            activeStep={statusStep}
            alternativeLabel
          >
            <Step color="secondary">
              <StepLabel
                optional={
                  <Typography
                    className={classes.stepDescription}
                    variant="caption"
                  >
                    Requested{' '}
                    {moment(projectSummary.created_date).format('D MMMM YYYY')}
                  </Typography>
                }
              >
                Quote Requested
              </StepLabel>
            </Step>
            <Step>
              <StepLabel>Quoted</StepLabel>
            </Step>
            <Step>
              <StepLabel>Pending Authorisation</StepLabel>
            </Step>
            <Step>
              <StepLabel>Translation In Progress</StepLabel>
            </Step>
            <Step>
              <StepLabel>Completed</StepLabel>
              <Typography className={classes.stepDescription} variant="caption">
                Expected{' '}
                {moment(projectSummary.deadline_date).format(
                  'D MMMM YYYY [at] HH:mm'
                )}
              </Typography>
            </Step>
          </Stepper>
        </section>
        <section>
          <Typography variant="h4" color="primary">
            Languages
          </Typography>
          <OrderLineItems orderId={id} />
          <Typography variant="h4" color="primary">
            Notes
          </Typography>
          <Typography className={classes.notes} variant="body1" color="primary">
            {projectSummary.notes}
          </Typography>
        </section>
        <section>
          <Typography variant="h4" color="primary">
            Uploaded Files
          </Typography>
          <FileList orderId={id} projectOwner={projectSummary.user_id} type="source" />
        </section>
      </Paper>

      {(projectSummary.orderstatus_name === 'Quote' ||
        projectSummary.orderstatus_name === 'Awaiting Authorisation' ||
        projectSummary.orderstatus_name === 'In Progress' ||
        projectSummary.orderstatus_name === 'Review' ||
        projectSummary.orderstatus_name === 'Completed') && (
        <DropDownSection title="Quote Document" open>
          <FileList orderId={id} projectOwner={projectSummary.user_id} type="quote" />
        </DropDownSection>
      )}

    {(projectSummary.orderstatus_name === 'Quote' ||
        projectSummary.orderstatus_name === 'Awaiting Authorisation' ||
        projectSummary.orderstatus_name === 'In Progress' ||
        projectSummary.orderstatus_name === 'Review' ||
        projectSummary.orderstatus_name === 'Completed') && (
        <DropDownSection title="Process Files" open>
          <FileList orderId={id} projectOwner={projectSummary.user_id} type="process" />
        </DropDownSection>
      )}

      {(projectSummary.orderstatus_name === 'Completed' || 
      projectSummary.orderstatus_name === 'Review')  && (
        <DropDownSection title="Translated Files" open>
          <Typography>Linguist Files</Typography>
          <FileList orderId={id} projectOwner={projectSummary.user_id} type="translated" />
        
      <br />

      <Typography>Final Files</Typography>
      <FileList orderId={id} projectOwner={projectSummary.user_id} type="delivery" />
        
        <br />
        {projectSummary.orderstatus_name === 'Review' && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={updateStatus}
                >
                  <Typography>Complete Order</Typography>
                </Button>
              )}
          <br/>
    
      <Typography>Upload Files</Typography>
      {projectSummary.id && ( //hack to get around prop not updating
        <FileUpload
        onChange={() => {}}
        uploadTo="delivery"
        projectOwner={projectSummary.user_id}
        quoteID={projectSummary.id}
      />
      )}

    </DropDownSection>

      )}
      <form
        action="https://pimsearch.perfect.com/se-oci-test/inbound.jsp"
        method="post"
        ref={ociFormEl}
      >
        {ociItemFields &&
          Object.keys(ociItemFields).map(key => (
            <input
              key={key}
              type="hidden"
              name={key + '[1]'}
              value={ociItemFields[key]}
            />
          ))}
      </form>

      <Dialog
        open={open}
        onClose={handleCloseNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure you want to submit?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           !!!THIS WILL MAKE THE FINAL FILES VISIBLE TO THE CLIENT AND NOTIFY THEM OF DELIVERY!!!<br/><br/>
           Submitting will progress thiis project to DELIVER. Are you sure you want to submit?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNo} className={classes.saveButton}
            variant="contained"
            color="primary">
            NO
          </Button>
          <Button onClick={handleCloseYes} className={classes.saveButton}
            variant="contained"
            color="primary" autoFocus>
            YES
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
  );
}
