import React, { useState, useEffect } from 'react';
import Amplify, { API } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import { loadCSS } from 'fg-loadcss';
import {
  Box,
  Input,
  InputAdornment,
  Typography,
  Fade,
  Grow,
  Popper,
  Paper,
  Icon,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import { Search, Add, Close } from '@material-ui/icons';
import Fab from 'GVComponents/Fab';
import Chip from 'GVComponents/Chip';

const useStyles = makeStyles(theme => ({
  addLanguagePopperButton: {
    marginLeft: 'auto',
    minWidth: 40,
    minHeight: 40
  },
  languageChip: {
    margin: '0.1vw'
  },
  languageSelectedContainer: {
    margin: '0 0.5vw'
  },
  languageSelectContainer: {
    borderRadius: 0,
    marginBottom: '2vh',
    padding: '1vmin',
    backgroundColor: 'rgb(211, 211, 211)'
  },
  languageSelectPaper: {
    borderRadius: 0,
    padding: '1.5vmin'
  },
  languageSelectItemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginTop: '1vmin'
    }
  },
  //copy paste from old react material works. dont know any other way right now
  //this allow there to be an arrow
  popper: {
    zIndex: 1,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.common.white} transparent`
      }
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${theme.palette.common.white} transparent transparent transparent`
      }
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.common.white} transparent transparent`
      }
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.common.white}`
      }
    }
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid'
    }
  }
}));

export default function LanguageSelect(props) {
  const classes = useStyles();
  const [languages, setLanguages] = useState([]);
  const [languagesSearch, setLanguagesSearch] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [arrowRef, setArrowRef] = React.useState(null);
  const [openLanguageSelection, setOpenLanguageSelection] = React.useState(
    false
  );

  const addSelectedLanguage = languageToAdd => {
    let newSelectedLanguages;
    let newIsoCodes;
    if (props.singleLanguage) {
      newSelectedLanguages = [languageToAdd];
    }

    if (!props.singleLanguage && !selectedLanguages.includes(languageToAdd)) {
      newSelectedLanguages = [...selectedLanguages, languageToAdd];
    }

    if (newSelectedLanguages) {
      setSelectedLanguages(newSelectedLanguages);
      props.onChange(newSelectedLanguages);
    }
  };

  const removeSelectedLanguage = languageToRemove => {
    if (props.singleLanguage) {
      return;
    }
    let newSelectedLanguages = selectedLanguages.filter(
      language => language !== languageToRemove
    );
    setSelectedLanguages(newSelectedLanguages);
    props.onChange(newSelectedLanguages);
  };

  const toggleLanguageSelection = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpenLanguageSelection(!openLanguageSelection);
  };

  const handleArrowRef = node => {
    setArrowRef(node);
  };

  const getLanguages = async () => {
    const apiName = 'TransportAPI';
    const path = '/Test/language-services/language';
    const session = await Amplify.Auth.currentSession();
    const token = session.idToken.jwtToken;
    API.get(apiName, path, { headers: { Authorization: token } })
      .then(response => {
        setLanguages(response);
        setLanguagesSearch(response);
      })
      .catch(error => {
        console.log(error.response);
      });
  };

  const search = searchterm => {
    let newLanguagesSearch = languages.filter(
      language =>
        language.language_name.toLowerCase().indexOf(searchterm.toLowerCase()) >
        -1
    );
    setLanguagesSearch(newLanguagesSearch);
  };

  useEffect(() => {
    if (languages.length === 0) {
      getLanguages();
    }
    setSelectedLanguages(props.value);
    loadCSS(
      'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
      document.querySelector('#font-awesome-css')
    );
  }, [props.value]);

  return (
    <React.Fragment>
      <Popper
        className={classes.popper}
        open={openLanguageSelection}
        anchorEl={anchorEl}
        placement="bottom"
        modifiers={{
          flip: {
            enabled: true
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'window'
          },
          arrow: {
            enabled: true,
            element: arrowRef
          }
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <React.Fragment>
              <span className={classes.arrow} ref={handleArrowRef} />
              <Paper className={classes.languageSelectPaper} elevation={3}>
                <Input
                  startAdornment={
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  }
                  onChange={e => {
                    search(e.target.value);
                  }}
                />
                <IconButton onClick={toggleLanguageSelection}>
                  <Close />
                </IconButton>
                <Box className={classes.languageSelectItemsContainer}>
                  {languagesSearch.length === 0 && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignContent="center"
                    >
                      <CircularProgress size={60} />
                    </Box>
                  )}
                  {languagesSearch.slice(0, 4).map(language => (
                    <Chip
                      color="primary"
                      className={classes.languageButton}
                      key={language.language_name}
                      label={language.language_name}
                      clickable
                      onClick={() => {
                        addSelectedLanguage(language);
                      }}
                    />
                  ))}
                </Box>
              </Paper>
            </React.Fragment>
          </Fade>
        )}
      </Popper>
      <Box
        className={classes.languageSelectContainer}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Typography variant="h6">{props.heading}</Typography>
        <Box className={classes.languageSelectedContainer}>
          {selectedLanguages.map(language => (
            <Grow key={language.language_name} in>
              <Chip
                className={classes.languageChip}
                label={language.language_name}
                onDelete={
                  !props.singleLanguage
                    ? () => {
                        removeSelectedLanguage(language);
                      }
                    : null
                }
                color="primary"
              />
            </Grow>
          ))}
        </Box>
        <Fab
          className={classes.addLanguagePopperButton}
          size="small"
          color="secondary"
          onClick={toggleLanguageSelection}
        >
          {props.singleLanguage && selectedLanguages.length > 0 ? (
            <Icon className="fas fa-exchange-alt" />
          ) : (
            <Add />
          )}
        </Fab>
      </Box>
    </React.Fragment>
  );
}
