import React, { useState, useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { NavLink, Link } from 'react-router-dom';
import {
  Drawer,
  AppBar,
  Toolbar,
  Box,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Icon,
  Typography,
  Paper
} from '@material-ui/core';
import { Menu, Warning } from '@material-ui/icons';
import ProfileWidget from 'components/profileWidget';
import { UserContext } from 'components/UserContext';
import LanguageSwitcher from 'components/languageSwitcher';
import logo from '../media/img/logo_global.svg';
import '../fonts/Pe-icon-7-stroke.css';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#fafbfc',
    boxShadow:
      '0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03)'
  },
  warningContainer: {
    backgroundColor: '#f5d562',
    height: '60%',
    padding: '1vmin'
  },
  menuIcon: {
    color: '#3f6ad8'
  },
  list: {
    display: 'flex',
    flexDirection: 'column'
  },
  listHeading: {
    fontWeight: 'bold',
    color: '#3f6ad8'
  },
  listHeadingCompact: {
    textIndex: '-999em',
    borderBottom: '1px #3f6ad8 solid'
  },
  listIcon: {
    minWidth: 30
  },
  noWrap: {
    whiteSpace: 'nowrap'
  },
  listButton: {
    alignSelf: 'center',
    width: '90%',
    borderRadius: 5,
    marginTop: '0.2vmin',
    marginBottom: '0.1vmin',
    transition: '0.1s ease-out',
    '&:hover': {
      transition: '0.1s ease-in',
      backgroundColor: '#e0f3ff'
    }
  },
  listButtonActive: {
    backgroundColor: '#e0f3ff'
  },
  drawer: {
    width: drawerWidth
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  content: {
    backgroundColor: theme.palette.primary.main,
    flexGrow: 1
  },
  logo: {
    width: '4.5vw',
    padding: 10
  },
  moveRight: {
    marginLeft: 'auto'
  },
  toolbar: theme.mixins.toolbar
}));

export default function Nav(props) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const userInfo = useContext(UserContext);
  const [navLinks, setNavLinks] = useState([]);
  const [clickedOpen, setClickedOpen] = useState(true);
  const [open, setOpen] = useState(true);
  const toggleClickedOpen = () => {
    setClickedOpen(!clickedOpen);
    toggleOpen();
  };
  const toggleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
  //  if (userInfo.groups.length === 0) {
      setNavLinks([
        {
          text: t('nav:dashboard'),
          path: '/dashboard',
          icon: <Icon className="pe-7s-rocket" />
        },
        {
          text: t('nav:quotesProjects'),
          path: '/projects',
          icon: <Icon className="pe-7s-world" />
        },
        {
          text: t('nav:requestQuote'),
          path: '/project/new',
          icon: <Icon className="pe-7s-note2" />
        }
      ]);
      return;
 //   }
    // if (userInfo.groups[0] === 'Admin') {
    //   setNavLinks([
    //     {
    //       text: t('nav:findQuote'),
    //       path: '/',
    //       icon: <Icon className="pe-7s-rocket" />
    //     }
    //   ]);
    //}
  }, [i18n.language]);

  /*

            <ListSubheader
              className={clsx(
                {[classes.listeHeadingCompact]: !open},
                classes.listHeading,
                classes.noWrap,
              )}>
              {open ? <Trans i18nKey="nav:getInTouch" /> : '-'}
            </ListSubheader>
            <ListItem
              className={classes.listButton}
              component={NavLink}
              to="/"
              button>
              <ListItemIcon className={clsx({[classes.listIcon]: open})}>
                <Icon className="pe-7s-mail" />
              </ListItemIcon>
              <ListItemText
                className={classes.noWrap}
                primaryTypographyProps={{variant: 'body2'}}
                primary={t('nav:contact')}
              />
            </ListItem>
            */

  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <AppBar position="sticky" color="inherit" className={classes.appBar}>
        <Toolbar>
          <IconButton onClick={toggleClickedOpen}>
            <Menu className={classes.menuIcon} />
          </IconButton>
          <Link to="/">
            <img className={classes.logo} src={logo} alt="globalvoices logo" />
          </Link>
          {!!window.MSInputMethodContext && !!document.documentMode ? (
            <Paper className={classes.warningContainer}>
              <Box display="flex" alignItems="center" height="100%">
                <Warning />
                <Typography variant="body2">
                  Your browser is outdated and is soon to be end of life. Some
                  pages may not load correctly. For the best experience, please
                  update your browser.
                </Typography>
              </Box>
            </Paper>
          ) : null}
          <div className={classes.moveRight}>
            {/*<LanguageSwitcher />*/}
            <ProfileWidget />
          </div>
        </Toolbar>
      </AppBar>

      <div className={classes.root}>
        <Drawer
          onMouseEnter={() => (!clickedOpen ? setOpen(true) : null)}
          onMouseLeave={() => (!clickedOpen ? setOpen(false) : null)}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })}
          classes={{
            paper: clsx(classes.drawerPaper, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })
          }}
          variant="permanent"
        >
          <div className={classes.toolbar} />
          <List
            className={classes.list}
            subheader={
              <ListSubheader
                className={clsx(classes.listHeading, classes.noWrap)}
              >
                {open ? <Trans i18nKey="nav:yourPortal" /> : '-'}
              </ListSubheader>
            }
          >
            {navLinks.map(link => (
              <ListItem
                className={classes.listButton}
                component={NavLink}
                to={link.path}
                activeClassName={classes.listButtonActive}
                key={link.text}
                button
              >
                <ListItemIcon className={clsx({ [classes.listIcon]: open })}>
                  {link.icon}
                </ListItemIcon>
                <ListItemText
                  className={classes.noWrap}
                  primaryTypographyProps={{ variant: 'body2' }}
                  primary={link.text}
                />
              </ListItem>
            ))}
          </List>
        </Drawer>
        <main className={classes.content}>{props.children}</main>
      </div>
    </Box>
  );
}
