import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CoreFab from '@material-ui/core/Fab';

const useStyles = makeStyles({
    root: {
        borderRadius: 0,
    },
});

export default function Fab({className, children, ...props}) {
  const classes = useStyles();
  return <CoreFab className={clsx(classes.root, className)} {...props} >{children}</CoreFab>;
}
