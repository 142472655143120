import React from "react";
import {
  fade,
  //ThemeProvider,
  withStyles,
  makeStyles
} from "@material-ui/core/styles";
import {
  InputBase,
  RadioGroup,
  Radio,
  Checkbox,
  Grid,
  Link,
  FormControlLabel,
  Select
} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from '@material-ui/core/MenuItem';

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
      width: "100%"
    },
    width: "100%"
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}))(InputBase);

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  margin: {
    margin: theme.spacing(0.1),
    width: "100%"
  }
}));

function CustomizedInputs() {
  const classes = useStyles();
  const countries = [
    "USA", "Canada", "United Kingdom",
    "Italy",
    "Spain",
    "France",
    "Germany",
    "China",
    "Iran",
    "South Korea"
  ];
  const timezone = [
    "GMT - 4",
    "GMT - 3",
    "GMT - 2",
    "GMT - 1",
    "GMT 0",
    "GMT + 1",
    "GMT + 2",
    "GMT + 3",
    "GMT + 4"
  ];
  const [timezoneValue, setTimezoneValue] = React.useState('None');
  const [countryValue, setCountryValue] = React.useState('None');
  const handleTimeChange = (event) => {
    setTimezoneValue(event.target.value);
  };
  const handleCountryChange = (event) => {
    setCountryValue(event.target.value);
  };
  return (
    <div>
      <form className={classes.root} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={5} lg={5}>
            <FormControl className={classes.margin}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ color: "#263e80", fontWeight: "bold" }}
              >
                Email Address*
              </InputLabel>
              <BootstrapInput
                id="bootstrap-input"
                placeholder="Enter your email address"
              />
            </FormControl>
          </Grid>
          <Grid item xs={5} lg={5}>
            <FormControl
              size="small"
              style={{ paddingTop: "2vh" }}
              className={classes.margin}
            >
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ color: "#263e80", fontWeight: "bold" }}
              >
                Time Zone*
              </InputLabel>
              <Select
                label="timezone"
                value={timezoneValue}
                onChange={handleTimeChange}
                inputProps={{
                  name: "United Kingdom",
                  id: "outlined-age-native-simple"
                }}
              >
                <MenuItem aria-label="None" value="None">
                  None
                </MenuItem>
                {timezone.map(time => {
                  return <MenuItem value={time} >{time}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={5} lg={5}>
            <FormControl className={classes.margin}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ color: "#263e80", fontWeight: "bold" }}
              >
                Password*
              </InputLabel>
              <BootstrapInput
                id="bootstrap-input"
                placeholder="Choose a password"
              />
            </FormControl>
          </Grid>
          <Grid item xs={5} lg={5}>
            <FormControl className={classes.margin}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ color: "#263e80", fontWeight: "bold" }}
              >
                Confirm your password*
              </InputLabel>
              <BootstrapInput
                id="bootstrap-input"
                placeholder="Please confirm your password"
              />
            </FormControl>
          </Grid>
          <Grid item xs={5} lg={5}>
            <FormControl className={classes.margin}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ color: "#263e80", fontWeight: "bold" }}
              >
                First name*
              </InputLabel>
              <BootstrapInput id="bootstrap-input" placeholder="E.g John" />
            </FormControl>
          </Grid>
          <Grid item xs={5} lg={5}>
            <FormControl className={classes.margin}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ color: "#263e80", fontWeight: "bold" }}
              >
                Last name*
              </InputLabel>
              <BootstrapInput id="bootstrap-input" placeholder="E.g Doe" />
            </FormControl>
          </Grid>
          <Grid item xs={5} lg={5}>
            <FormControl className={classes.margin}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ color: "#263e80", fontWeight: "bold" }}
              >
                Telephone*
              </InputLabel>
              <BootstrapInput
                id="bootstrap-input"
                placeholder="E.g +44 7747 477 412"
              />
            </FormControl>
          </Grid>
          <Grid item xs={5} lg={5} />
          <Grid item xs={5} lg={5}>
            <FormControl className={classes.margin}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ color: "#263e80", fontWeight: "bold" }}
              >
                Who are you?*
              </InputLabel>
              <RadioGroup
                style={{ marginTop: "3vh" }}
                row
                aria-label="position"
                name="position"
                defaultValue="top"
              >
                <FormControlLabel
                  value="Company"
                  style={{ color: "#777777", fontWeight: "400" }}
                  control={<Radio size="small" color="primary" />}
                  label="Company"
                />
                <FormControlLabel
                  style={{ color: "#777777", fontWeight: "400" }}
                  value="Individual"
                  control={<Radio size="small" color="primary" />}
                  label="Individual"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={5} lg={5}>
            <FormControl className={classes.margin}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ color: "#263e80", fontWeight: "bold" }}
              >
                Company name*
              </InputLabel>
              <BootstrapInput
                id="bootstrap-input"
                placeholder="E.g Pearl Ltd"
              />
            </FormControl>
          </Grid>
          <Grid item xs={5} lg={5}>
            <FormControl className={classes.margin}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ color: "#263e80", fontWeight: "bold" }}
              >
                Address (line1)*
              </InputLabel>
              <BootstrapInput
                id="bootstrap-input"
                placeholder="21 Lion Bridge"
              />
            </FormControl>
          </Grid>
          <Grid item xs={5} lg={5}>
            <FormControl className={classes.margin}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ color: "#263e80", fontWeight: "bold" }}
              >
                Address (line2)*
              </InputLabel>
              <BootstrapInput
                id="bootstrap-input"
                placeholder="E.g Innovation Park"
              />
            </FormControl>
          </Grid>
          <Grid item xs={5} lg={5}>
            <FormControl className={classes.margin}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ color: "#263e80", fontWeight: "bold" }}
              >
                City*
              </InputLabel>
              <BootstrapInput id="bootstrap-input" placeholder="E.g London" />
            </FormControl>
          </Grid>
          <Grid item xs={5} lg={5}>
            <FormControl className={classes.margin}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ color: "#263e80", fontWeight: "bold" }}
              >
                Postcode*
              </InputLabel>
              <BootstrapInput id="bootstrap-input" placeholder="E.g W3G0PW" />
            </FormControl>
          </Grid>
          <Grid item xs={5} lg={5}>
            <FormControl
              size="small"
              style={{ paddingTop: "2vh" }}
              className={classes.margin}
            >
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ color: "#263e80", fontWeight: "bold" }}
              >
                Country*
              </InputLabel>
              <Select
                outlined
                label="Countries"
                value={countryValue}
                onChange={handleCountryChange}
                inputProps={{
                  name: "United Kingdom",
                  id: "outlined-age-native-simple"
                }}
              >
                <MenuItem aria-label="None" value="None">
                  None
                </MenuItem>
                {countries.map(country => {
                  return <MenuItem value={country}>{country}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={5} lg={5}>
            <FormControl className={classes.margin}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ color: "#263e80", fontWeight: "bold" }}
              >
                VAT Number(EU only)*
              </InputLabel>
              <BootstrapInput
                id="bootstrap-input"
                placeholder="E.g 1234548913"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <RadioGroup
              style={{ marginTop: "1vh" }}
              row
              aria-label="position"
              name="position"
              defaultValue="top"
            >
              <FormControlLabel
                type="Checkbox"
                control={
                  <Checkbox
                    iconStyle={{ width: "0.1vh", height: "0.1vh" }}
                    color="primary"
                  />
                }
              />

              <div style={{ marginTop: "1vh" }}>
                I have read and agree to the{" "}
                <Link href="https://www.globalvoices.co.uk/privacy-policy/">Privacy Policy</Link> and{" "}
                <Link href="https://www.globalvoices.co.uk/terms-conditions/" style={{ marginTop: ".5%" }}>
                  {" "}
                  Terms & Conditions.
                </Link>
              </div>
            </RadioGroup>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
export default CustomizedInputs;
