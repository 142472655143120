import React, { Component } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import Amplify, { API } from 'aws-amplify';

import {
  AddBox,
  ArrowDownward,
  Search,
  ViewColumn,
  SaveAlt,
  ChevronLeft,
  ChevronRight,
  FirstPage,
  LastPage,
  Add,
  Check,
  FilterList,
  Remove,
  AddBoxOutlined,
  Clear,
  Edit,
  Delete,
  DeleteOutline,
} from "@material-ui/icons";

class Root extends Component {
  state = {
    tableLang: [],
    source: ""
  };


  getPrice = async (language) => {
    let tl=language.isocode + ",";

    const apiName = 'TransportAPI';
      const path = '/Test/order-services/salesorder/pricing';
      const session = await Amplify.Auth.currentSession();
      const token = session.idToken.jwtToken;
          console.log ("calling ");
      return API.get(apiName, path, { headers: { Authorization: token }, 
          queryStringParameters: { 
              // Pass in the quote request settings that effect the price
              source_language: this.props.quote.source_language.map(
                  language => language.isocode
                )[0],
              target_language : tl,
              rule_group_id : 0,
              deadline_date : this.props.quote.deadline_date,
              word_count : this.props.quote.word_count,
              rep_count: null,
              price : null, 
              servicelevel_id : 5,
              company_id: null} })
          
  }

  componentDidUpdate(){
    if (this.props.quote.target_languages.length && this.props.quote.source_language.length && (this.props.quote.source_language[0].isocode !== this.state.source || this.state.tableLang.length !== this.props.quote.target_languages.length)){
      let tableLang = [];
        this.props.quote.target_languages.map((language, index) => {
          let price = 0;
          let rate = 0;
          this.getPrice(language)
          .then(response => {
            console.log (response);
            // Force the price and rate to show 2 decimal places
            price =  (Math.round(response.price * 100) / 100).toFixed(2);
            rate = (Math.round(response.rate * 100) / 100).toFixed(2);

            if (this.props.quote.has_unselectables == false)
            tableLang.push({
              name: this.props.quote.source_language[0].language_name + ' - ' + language.language_name,
              rate: rate + "/Word",
              words: this.props.quote.word_count,
              total: String.fromCharCode(163) + price
            })
            else
              tableLang.push({
              name: this.props.quote.source_language[0].language_name + ' - ' + language.language_name,
              rate:  rate + "/Word",
              words: this.props.quote.word_count
            });

            if (index === this.props.quote.target_languages.length - 1){
              this.setState({source: this.props.quote.source_language[0].isocode,
                tableLang: tableLang
              });
            }
          })
          .catch(error => {
            console.log(error);
          });
        });
    }
  }

  componentDidMount(){
    if (this.props.quote.target_languages.length && this.props.quote.source_language.length && (this.props.quote.source_language[0].isocode !== this.state.source || this.state.tableLang.length !== this.props.quote.target_languages.length)){
      let tableLang = [];
        this.props.quote.target_languages.map((language, index) => {
          let price = 0;
          let rate = 0;
          this.getPrice(language)
          .then(response => {
            console.log (response);
            // Force the price and rate to show 2 decimal places
            price =  (Math.round(response.price * 100) / 100).toFixed(2);
            rate = (Math.round(response.rate * 100) / 100).toFixed(2);
            if (this.props.quote.has_unselectables == false)
              tableLang.push({
              name: this.props.quote.source_language[0].language_name + ' - ' + language.language_name,
              rate:  rate + "/Word",
              words: this.props.quote.word_count,
              total: String.fromCharCode(163) + price
            })
            else
              tableLang.push({
              name: this.props.quote.source_language[0].language_name + ' - ' + language.language_name,
              rate:  rate + "/Word",
              words: this.props.quote.word_count
            });

            if (index === this.props.quote.target_languages.length - 1){
              this.setState({source: this.props.quote.source_language[0].isocode,
                tableLang: tableLang
              });
            }
          })
          .catch(error => {
            console.log(error);
          });
        });
    }
  }

  removeSelectedLanguage = languageToRemove => {
    let selectedLanguages = [...this.props.quote.target_languages];
    console.log(selectedLanguages);
    let newSelectedLanguages = selectedLanguages.filter(
      language => {
        console.log(languageToRemove);
        return  language.language_name !== languageToRemove.name
      } 
    );
    console.log(newSelectedLanguages);
    let tableLang = [];
      newSelectedLanguages.map((language) => {
        tableLang.push({
          name: language.language_name,
          rate: "$1/Word",
          words: "1987",
          total: "$63"
        });
      });
      this.setState({tableLang: tableLang});
    this.props.onChange(newSelectedLanguages);
  };

  render() {
    return (
      <div >
        {/* <Grid
          container
          lg={7}
          xs={7}
          style={{
            padding: "2vh",
            backgroundColor: "#fff",
          }}
        >
          <CustomizedInputs />
        </Grid> */}
        <Grid item xs={12}>
        {(this.state.tableLang.length > 0 && this.props.quote.has_unselectables == false) && (
          <MaterialTable
            icons={{
              Check: Check,
              DetailPanel: ChevronRight,
              Export: SaveAlt,
              Filter: FilterList,
              FirstPage: FirstPage,
              LastPage: LastPage,
              NextPage: ChevronRight,
              PreviousPage: ChevronLeft,
              Search: Search,
              ThirdStateCheck: Remove,
              SortArrow: ArrowDownward,
              Add: AddBox,
              ViewColumn: ViewColumn,
              Clear: Clear,
              ResetSearch: Clear,
              Edit: Edit,
              Delete: DeleteOutline,
            }}
            title="Simple Action Preview"
            columns={[
              { title: "Language", field: "name" },
              { title: "Rate", field: "rate" },
              { title: "Words", field: "words" },
              { title: "Total", field: "total" },
            ]}
            data={this.state.tableLang}
            actions={[
              (rowData) => ({
                icon: Delete,
                tooltip: "Delete Row",
                onClick: (event, rowData) => this.removeSelectedLanguage(rowData),
              }),
            ]}
            options={{
              header: true,
              toolbar: false,
              paging: false,
              actionsColumnIndex: -1,
            }}
          />
          
        )}

{(this.state.tableLang.length > 0 && this.props.quote.has_unselectables == true) && (
          <MaterialTable
            icons={{
              Check: Check,
              DetailPanel: ChevronRight,
              Export: SaveAlt,
              Filter: FilterList,
              FirstPage: FirstPage,
              LastPage: LastPage,
              NextPage: ChevronRight,
              PreviousPage: ChevronLeft,
              Search: Search,
              ThirdStateCheck: Remove,
              SortArrow: ArrowDownward,
              Add: AddBox,
              ViewColumn: ViewColumn,
              Clear: Clear,
              ResetSearch: Clear,
              Edit: Edit,
              Delete: DeleteOutline,
            }}
            title="Simple Action Preview"
            columns={[
              { title: "Language", field: "name" },
              { title: "Rate", field: "rate" },
              { title: "Words", field: "words" },
            ]}
            data={this.state.tableLang}
            actions={[
              (rowData) => ({
                icon: Delete,
                tooltip: "Delete Row",
                onClick: (event, rowData) => this.removeSelectedLanguage(rowData),
              }),
            ]}
            options={{
              header: true,
              toolbar: false,
              paging: false,
              actionsColumnIndex: -1,
            }}
          />
        )}
      </Grid>
      </div>
    );
  }
}

export default Root;
