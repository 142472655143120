import React, { useState, useEffect } from 'react';
import jwt from 'jwt-decode';
import { Route, Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { UserContext } from 'components/UserContext';
import NotFound from 'views/generic/notfound';

export default function PrivateRoute({ children, allowedGroups, ...rest }) {
  const authenticationState = {
    notAuthenticated: -1,
    unknown: 0,
    authenticated: 1
  };
  const [user, setUser] = useState(null);
  const [groups, setGroups] = useState([]);
  const [authorized, setAuthorized] = useState(false);
  const [isAuthenticated, setAuthenticationState] = useState(0);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(cognitoUser => {
        if (cognitoUser != null) {
          setUser(cognitoUser);
          cognitoUser.getSession(function(err, session) {
            if (err) {
              setAuthenticationState(authenticationState.notAuthenticated);
              return;
            }
            let sessionIdInfo = jwt(session.getIdToken().jwtToken);
            let updatedGroups = sessionIdInfo['cognito:groups']
              ? sessionIdInfo['cognito:groups']
              : [];
            setGroups(updatedGroups);
            setAuthenticationState(authenticationState.authenticated);
            setAuthorized(checkAuthorized(updatedGroups));
          });
        }
      })
      .catch(e => {
        setAuthenticationState(authenticationState.notAuthenticated);
      });
  }, []);

  const hasRequiredAttributes = () => {
    if (user.attributes.name) {
      return true;
    }
    return false;
  };

  const checkAuthorized = groups => {
    if (allowedGroups !== undefined) {
      if (groups.length === 0) {
        return false;
      }
      if (!allowedGroups.includes(groups[0])) {
        return false;
      }
      return true;
    }
    return true;
  };

  return (
    <UserContext.Provider
      value={{
        user: user,
        groups: groups
      }}
    >
      <Route
        {...rest}
        render={({ location }) => {
          switch (isAuthenticated) {
            case authenticationState.authenticated:
              console.log(user.attributes);
              if (!hasRequiredAttributes()) {
                console.log('here');
                return (
                  <Redirect
                    to={{
                      pathname: '/updateattributes',
                      state: { user: user }
                    }}
                  />
                );
              }
              if (!authorized) {
                return <NotFound />;
              }
              return children;
            case authenticationState.notAuthenticated:
              let redirect = '';
              if (location.pathname != '/') {
                redirect = '?redirect=' + location.pathname;
              }
              return (
                <Redirect
                  to={{
                    pathname: '/login',
                    search: redirect,
                    state: { from: location }
                  }}
                />
              );
            default:
              return <p>loading</p>;
          }
        }}
      />
    </UserContext.Provider>
  );
}
