import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import Amplify, { API } from 'aws-amplify';
import {
  Typography,
  Box,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Snackbar,
  Paper,
  Divider
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import NotFound from 'views/generic/notfound';
import { CloudDownload, Save } from '@material-ui/icons';
import EditOrderLineItems from 'components/editOrderLineItems';
import QuoteSection from 'components/quoteSection';
import FileUpload from 'components/fileUpload';
import FileList from 'components/fileList';
import DateTimeSelect from 'components/dateTimeSelect';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const useStyles = makeStyles(theme => ({
  downloadZIPButton: {
    marginTop: 'auto',
    width: '20vmin',
    alignSelf: 'center'
  },
  fileUpload: {
    minHeight: '15vh'
  },
  fixWidth: {
    width: '40vw'
  },
  fileList: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '20vw'
  },
  saveButton: {
    height: '65%'
  }
}));


export default function ProjectAnalysis() {
  const [lineItems, setLineItems] = useState([]);
  const [projectSummary, setProjectSummary] = useState({});
  const [itemSummary, setItemSummary] = useState({});
  const classes = useStyles();
  let { id } = useParams();
  const [lineItemUpdate, setLineItemsUpdate] = useState({
    id: id,
    price: null
  });

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseNo = () => {
    setOpen(false);

  };

  const handleCloseYes = () => {

    executeSaveSatus();

  };

const executeSaveSatus = async () => {

  const apiName = 'TransportAPI';
    const path = '/Test/order-services/salesorder/status';
    let session = await Amplify.Auth.currentSession();
    let token = session.idToken.jwtToken;
    let newStatus_id = null
    console.log(projectSummary.orderstatus_id)
    console.log(projectSummary.orderstatus_id === 2)

    handleClickOpen();

    handleSaving();
    if (projectSummary.orderstatus_id === 2) {
      newStatus_id = 3;
    }

    if (projectSummary.orderstatus_id === 4) {
      newStatus_id = -1;
    }
    console.log({status_id: newStatus_id, salesorder_id: id})
    API.put(apiName, path, {
      body: {status_id: newStatus_id, salesorder_id: id},
      headers: { Authorization: token }
    })
      .then(response => {
        console.log(response)
        getLineItems();
        handleSaved();
      })
      .catch(error => {
        console.log(error.response);
      });

}
const [savingOpen, setSavingOpen] = React.useState(false);

const handleSaving = () => {
  setSavingOpen(true);
  setOpen(false);
};

const handleSavingClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }

  setSavingOpen(false);
};

const [savedOpen, setSavedOpen] = React.useState(false);

const handleSaved = () => {
  setSavedOpen(true);
};

const handleSavedClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }

  setSavedOpen(false);
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

  const validUUID = uuid => {
    let uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    if (uuidPattern.test(uuid)) {
      return true;
    }
    return false;
  };

  const [quoteUpdate, setQuoteUpdate] = useState({
    salesorder_id: id,
    word_count: null,
    repitition_count: null,
    status_id: null,
    quoteno: null,
    projectno: null,
    project_unique_code: null,
    creator_email: null,
    deadline_date:null,
    timezone:null
  });

  /*const [deadlineUpdate, setDeadline] = useState({
    deadline_date: null,
    timezone: ''
  });*/

  const setQuoteField = fieldID => {
    return value => {
      setQuoteUpdate(Object.assign({}, quoteUpdate, { [fieldID]: value }));
    };
  };
  useEffect(() => {
    if (lineItems.length === 0) {
      getLineItems();
    }
    if (Object.entries(projectSummary).length === 0) {
      getProjectSummary();
    }
  });

  if (!validUUID(id)) {
    return <NotFound />;
  }

  const getProjectSummary = async () => {
    const apiName = 'TransportAPI';
    const path = '/Test/order-services/salesorder/summary';
    const session = await Amplify.Auth.currentSession();
    const token = session.idToken.jwtToken;
    API.get(apiName, path, {
      headers: { Authorization: token },
      queryStringParameters: { orderid: id }
    })
      .then(response => {
        console.log(response);
        setProjectSummary(response[0]);
      })
      .catch(error => {
        console.log(error.response);
      });
  };

  const updateQuote = async () => {
    const apiName = 'TransportAPI';
    const path = '/Test/order-services/salesorder';
    let session = await Amplify.Auth.currentSession();
    let token = session.idToken.jwtToken;
    //  console.log(quoteUpdate);

    handleSaving();
    API.put(apiName, path, {
      body: quoteUpdate,
      headers: { Authorization: token }
    })
      .then(response => {
        getLineItems();
        handleSaved();
      })
      .catch(error => {
        console.log(error.response);
      });
  };

  // const SendIP = async () => {
  //   const apiName = 'TransportAPI';
  //   const path = '/Test/email-services/2/sgbamnotifyproject';
  //   let session = await Amplify.Auth.currentSession();
  //   let token = session.idToken.jwtToken;
  //   //  console.log(quoteUpdate);
  //   API.post(apiName, path, {
  //     body: quoteUpdate,
  //     headers: { Authorization: token }
  //   })
  //     .then(response => {
  //       getLineItems();
  //     })
  //     .catch(error => {
  //       console.log(error.response);
  //     });
  // };


  const updateStatus = async () => {
    
      handleClickOpen();

  };

  const getLineItems = async () => {
    const apiName = 'TransportAPI';
    const path = '/Test/order-services/salesorder/line';
    const session = await Amplify.Auth.currentSession();
    const token = session.idToken.jwtToken;
    API.get(apiName, path, {
      headers: { Authorization: token },
      queryStringParameters: { orderid: id }
    })
      .then(response => {
        setLineItems(response);
      })
      .catch(error => {
        console.log(error.response);
      });
  };

  const updateLineItem = async () => {
    const apiName = 'TransportAPI';
    const path = '/Test/order-services/salesorder/line';
    let session = await Amplify.Auth.currentSession();
    let token = session.idToken.jwtToken;
    //  console.log(quoteUpdate);
    API.put(apiName, path, {
      body: lineItemUpdate,
      headers: { Authorization: token }
    })
      .then(response => {})
      .catch(error => {
        console.log(error.response);
      });
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">

<Snackbar open={savingOpen} autoHideDuration={6000} onClose={handleSavingClose}>
        <Alert onClose={handleSavingClose} severity="success">
          Saving
        </Alert>
      </Snackbar>
      <Snackbar open={savedOpen} autoHideDuration={6000} onClose={handleSavedClose}>
        <Alert onClose={handleSavedClose} severity="success">
          Saved
        </Alert>
      </Snackbar>


      <Typography variant="h2" color="secondary">
        Project Analysis
      </Typography>
      <br/>
      {projectSummary.orderstatus_name === 'In Progress' && (
              <Typography variant="h3" color="secondary">
              Create Project for
            </Typography>
      )}

      {projectSummary.orderstatus_name !== 'In Progress' && (
              <Typography variant="h3" color="secondary">
              Create Quote for
            </Typography>
      )}

      <Typography variant="h4" color="secondary">
        {projectSummary.order_name}
      </Typography>

      <QuoteSection title="Get Quote Request Files">
        {projectSummary.user_id && ( //hack to get around prop not updating
          <FileList
            projectOwner={projectSummary.user_id}
            orderId={id}
            type="source"
            className={classes.fileList}
          />
        )}
        {/* <Button
          variant="contained"
          color="primary"
          className={classes.downloadZIPButton}
          startIcon={<CloudDownload />}
        >
          Download ZIP
        </Button> */}
      </QuoteSection>
      <QuoteSection title="Enter Quote Details" className={classes.fixWidth}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Source Language</TableCell>
                <TableCell>Target Language</TableCell>
                <TableCell>Word Count</TableCell>
                <TableCell>Rate</TableCell>
                <TableCell>Repetition Count</TableCell>
                <TableCell>Rep. Rate (%)</TableCell>
                <TableCell>Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lineItems.map((lineItem, index) => (
                <TableRow key={index}>
                  <TableCell scope="row">
                    {lineItem.src_language_name}
                  </TableCell>
                  <TableCell>{lineItem.target_language_name}</TableCell>
                  <TableCell>{lineItem.word_count}</TableCell>
                  <TableCell>{lineItem.rate}</TableCell>
                  <TableCell>{lineItem.rep_count}</TableCell>
                  <TableCell>{lineItem.rep_rate}</TableCell>
                  {lineItem.price >= 0 && (
                    <TableCell align="right">{'€' + lineItem.price}</TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          display="flex"
          flexDirection="row"
          marginTop="2vmin"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <TextField
            variant="outlined"
            color="primary"
            label="Word Count"
            onChange={event => {
              setQuoteField('word_count')(event.target.value);
            }}
          />
          <TextField
            variant="outlined"
            color="primary"
            label="Repetition Word Count"
            onChange={event => {
              setQuoteField('repitition_count')(event.target.value);
            }}
          />
          <TextField
            variant="outlined"
            color="primary"
            label="QuoteNo"
            onChange={event => {
              setQuoteField('quoteno')(event.target.value);
            }}
          />
          {projectSummary.orderstatus_name === 'In Progress' && (
            <TextField
              variant="outlined"
              color="primary"
              label="ProjectNo"
              onChange={event => {
                setQuoteField('projectno')(event.target.value);
              }}
            />
          )}

          {projectSummary.orderstatus_name === 'In Progress' && (
            <TextField
              variant="outlined"
              color="primary"
              label="Unique Project Code"
              onChange={event => {
                setQuoteField('project_unique_code')(event.target.value);
              }}
            />
          )}

      </Box>

      <Typography variant="h5" color="primary">
      <br/>  Deadline Date<br/>
      </Typography>

      <DateTimeSelect
          initalDateTime={projectSummary.deadline_date}
          initalTimezone={projectSummary.timezone}
          onDateTimeChange={setQuoteField('deadline_date')}
          onTimezoneChange={setQuoteField('timezone')}
        />

          <Button
            className={classes.saveButton}
            variant="contained"
            color="primary"
            size="small"
            startIcon={<Save />}
            onClick={updateQuote}          >
            Save
          </Button>
        
      </QuoteSection>

      <QuoteSection title="Upload Quote" className={classes.fileUpload}>
        {projectSummary.user_id && ( //hack to get around prop not updating
          <FileUpload
            onChange={() => {}}
            uploadTo="quote"
            projectOwner={projectSummary.user_id}
            quoteID={id}
            maxFiles={1}
          />
        )}
      </QuoteSection>
      <QuoteSection title="Upload Trados Files" className={classes.fileUpload}>
        {projectSummary.user_id && ( //hack to get round prop not updating
          <FileUpload
            onChange={() => {}}
            uploadTo="process"
            projectOwner={projectSummary.user_id}
            quoteID={id}
            maxFiles={1}
          />
        )}
      </QuoteSection>
      <Button
        variant="contained"
        color="secondary"
        size="large"
        startIcon={<Save />}
        onClick={updateStatus}
      >
        SUBMIT
      </Button>

      <Dialog
        open={open}
        onClose={handleCloseNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure you want to submit?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Have you entered and SAVED the required details and uploaded required files?<br/><br/>
           Submitting will progress this item to the next stage and will notify the client of progress. Are you sure you want to submit?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNo} className={classes.saveButton}
            variant="contained"
            color="primary">
            NO
          </Button>
          <Button onClick={handleCloseYes} className={classes.saveButton}
            variant="contained"
            color="primary" autoFocus>
            YES
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
  );
}
