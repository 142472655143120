import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import {
  Typography,
  Button,
  InputAdornment,
  LinearProgress
} from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import MuiPhoneNumber from 'material-ui-phone-number';
import { Lock, MailOutline, AccountCircle, Business } from '@material-ui/icons';
import Authentication from 'views/generic/authentication';
import AuthenticationForm from 'components/authenticationForm';
import AuthenticationCompleted from 'components/authenticationCompleted';

export default function Login() {
  const { t } = useTranslation();
  let history = useHistory();
  return (
    <Authentication>
      <Formik
        initialValues={{
          email: '',
          password: '',
          passwordConfirmation: '',
          name: '',
          company: '',
          phoneNumber: ''
        }}
        initialStatus={{ completed: false }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(t('validation:enterValidEmail'))
            .required(t('validation:emailRequired')),
          password: Yup.string()
            .min(8, t('validation:minPasswordLengthNotMet'))
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
              t('validation:passwordRequirementsNotMet')
            )
            .required(t('validation:enterNewPassword')),
          passwordConfirmation: Yup.string()
            .oneOf([Yup.ref('password')], t('validation:passwordsDontMatch'))
            .required(t('valdiation:enterConfirmPassword')),
          name: Yup.string().required(t('validation:enterName')),
          company: Yup.string(),
          phoneNumber: Yup.string()
        })}
        onSubmit={async (values, { setSubmitting, setErrors, setStatus }) => {
          let lcemail = values.email.toLowerCase();
          try {
            await Auth.signUp({
              username: lcemail,
              password: values.password,
              attributes: {
                name: values.name,
                email: lcemail,
                'custom:company': values.company,
                phone_number: values.phoneNumber
              }
            });
            setStatus({ completed: true });
          } catch (err) {
            switch (err.code) {
              case 'UsernameExistsException':
                setErrors({ email: t('validation:emailInUse') });
                break;
              case 'InvalidPasswordException':
                setErrors({
                  password: t('validation:passwordNotMetFull')
                });
                break;
              case 'InvalidParameterException':
                switch (err.message) {
                  case 'Invalid phone number format.':
                    console.log(err);
                    setErrors({
                      phoneNumber: t('validation:phoneNumberInvalid')
                    });
                    break;
                  default:
                    console.log(err);
                }
                break;
              default:
                console.log(err);
            }
          }
          setSubmitting(false);
        }}
      >
        {({ status, isSubmitting }) => (
          <AuthenticationForm
            completed={status.completed}
            completedView={
              <AuthenticationCompleted
                title={t('authentication:checkEmail')}
                icon={<MailOutline fontSize="large" />}
                message={t('authentication:checkEmailSignUp')}
                buttonText={t('authentication:login')}
                redirectTo="/login"
              />
            }
          >
            <Typography color="primary" variant="h2">
              <Trans i18nKey="authentication:signUpHeading" />
            </Typography>
            <TextField
              name="email"
              id="outlined-textarea"
              label={t('authentication:email') + ' *'}
              type="email"
              color="primary"
              margin="normal"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutline />
                  </InputAdornment>
                )
              }}
            />
            <TextField
              name="password"
              id="outlined-textarea"
              label={t('authentication:password') + ' *'}
              type="password"
              color="primary"
              margin="normal"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                )
              }}
            />
            <TextField
              name="passwordConfirmation"
              id="outlined-textarea"
              label={t('authentication:passwordConfirmation') + ' *'}
              type="password"
              color="primary"
              margin="normal"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                )
              }}
            />
            <hr />
            <TextField
              name="name"
              id="outlined-textarea"
              label={t('authentication:name') + ' *'}
              color="primary"
              margin="normal"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                )
              }}
            />
            <TextField
              name="company"
              id="outlined-textarea"
              label={t('authentication:company')}
              color="primary"
              margin="normal"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Business />
                  </InputAdornment>
                )
              }}
            />
            <Field name="phoneNumber">
              {({ form: { setFieldValue }, meta }) => (
                <MuiPhoneNumber
                  label={t('authentication:phone')}
                  id="outlined-textarea"
                  variant="outlined"
                  margin="normal"
                  color="primary"
                  error={meta.error}
                  helperText={meta.error}
                  defaultCountry={'gb'}
                  inputClass={TextField}
                  onChange={e => {
                    setFieldValue('phoneNumber', e.replace(/ /g, ''));
                  }}
                />
              )}
            </Field>
            {isSubmitting && <LinearProgress />}
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              type="submit"
            >
              <Trans i18nKey="authentication:register" />
            </Button>
            <Button color="secondary" onClick={() => history.push('/login')}>
              <Trans i18nKey="authentication:login" />
            </Button>
          </AuthenticationForm>
        )}
      </Formik>
    </Authentication>
  );
}
