import React from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography, Icon} from '@material-ui/core';
import helpImage from '../../media/img/helpPhoto.jpg';
import '../../fonts/Pe-icon-7-stroke.css';
const useStyles = makeStyles(theme => ({
  image: {
    width: '10vmin',
  },
  imageContainer: {
    borderRadius: '50%',
    width: '6vmin',
    height: '6vmin',
    overflow: 'hidden',
  },
  contactInfoContainer: {
    '& > :nth-child(odd)': {
      marginRight: '0.5vmin',
    },
    '& > :nth-child(even)': {
      marginRight: '1vmin',
    },
  },
}));

export default function LeanHelpMessage({className, header, message}) {
  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      width="100%"
      className={className}>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={helpImage} />
      </div>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        marginLeft="1vmin">
        <Typography variant="h5" color="primary">
          {header}
        </Typography>
        <Typography variant="body1">{message}</Typography>
        <Box
          display="flex"
          flexDirection="row"
          className={classes.contactInfoContainer}>
          <Icon className="pe-7s-phone" />
          <Typography variant="body1">
            <a hreF='tel:+44 (0) 845 130 1170'>
                +44 (0) 845 130 1170
            </a>
          </Typography>
          <Icon className="pe-7s-mail" />
          <Typography varaint="body1">
            <a href='mailto:info@globalvoices.co.uk}'>
              info@globalvoices.co.uk
            </a>
          </Typography>   
        </Box>
      </Box>
    </Box>
  );
}
