import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from 'GVComponents/Button.js';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import { Box, DialogContent } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import GridOnIcon from '@material-ui/icons/GridOn';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import RedPage from '../media/img/file1.png'
import RedFold from '../media/img/file2.png'
import BluePage from '../media/img/file3.png'
import BlueFold from '../media/img/file4.png'
import PurplePage from '../media/img/file5.png'
import PurpleFold from '../media/img/file6.png'
import File7 from '../media/img/file7.png'
import File8 from '../media/img/file8.png'
import File9 from '../media/img/file9.png'
import File10 from '../media/img/file10.png'

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

export default function SupportedFilesDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Supported Documents
      
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} xs={12}>
          <Grid item style={{display: 'flex'}} xs={6} justify='center'>
            <div style={{position:'relative',justifyContent: 'center', bottom: '1vh', left:'1.5vh', width:'13vh'}}>
            
              <p style={{position: 'absolute', fontWeight: 'bold', top: '5vh',fontSize: '3vh', left: '0.4vh', color: '#fff'}}>.docs</p>
                <img src={BluePage} style={{width:'10vh'}}/>                
                <img src={BlueFold} style={{position: 'absolute', left: '7vh', width:'3vh', color: '#fff'}}/>
                <p style={{margin: 0,fontWeight: 'bold',paddingLeft:'1.5vh'}}>Word File</p>
            </div>
          </Grid>
                <Grid item style={{display: 'flex'}} xs={6} justify='center'>
                <div style={{position:'relative',justifyContent: 'center', bottom: '1vh', left:'1.5vh', width:'13vh'}}>
                
                <p style={{position: 'absolute', fontWeight: 'bold', top: '5vh',fontSize: '3vh', left: '1.8vh', color: '#fff'}}>.odt</p>
                    <img src={PurplePage} style={{width:'10vh'}}/>                 
                    <img src={PurpleFold} style={{position: 'absolute', left: '7vh', width:'3vh', color: '#fff'}}/>
                    <p style={{margin: 0,fontWeight: 'bold'}}>OpenDocument</p>
                </div>
                </Grid>
                <Grid item style={{display: 'flex'}} xs={6} justify='center'>
                <div style={{position:'relative',justifyContent: 'center', bottom: '1vh', left:'1.5vh', width:'13vh'}}>
                
                <p style={{position: 'absolute', fontWeight: 'bold', top: '5vh',fontSize: '3vh', left: '1.8vh', color: '#fff'}}>.pdf</p>
                    <img src={RedPage} style={{width:'10vh'}}/>                 
                    <img src={RedFold} style={{position: 'absolute', left: '7vh', width:'3vh', color: '#fff'}}/>
                    <p style={{margin: 0,fontWeight: 'bold',paddingLeft:'1.5vh'}}>PDF File</p>
                </div>
                </Grid>

                <Grid item style={{display: 'flex'}} xs={6} justify='center'>
                <div style={{position:'relative',justifyContent: 'center', bottom: '1vh', left:'1.5vh', width:'13vh'}}>
                 
                    <p style={{position: 'absolute', fontWeight: 'bold', top: '5vh',fontSize: '3vh', left: '1.8vh', color: '#fff'}}>.xls</p>
                    <img src={File7} style={{width:'10vh'}}/>                 
                    <img src={File8} style={{position: 'absolute', left: '7vh', width:'3vh', color: '#fff'}}/>
                    <p style={{margin: 0,fontWeight: 'bold',paddingLeft:'1.5vh'}}>Excel File</p>
                </div>
              </Grid>

                <Grid item style={{display: 'flex'}} xs={6} justify='center'>
                <div style={{position:'relative',justifyContent: 'center', bottom: '1vh', left:'1.5vh', width:'13vh'}}>
                  
                  <p style={{position: 'absolute', fontWeight: 'bold', top: '5vh',fontSize: '3vh', left: '1.8vh', color: '#fff'}}>.txt</p>
                    <img src={File9} style={{width:'10vh'}}/>                 
                    <img src={File10} style={{position: 'absolute', left: '7vh', width:'3vh', color: '#fff'}}/>
                    <p style={{margin: 0,fontWeight: 'bold',paddingLeft:'1.5vh'}}>Text File</p>
                </div>
              </Grid>
            
          <Grid item style={{display: 'flex'}} xs={6} justify='center'>
            <Button
                onClick={() => handleListItemClick()} 
                variant="contained"
                color="secondary"
                style={{position:'absolute',height: '6vh', bottom: '3vh'}}
              >
                <Typography>Close</Typography>
            </Button>
          </Grid>
          </Grid>
        </DialogContent>

    </Dialog>
  );
}

SupportedFilesDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};