import React from 'react';
import { Trans } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Typography, Box } from '@material-ui/core';
import logo from 'media/img/logo_global.svg';

const useStyles = makeStyles(theme => ({
  notFoundContainer: {
    height: '100vh'
  },
  logo: {
    height: '30vh'
  },
  notFoundHeader: {
    marginTop: '5vh'
  },
  notFoundDesc: {
    marginTop: '10vh'
  },
  homeButton: {
    marginTop: '10vh'
  }
}));

export default function NotFound() {
  const classes = useStyles();
  return (
    <Box
      className={classes.notFoundContainer}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Link to="/">
        <img className={classes.logo} src={logo} />
      </Link>
      <Typography className={classes.notFoundHeader} variant="h1">
        401
      </Typography>
      <Typography variant="h1" className={classes.notFoundDesc}>
        <Trans i18nKey="common:notAuthorizedHeader" />
      </Typography>
    </Box>
  );
}
