import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Amplify, { API } from 'aws-amplify';
import moment from 'moment';
import { Box, Typography, TextField , Snackbar } from '@material-ui/core';
import {
  AccessTime,
  CalendarToday,
  Work,
  LocalCafe,
  SentimentVerySatisfied,
  Business
} from '@material-ui/icons';
import NotFound from 'views/generic/notfound';
import MuiAlert from '@material-ui/lab/Alert';
import DropDownSection from 'components/dropDownSection';
import LanguageLineItems from 'components/languageLineItems';
import FileList from 'components/fileList.js';
import FileUpload from 'components/fileUpload.js';
import Button from 'GVComponents/Button.js';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const useStyles = makeStyles(theme => ({
  projectContainer: {
    margin: '2vmin 10vmin'
  },
  mainSplit: {
    display: 'grid',
    gridTemplateColumns: '50% 50%'
  },
  infoSection: {
    display: 'flex'
  }
}));

export default function Linguist() {
  const classes = useStyles();
  let { id } = useParams();
  const [projectSummary, setProjectSummary] = useState({});

  const getProjectSummary = async () => {
    const apiName = 'TransportAPI';
    const path = '/Test/order-services/salesorder/summary/projectno';
    const session = await Amplify.Auth.currentSession();
    const token = session.idToken.jwtToken;
    API.get(apiName, path, {
      headers: { Authorization: token },
      queryStringParameters: { projectno: id }
    })
      .then(response => {
        console.log(response);
        setProjectSummary(response[0]);
      })
      .catch(error => {
        console.log(error.response);
      });
  };


  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseNo = () => {
    setOpen(false);

  };

  const handleCloseYes = () => {

    executeSaveSatus();

  };
  
  const [savingOpen, setSavingOpen] = React.useState(false);

const handleSaving = () => {
  setSavingOpen(true);
};

const handleSavingClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }

  setSavingOpen(false);
  setOpen(false);
};

const [savedOpen, setSavedOpen] = React.useState(false);

const handleSaved = () => {
  setSavedOpen(true);
};

const handleSavedClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }

  setSavedOpen(false);
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

  const [quoteUpdate, setUpdatefield] = useState({
    salesorder_id: projectSummary.id,
    status_id: null,
  });

  const setUpdate = (fieldID, value) => {
    quoteUpdate[fieldID] = value;
    setUpdatefield(quoteUpdate);
  };

const executeSaveSatus = async () => {
  const apiName = 'TransportAPI';
  const path = '/Test/order-services/salesorder/status';
  let session = await Amplify.Auth.currentSession();
  let token = session.idToken.jwtToken;
  //  console.log(quoteUpdate);
  handleSaving();

  if (projectSummary.orderstatus_name === 'In Progress') 
  {  
    setUpdate('id', projectSummary.id);
    setUpdate('salesorder_id', projectSummary.id);
  setUpdate('status_id', 10);
  }

  API.put(apiName, path, {
    body: quoteUpdate,
    headers: { Authorization: token }
  })
    .then(response => {
      getProjectSummary();
      handleSaved();
    })
    .catch(error => {
      console.log(error.response);
    });
};

  const updateStatus = async () => {

    handleClickOpen();

  };

  useEffect(() => {
    if (Object.entries(projectSummary).length !== 0) {
      return;
    }

    getProjectSummary();
  });

  return (
    <Box
      className={classes.projectContainer}
      display="flex"
      flexDirection="column"
    >

<Snackbar open={savingOpen} autoHideDuration={6000} onClose={handleSavingClose}>
        <Alert onClose={handleSavingClose} severity="success">
          Submitting ...
        </Alert>
      </Snackbar>
      <Snackbar open={savedOpen} autoHideDuration={6000} onClose={handleSavedClose}>
        <Alert onClose={handleSavedClose} severity="success">
          Submitted
        </Alert>
      </Snackbar>

      <Typography variant="h2" color="secondary" gutterBottom>
        Linguist Files - {projectSummary.ordertype_name} Project{' '}
        {projectSummary.order_name}
      </Typography>

      <DropDownSection title="Source Files" open>
        <FileList orderId={projectSummary.id} projectOwner={projectSummary.user_id} type="process" />
      </DropDownSection>

      <DropDownSection title="Translated Files" open>
        <br />

        <Typography>Upload Translated Files</Typography>
        {projectSummary.id && ( //hack to get around prop not updating
          <FileUpload
            onChange={() => {}}
            uploadTo="translated"
            projectOwner={projectSummary.user_id}
            quoteID={projectSummary.id}
          />
        )}
      
        
        <FileList orderId={projectSummary.id} projectOwner={projectSummary.user_id} type="translated" />
        <br />
        {projectSummary.orderstatus_name === 'In Progress' && (
        <Button variant="contained" color="secondary" onClick={updateStatus}>
          <Typography>Submit</Typography>
        </Button>
        )}
        <br /> <br />
      </DropDownSection>

      <Dialog
        open={open}
        onClose={handleCloseNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure you want to submit?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Are you sure you have uploaded all of the translated documents?<br/><br/>
           Submitting will progress thiis project to REVIEW. Are you sure you want to submit?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNo} className={classes.saveButton}
            variant="contained"
            color="primary">
            NO
          </Button>
          <Button onClick={handleCloseYes} className={classes.saveButton}
            variant="contained"
            color="primary" autoFocus>
            YES
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
  );
}
