import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Amplify, { API, Auth } from 'aws-amplify';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Typography,
  Button,
  InputAdornment,
  LinearProgress
} from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Lock, MailOutline } from '@material-ui/icons';
import Authentication from 'views/generic/authentication';
import AuthenticationForm from 'components/authenticationForm';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Login() {
  const { t, i18n } = useTranslation();
  const [validationSchema, setValidationSchema] = useState(Yup.object({}));
  let history = useHistory();
  let query = useQuery();

  const portalUserMigration = async (email, password) => {
    const apiName = 'TransportAPI';
    const path = '/Test/portalusermigration';
    await API.put(apiName, path, {
      body: { email: email.toLowerCase(), password: password }
    }).catch(e => console.log(e));
  };

  useEffect(() => {
    //need to find out how to force formik to refresh
  }, [i18n.language]);

  return (
    <Authentication>
      <Formik
        initialValues={{
          email: query.get('email') ? query.get('email') : '',
          password: ''
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(t('validation:enterValidEmail'))
            .required(t('validation:emailRequired')),
          password: Yup.string().required(t('validation:enterPassword'))
        })}
        onSubmit={async ({ email, password }, { setSubmitting, setErrors }) => {
          try {
            //remove the current authenticated user if already logged in
            //this is an attempt to fix an ie 11 bug where login cookies stack causing a request header overflow
            try {
              let cognitoUser = await Auth.currentAuthenticatedUser();
              if (cognitoUser != null) {
                await Auth.signOut();
              }
            } catch (e) {}
            await portalUserMigration(email, password);
            let lcemail = email.toLowerCase();
            let user = await Auth.signIn(lcemail, password);
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
              history.push('/passwordreset', { user: user });
              return;
            }
            if (query.has('redirect')) {
              history.push(query.get('redirect'));
              return;
            }
            history.push('/');
          } catch (err) {
            switch (err.code) {
              case 'NotAuthorizedException':
              case 'UserNotFoundException':
                setErrors({
                  email: '<200e>',
                  password: t('validation:emailPasswordIncorrect')
                });
                break;
              default:
                setErrors({
                  email: '<200e>',
                  password: t('validation:genericValidationError')
                });
                break;
            }
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <AuthenticationForm>
            <Typography color="primary" variant="h2">
              <Trans i18nKey="authentication:welcome" />
            </Typography>
            <TextField
              name="email"
              id="outlined-textarea"
              label={t('authentication:email')}
              type="email"
              color="primary"
              margin="normal"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutline />
                  </InputAdornment>
                )
              }}
            />
            <TextField
              name="password"
              id="outlined-textarea"
              label={t('authentication:password')}
              type="password"
              color="primary"
              margin="normal"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                )
              }}
            />
            {isSubmitting && <LinearProgress />}
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              type="submit"
            >
              <Trans i18nKey="authentication:login" />
            </Button>
            <Button
              color="secondary"
              onClick={() => history.push('/forgotpassword')}
            >
              <Trans i18nKey="authentication:forgotPassword" />
            </Button>
            <Button color="secondary" onClick={() => history.push('/signup')}>
              <Trans i18nKey="authentication:signUp" />
            </Button>
          </AuthenticationForm>
        )}
      </Formik>
    </Authentication>
  );
}
