import React, { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Typography, Box, Fab } from '@material-ui/core';
import { UserContext } from 'components/UserContext';
import ProjectCard from 'components/projectCard';
import HomeIcon from '@material-ui/icons/Home';
import logo from 'media/img/logo_global.svg';
import Amplify, { API } from 'aws-amplify';

const useStyles = makeStyles(theme => ({
  projectsStatus: {
    margin: '1vmin',
    '& > *:not(:last-child)': {
      marginRight: '1vmin'
    }
  },
  dashboardContainer: {
    height: '100vh',
    backgroundColor: '#f1f3f4'
  },
  logo: {
    height: '20vh'
  },
  dashboardHeader: {
    margin: '1vmin'
  },
  dashboardSubHeading: {
    margin: '1vmin'
  },
  homeButton: {
    marginTop: '10vh'
  },
  quoteButton: {
    marginTop: '2vh'
  },
  projectCard: {
    flex: '1 1 0px',
    maxWidth: '50vh',
    color: 'white'
  },
  quotesRequestedCard: {
    backgroundColor: '#5ea5d7'
  },
  pendingQuotesCard: {
    backgroundColor: '#ff6961'
  },
  inProgressCard: {
    backgroundColor: '#f3bd2b'
  },
  completedProjects: {
    backgroundColor: '#5eb37e'
  }
}));

/*
      <Link to="/">
        <img className={classes.logo} src={logo} />
      </Link>
 
      <Link to="/projects">
        <Fab className={classes.homeButton} color="primary" variant="extended">
          <HomeIcon />
          View Quotes & Projects
        </Fab>
      </Link>
      <Link to="/project/new">
        <Fab className={classes.quoteButton} color="primary" variant="extended">
          <HomeIcon />
          Request A Quote
        </Fab>
      </Link>
      */

export default function Dashboard() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const userInfo = useContext(UserContext);

  const [projectSummary, setProjectSummary] = useState([]);

  const getProjectCount = async () => {
    const apiName = 'TransportAPI';
    const path = '/Test/order-services/salesorder/summary/count';
    const session = await Amplify.Auth.currentSession();
    const token = session.idToken.jwtToken;
    API.get(apiName, path, {
      headers: { Authorization: token }
    })
      .then(response => {
        console.log(response);
        setProjectSummary(response);
      })
      .catch(error => {
        console.log(error.response);
      });
  };

  useEffect(() => {
    if (Object.entries(projectSummary).length !== 0) {
      return;
    }

    getProjectCount();
  }, []);

  return (
    <Box
      className={classes.dashboardContainer}
      display="flex"
      flexDirection="column"
    >
      <Typography
        className={classes.dashboardHeader}
        color="Primary"
        variant="h2"
      >
        {t('dashboard:greeting', { name: userInfo.user.attributes.name })}
      </Typography>
      <Typography
        className={classes.dashboardSubHeading}
        color="primary"
        variant="h3"
      >
        <Trans i18nKey="dashboard:greetingSubHeading" />
      </Typography>
      <Box
        display="flex"
        justifyContent="space-evenly"
        className={classes.projectsStatus}
      >
        <ProjectCard
          title={t('dashboard:quotesRequestedTitle')}
          subtitle={t('dashboard:quotesRequestedSubTitle')}
          count={projectSummary.length > 0 && projectSummary[2].cnt}
          linkTo="/projects"
          className={clsx(classes.quotesRequestedCard, classes.projectCard)}
        />
        <ProjectCard
          title={t('dashboard:pendingQuotesTitle')}
          subtitle={t('dashboard:pendingQuotesSubTitle')}
          count={projectSummary.length > 0 && projectSummary[1].cnt}
          linkTo="/projects"
          className={clsx(classes.pendingQuotesCard, classes.projectCard)}
        />
        <ProjectCard
          title={t('dashboard:projectsInProgressTitle')}
          subtitle={t('dashboard:projectsInProgressSubTitle')}
          count={projectSummary.length > 0 && projectSummary[3].cnt}
          linkTo="/projects"
          className={clsx(classes.inProgressCard, classes.projectCard)}
        />
        <ProjectCard
          title={t('dashboard:projectsCompletedTitle')}
          subtitle={t('dashboard:projectsCompletedSubTitle')}
          count={projectSummary.length > 0 && projectSummary[0].cnt}
          linkTo="/projects"
          className={clsx(classes.completedProjects, classes.projectCard)}
        />
      </Box>
    </Box>
  );
}
