import React, { useState, useEffect } from "react";
import Amplify, { API } from 'aws-amplify';
import { Box, Chip } from '@material-ui/core';
import { Done, Add } from "@material-ui/icons";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

export default function LanguageDropDown(props){
    const [contentTypes, setContentTypes] = useState([]);

    const getContentTypes = async() =>{
        const apiName = 'TransportAPI';
        const path = '/Test/language-services/language';
        const session = await Amplify.Auth.currentSession();
        const token = session.idToken.jwtToken
        API.get(apiName, path,{ headers: {Authorization: token} }).then(response => {
            console.log(response)
            setContentTypes(response);
        }).catch(error => {
            console.log(error.response)
        });
    }

    const getContentTypeIds = (contentTypes)=>{
        return contentTypes.filter(contentType =>{
            return contentType.selected != false && contentType.selected != undefined;
        }).map(contentType => {
            return contentType.id;
        })
    }

    const toggleSelectContentType = (index) => {
        
        console.log ("IIINNNNNDDDEEXXX");
        console.log (index);
        //getting a slice of whole array makes a copy of array
        //without this the component wont rerender
        let updatedContentTypes = contentTypes.slice();

            if(updatedContentTypes[index].selected) {
                updatedContentTypes[index].selected = !updatedContentTypes[index].selected;
            } else {
                updatedContentTypes[index].selected = true;
            }
    
        setContentTypes(updatedContentTypes);
        props.onChange(getContentTypeIds(updatedContentTypes));
    }

    useEffect(() => {
        if(contentTypes.length === 0){
            getContentTypes();
        }
    },[]);

    // Select for content type
    const useStyles = makeStyles(theme => ({
        formControl: {
          margin: theme.spacing(1),
          minWidth: 120,
        },
        selectEmpty: {
          marginTop: theme.spacing(2),
        },
      }));

      const classes = useStyles();
      const [content, setContent] = React.useState('');
      
      const handleChange = event => {
          toggleSelectContentType(event.target.value);
          setContent(event.target.value);
      };

    return (
        <div>
        <FormControl className={classes.formControl}>

        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={content}
          onChange={handleChange}
        >
          {contentTypes.slice(0,20).map((contentType, index) => (
            <MenuItem
                color="primary"
                style={{ margin: "2px" }}
                value={index}
                key={contentType.id}
            >
                {contentType.language_name}
            </MenuItem>
            ))
            }
        </Select>

      </FormControl>

        </div>

        );

    }

