import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import Theme from 'Theme';
import './index.css';
import Amplify from 'aws-amplify';
import 'I18n';
import jquery from "jquery";
import "jquery";
import "popper.js";
import "bootstrap/dist/css/bootstrap.min.css";
window.jQuery = jquery;
window.$ = window.jQuery;
const $ = jquery;
require("bootstrap");

// initializing popover of bootstrap for use
$(document).ready(function() {
  $('[data-toggle="popover"]').popover();
});

$(function() {
  $('[data-toggle="tooltip"]').tooltip();
});

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'eu-west-1',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'eu-west-1_WUknpwkqV',
    userPoolWebClientId: '76vqq3418anloqdcurql5o91ol',
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,
    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: process.env.REACT_APP_AWS_DOMAIN,
      // OPTIONAL - Cookie path
      path: '/',
      // OPTIONAL - Cookie expiration in days
      expires: 365,
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      // this will be true in prod
      secure: false
    }
  },
  API: {
    endpoints: [
      {
        name: 'TransportAPI',
        endpoint: 'https://f13mm8g51m.execute-api.eu-west-2.amazonaws.com'
      },
      {
        name: 'GlobalIrisAPI',
        endpoint: 'https://pay.sandbox.realexpayments.com'
      }
    ]
  }
});

ReactDOM.render(<Theme />, document.getElementById('root'));
