import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from 'Router/privateRoute.js';
import Nav from 'components/nav';
import Login from 'views/generic/login';
import SignUp from 'views/generic/signup';
import ForgotPassword from 'views/generic/forgotPassword';
import PasswordReset from 'views/generic/passwordReset';
import NotFound from 'views/generic/notfound';
import Quote from 'views/generic/quote';
import Projects from 'views/generic/projects';
import Project from 'views/generic/project';
import ClientDashboard from 'views/client/dashboard';
import ProjectAnalysis from 'views/admin/projectAnalysis';
import ClientConfiguration from 'views/admin/clientConfiguration';
import Linguist from 'views/linguist/linguist';
import NotAuthorized from 'views/generic/notAuthorized';
import SignUpConfrimed from 'views/generic/signupConfirmed';
import ProjectManagement from 'views/admin/projectManagement';
import NewOCIUser from 'views/generic/newOCIUser';
import UpdateAttributes from 'views/generic/updateAttributes';
import InvalidProactisRedirect from 'views/generic/invalidProactisRedirect';

export default function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route exact path="/signup">
          <SignUp />
        </Route>
        <Route exact path="/forgotpassword">
          <ForgotPassword />
        </Route>
        <Route exact path="/signupconfirmed">
          <SignUpConfrimed />
        </Route>
        <Route exact path="/newOCIUser">
          <NewOCIUser />
        </Route>
        <Route exact path="/updateattributes">
          <UpdateAttributes />
        </Route>
        <Route path="/passwordreset">
          <PasswordReset />
        </Route>
        <Route path="/notauthorized">
          <NotAuthorized />
        </Route>
        <Route path="/invalidproactisredirect">
          <InvalidProactisRedirect />
        </Route>
        <PrivateRoute exact path="/">
          <Redirect to="/dashboard" />
        </PrivateRoute>
        <PrivateRoute exact path="/projects">
          <Nav>
            <Projects />
          </Nav>
        </PrivateRoute>
        <PrivateRoute exact path="/project/new">
          <Nav>
            <Quote />
          </Nav>
        </PrivateRoute>
        <PrivateRoute path="/project/:id">
          <Nav>
            <Project />
          </Nav>
        </PrivateRoute>
        <PrivateRoute path="/dashboard">
          <Nav>
            <ClientDashboard />
          </Nav>
        </PrivateRoute>
        <PrivateRoute path="/projectanalysis/:type/:id" allowedGroups={["admin"]}>
          <Nav>
            <ProjectAnalysis />
          </Nav>
        </PrivateRoute>
        <PrivateRoute path="/admin/client/:id" allowedGroups={["admin"]}>
          <Nav>
            <ClientConfiguration />
          </Nav>
        </PrivateRoute>
        <PrivateRoute path="/linguist/:id" allowedGroups={["admin", "linguist"]}>
          <Nav>
            <Linguist />
          </Nav>
        </PrivateRoute>
        <PrivateRoute path="/projectmanagement/:id" allowedGroups={["admin"]}>
          <Nav>
            <ProjectManagement />
          </Nav>
        </PrivateRoute>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
