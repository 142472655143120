import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Form} from 'formik';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      width: '90%',
    },
    [theme.breakpoints.up('md')]: {
      width: '40vmin',
    },
    '& > *': {
      marginTop: '0.5vmin',
      marginBottom: '0.5vmin',
    },
  },
}));

export default function AuthenticationForm({
  children,
  completed,
  completedView,
  ...rest
}) {
  const classes = useStyles();
  if (completed) {
    return completedView;
  }
  return (
    <Form className={classes.form} {...rest}>
      {children}
    </Form>
  );
}
