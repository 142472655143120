import React from 'react';
import { useTranslation } from 'react-i18next';
import Authentication from 'views/generic/authentication';
import AuthenticationCompleted from 'components/authenticationCompleted';
import { MailOutline } from '@material-ui/icons';

export default function Login() {
  const { t } = useTranslation();
  return (
    <Authentication>
      <AuthenticationCompleted
        title={t('authentication:signupCompleteHeader')}
        icon={<MailOutline fontSize="large" />}
        message={t('authentication:signupCompleteMessage')}
        buttonText={t('authentication:login')}
        redirectTo="/login"
      />
    </Authentication>
  );
}
