import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Box, Typography, Fade, Icon } from '@material-ui/core';
import moment from 'moment';
import 'moment-timezone';
import { UserContext } from 'components/UserContext';

const useStyles = makeStyles(theme => ({
  verticalHr: {
    width: 1,
    borderLeft: '2px solid white'
  },
  hide: {
    visibility: 'hidden'
  },
  whiteText: {
    color: 'white',
    transition: 'color 0.2s ease-in-out'
  }
}));

export default function QuoteBreakdown({ className, step, showStep, quote }) {

  const userInfo = useContext(UserContext);

  const { t } = useTranslation();
  const classes = useStyles();
  let showBreakdown = true;
  if (step < showStep) {
    showBreakdown = false;
  }

  return (
    <Fade in={showBreakdown}>
      <div className={className}>
        <Typography variant="h3" color="secondary" gutterBottom>
          {t('quote:yourOrder')}
        </Typography>
        <Box display="flex" flexDirection="row">
          <div className={classes.verticalHr} />
          <Box display="flex" flexDirection="column" marginLeft="1vmin">
            <Typography variant="h5" color="secondary">
              {t('quote:translationProject')}
            </Typography>
            <Box display="flex" flexDirection="column" marginLeft="2vmin">
              <Typography className={classes.whiteText} variant="body1">
                {quote.file_list.length > 0 &&
                  quote.file_list.length + ' Items'}

                {quote.has_unselectables == true &&
                  ' - Unselectable Areas Detected'}
              </Typography>
            </Box>
            <Typography
              variant="h5"
              className={clsx({ [classes.whiteText]: step < 2 })}
              color="secondary"
            >
              {t('quote:languages')}
            </Typography>
            <Box display="flex" flexDirection="column" marginLeft="2vmin">
              <Typography className={classes.whiteText} variant="h6">
                {quote.source_language.length > 0 && t('quote:from') + ':'}
              </Typography>
              <Box display="flex" flexDirection="column" marginLeft="2vmin">
                {quote.source_language.length > 0 && (
                  <Typography className={classes.whiteText} variant="body1">
                    {quote.source_language[0].language_name}
                  </Typography>
                )}
              </Box>
              <Typography className={classes.whiteText} variant="h6">
                {quote.target_languages.length > 0 && t('quote:to') + ':'}
              </Typography>
              <Box display="flex" flexDirection="column" marginLeft="2vmin">
                {quote.target_languages.length > 0 &&
                  quote.target_languages.map(language => (
                    <Typography
                      className={classes.whiteText}
                      variant="body1"
                      key={language.language_name}
                    >
                      {language.language_name}
                    </Typography>
                  ))}
              </Box>
            </Box>
            <Typography
              variant="h5"
              className={clsx({ [classes.whiteText]: step < 3 })}
              color="secondary"
            >
              {t('quote:contentType')}
            </Typography>
            <Box display="flex" flexDirection="column" marginLeft="2vmin">
              <Typography className={classes.whiteText} variant="body1">
                {quote.content_type && quote.content_type.content_type_name}
              </Typography>
            </Box>
            <Typography
              variant="h5"
              className={clsx({ [classes.whiteText]: step < 4 })}
              color="secondary"
            >
              {t('quote:deliveryDate')}
            </Typography>
            <Box display="flex" flexDirection="column" marginLeft="2vmin">
              <Typography variant="body1" className={classes.whiteText}>
                {quote.deadline_date &&
                  moment(quote.deadline_date).format('D MMMM YYYY [at] HH:mm')}
              </Typography>
            </Box>
            <Typography
              variant="h5"
              className={clsx({ [classes.whiteText]: step < 5 })}
              color="secondary"
            >
              {t('quote:serviceLevel')}
            </Typography>
            <Box display="flex" flexDirection="rows" marginLeft="2vmin">
              <Typography className={classes.whiteText} variant="body1">
                {quote.package && quote.package.label}
              </Typography>
             
            </Box>
            <Typography
              variant="h5"
              className={clsx({ [classes.whiteText]: step < 6 })}
              color="secondary"
            >
              {t('quote:tone')}
            </Typography>
            <Box display="flex" flexDirection="rows" marginLeft="2vmin">
              <Typography className={classes.whiteText} variant="body1">
                {quote.tone && quote.tone.label}
              </Typography>
            </Box>
            
            { (userInfo.groups.includes('general_public')  ? 
            <Typography
              variant="h5"
              className={clsx({ [classes.whiteText]: step < 7 })}
              color="secondary">
              {t('quote:Billing')}
            </Typography> : <br/>)}

            { (userInfo.groups.includes('general_public')  ? 
            <Box display="flex" flexDirection="rows" marginLeft="2vmin">
              <Typography className={classes.whiteText} variant="body1">
                {quote.Billing && quote.Billing.label}
              </Typography>
            </Box> : <br/>)}
          </Box>
        </Box>
      </div>
    </Fade>
  );
}
