import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Amplify, { API } from 'aws-amplify';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export default function ContentType(props) {
  const { t } = useTranslation();
  const [contentType, setContentType] = React.useState(null);
  const [contentTypes, setContentTypes] = useState([]);

  const getContentTypes = async () => {
    const apiName = 'TransportAPI';
    const path = '/Test/order-services/content';
    const session = await Amplify.Auth.currentSession();
    const token = session.idToken.jwtToken;
    API.get(apiName, path, { headers: { Authorization: token } })
      .then(response => {
        console.log(response);
        setContentTypes(response);
      })
      .catch(error => {
        console.log(error.response);
      });
  };

  useEffect(() => {
    if (contentTypes.length === 0) {
      getContentTypes();
    }
    console.log(props.value);
    setContentType(props.value);
  }, []);

  useEffect(() => {
    setContentType(props.value);
  }, [contentTypes]);

  const handleChange = event => {
    let updatedContentType = contentTypes.find(
      contentType => contentType.id === event.target.value
    );
    if (updatedContentType === null) {
      return;
    }
    props.onChange(updatedContentType);
    setContentType(updatedContentType);
  };

  return (
    <FormControl>
      <InputLabel id="document-type-label">
        {t('quote:documentType')}
      </InputLabel>
      <Select
        labelId="document-type-label"
        value={contentType !== null && contentType.id}
        onChange={handleChange}
      >
        {contentTypes.slice(0, 20).map(contentType => (
          <MenuItem
            color="primary"
            style={{ margin: '2px' }}
            value={contentType.id}
            key={contentType.id}
          >
            {contentType.content_type_name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
