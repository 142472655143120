import React from 'react';
import { Typography, Box } from '@material-ui/core';

export default function MultiLineTypography({
  children,
  containerClassName,
  ...rest
}) {
  if (children === undefined) {
    return null;
  }
  const lines = children.split('\n');
  return (
    <Box display="flex" flexDirection="column" className={containerClassName}>
      {lines.map(line => (
        <Typography key={line} {...rest}>
          {line}
        </Typography>
      ))}
    </Box>
  );
}
