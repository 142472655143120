import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from 'components/UserContext';
import { useTranslation, Trans } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Amplify, { API } from 'aws-amplify';
import {
  Table,
  TablePagination,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Typography,
  CircularProgress,
  Box
} from '@material-ui/core';
import Button from 'GVComponents/Button';
import Moment from 'react-moment';

const useStyles = makeStyles(theme => ({
  projectsListContainer: {
    borderRadius: 0
  }
}));

export default function ProjectList(props) {
  const { t } = useTranslation();
  let history = useHistory();
  const classes = useStyles();
  const [projectsSummary, setProjectsSummary] = useState([]);
  const [projectsSummaryLoading, setProjectsSummaryLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const userInfo = useContext(UserContext);

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getProjects = async () => {
    const apiName = 'TransportAPI';
    const path = '/Test/order-services/salesorder/summary/' + props.lookupType;
    const session = await Amplify.Auth.currentSession();
    const token = session.idToken.jwtToken;
    API.get(apiName, path, { headers: { Authorization: token } })
      .then(response => {
        setProjectsSummary(response);
        setProjectsSummaryLoading(false);
      })
      .catch(error => {
        console.log(error.response);
      });
  };
  const redirectToProject = projectID => {
    history.push('/project/' + projectID);
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <Paper className={classes.projectsListContainer}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography>{<Trans i18nKey="glossary:status" />}</Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  <Trans i18nKey="glossary:quote" />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  <Trans i18nKey="glossary:cost" />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  <Trans i18nKey="glossary:delivery" />
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projectsSummary
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(project => (
                <TableRow
                  style={{ cursor: 'pointer' }}
                  key={project.id}
                  onClick={() => {
                    redirectToProject(project.id);
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Chip label={project.orderstatus_name} color="primary" />
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {project.order_name ? project.order_name : project.id}
                    </Typography>
                  </TableCell>
                  <TableCell>
                  {(!userInfo.groups.includes('general_public') && !userInfo.groups.includes('candy')) &&
                    <Typography>
                      {project.total_cost && '€' + project.total_cost}
                    </Typography>}
                    {(userInfo.groups.includes('general_public') || userInfo.groups.includes('candy')) &&
                     <Typography>
                     {project.accepted_price && '£' + project.accepted_price}
                   </Typography>}
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {project.deadline_date ? (
                        <Moment format="D MMMM YYYY">
                          {project.deadline_date}
                        </Moment>
                      ) : null}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!projectsSummaryLoading & (projectsSummary.length === 0) ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          margin="2vmin"
        >
          <Typography variant="h6" color="primary">
            {props.isEmptyMessage}
          </Typography>
          {props.isEmptyButton && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                history.push(props.isEmptyButton.link);
              }}
            >
              {props.isEmptyButton.text}
            </Button>
          )}
        </Box>
      ) : null}
      {projectsSummaryLoading && (
        <Box display="flex" justifyContent="center" margin="2vmin">
          <CircularProgress size={60} />
        </Box>
      )}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={projectsSummary.length}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={t('common:rowsPerPage')}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        backIconButtonProps={{ color: 'secondary' }}
        nextIconButtonProps={{ color: 'secondary' }}
      />
    </Paper>
  );
}
