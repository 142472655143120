import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { Paper, Box, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer'
  },
  text: {
    alignSelf: 'flex-start'
  },
  count: {
    paddingLeft: '2vmin',
    paddingRight: '1vmin',
    marginLeft: 'auto'
  }
}));
export default function ProjectCard({
  title,
  subtitle,
  count,
  linkTo,
  className
}) {
  let history = useHistory();
  const classes = useStyles();

  const handleClick = () => {
    history.push(linkTo);
  };

  return (
    <Paper
      className={clsx(className, classes.root)}
      onClick={handleClick}
      elevation={4}
    >
      <Box display="flex" alignItems="center" padding="1vmin" height="100%">
        <section className={classes.text}>
          <Typography color="inherit" variant="h6">
            {title}
          </Typography>
          <Typography color="inherit" variant="subtitle1">
            {subtitle}
          </Typography>
        </section>
        <Typography color="inherit" variant="h4" className={classes.count}>
          {count}
        </Typography>
      </Box>
    </Paper>
  );
}
