import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CoreButton from '@material-ui/core/Button';

const useStyles = makeStyles({
    root: {
        margin: "1vmin",
        borderRadius: 0,
        transform: "skewX(-25deg)",
        "& > *":{
            color: "white",
            transform: "skewX(25deg)",
        }
    },
});

export default function Button(props) {
  const classes = useStyles();
  return <CoreButton className={classes.root} {...props} />;
}
