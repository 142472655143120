import React, {useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Paper, Typography} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    section:{
        display: "flex",
        flexDirection: "column",
        padding: "2vh",
        marginBottom: "2vh",
        minHeight: "50vh",
        minWidth: "40vw",
        maxWidth: "50vw",
        borderRadius: 0,
    },
}));
//minHeight: "50vh",

export default function QuoteSection({children, title, className}){ 
    const classes = useStyles();
    return (
        <Paper className={clsx(classes.section, className)}>
            <Typography color="primary" variant="h5" gutterBottom>
                <b >
                {title}
                </b>
            </Typography>
            { children }
        </Paper>
    )
}
