import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Box } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment-timezone';
import timezones from 'components/timezones';

const useStyles = makeStyles(theme => ({
  dateTimeContainerIE11Fallback: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  dateTimeContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1.5fr',
    gridGap: '1vmin',
    alignItems: 'flex-end'
  },
  timeZoneSelectIE11Fallback: {
    width: '20vmin'
  },
  timeZoneSelect: {
    marginBottom: 6
  }
}));

const findTimezoneName = id => {
  var i;
  for (i = 0; i < timezones.length; i++) {
    if (timezones[i].id === id) {
      return timezones[i].name;
    }
  }
  return '';
};

export default function DateTimeSelect({
  onDateTimeChange,
  onTimezoneChange,
  initalDateTime,
  initalTimezone,
  disabled
}) {
  const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
  const classes = useStyles();
  const [dateTime, setDateTime] = useState(moment().tz(moment.tz.guess()));
  const userLocalTimezone = moment.tz.guess();
  const [timezone, setTimezone] = useState({
    name: findTimezoneName(userLocalTimezone),
    id: userLocalTimezone
  });
  const updateDateTime = dateTime => {
    setDateTime(dateTime);
    //dirty hack to strip original timezone from moment
    onDateTimeChange(
      moment.tz(dateTime.format('YYYY-MM-DD HH:mm:ss'), timezone.id)
    );
  };
  useEffect(() => {
    if (initalDateTime !== undefined) {
      setDateTime(moment(initalDateTime));
    }
  }, [initalDateTime]);
  useEffect(() => {
    if (initalTimezone) {
      setTimezone(timezones.find(timezone => timezone.id === initalTimezone));
    }
  }, [initalTimezone]);
  useEffect(() => {
    onTimezoneChange(timezone.id);
    updateDateTime(dateTime);
  }, [timezone]);
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Box
        className={clsx(
          { [classes.dateTimeContainer]: !isIE11 },
          { [classes.dateTimeContainerIE11Fallback]: isIE11 }
        )}
      >
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          margin="normal"
          format="DD/MM/YYYY"
          id="date-picker-inline"
          label="Date"
          disabled={disabled}
          value={dateTime}
          onChange={updateDateTime}
          disablePast
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
        />
        <KeyboardTimePicker
          margin="normal"
          id="time-picker"
          label="Time"
          value={dateTime}
          ampm={false}
          disabled={disabled}
          onChange={updateDateTime}
          KeyboardButtonProps={{
            'aria-label': 'change time'
          }}
        />
        <Autocomplete
          className={clsx(classes.timeZoneSelect, {
            [classes.timeZoneSelectIE11Fallback]: isIE11
          })}
          options={timezones}
          getOptionLabel={zone => zone.name}
          value={timezone}
          disabled={disabled}
          onChange={(event, value) => setTimezone(value)}
          renderInput={params => (
            <TextField
              {...params}
              label="Time Zone"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </Box>
    </MuiPickersUtilsProvider>
  );
}
