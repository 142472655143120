import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
    root:{
        '& .MuiInputBase-root':{
            height: "100%",
            '& .MuiInputBase-input':{
                alignSelf: "baseline",
            },
        },
    },
});

export default function Note(props){
    const classes = useStyles();
    return(
        <TextField
            label="Notes"
            multiline
            variant="outlined"
            placeholder="Help the linguist translate the project exactly the way you want it."
            classes={{
                root: classes.root,
                input: classes.input,
            }}
            onChange={event=>{props.onChange(event.target.value)}}
            style={{flex: 1, marginBottom: "2vmin"}}
            value={props.currentNotes}
        />
    )
}
