import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography, Box, Button} from '@material-ui/core';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '0.5vmin',
    padding: '0.5vmin',
    borderRadius: '100%',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
}));

export default function AuthenticationCompleted({
  title,
  icon,
  message,
  buttonText,
  redirectTo,
}) {
  let history = useHistory();
  const classes = useStyles();
  const handleClick = () => {
    history.push(redirectTo);
  };
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography color="primary" variant="h2" gutterBottom>
        {title}
      </Typography>
      <Box display="flex" alignItems="center">
        <div className={classes.iconContainer}>{icon}</div>
        <Typography variant="body1">{message}</Typography>
      </Box>
      <Button variant="contained" color="primary" onClick={handleClick}>
        {buttonText}
      </Button>
    </Box>
  );
}
