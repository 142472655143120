import React, { useState, useEffect, useRef, useContext } from 'react';
import { UserContext } from 'components/UserContext';
import { useTranslation, Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import Amplify, { API } from 'aws-amplify';
import moment from 'moment';
import clsx from 'clsx';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Stepper,
  Step,
  StepLabel,
  Snackbar
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import {
  AccessTime,
  CalendarToday,
  Work,
  LocalCafe,
  SentimentVerySatisfied,
  Business
} from '@material-ui/icons';
import NotFound from 'views/generic/notfound';
import DropDownSection from 'components/dropDownSection';
import OrderLineItems from 'components/orderLineItems';
import LeanOrderLineItems from 'components/lean/leanOrderLineItems';
import FileList from 'components/fileList.js';
import Button from 'GVComponents/Button.js';

const useStyles = makeStyles(theme => ({
  projectContainer: {
    margin: '2vmin 10vmin'
  },
  mainSplitIE11Fallback: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 0,
    padding: '2vmin',
    '& > *': {
      marginTop: '2.5vmin'
    }
  },
  mainSplit: {
    display: 'grid',
    borderRadius: 0,
    padding: '2vmin',
    gridColumnGap: '5vmin',
    gridRowGap: '2.5vmin',
    gridTemplateAreas:
      '"header header" "info info" "status status" "languages uploaded" "notes uploaded"',
    gridTemplateColumns: '1fr 1fr'
  },
  infoSection: {
    gridArea: 'info',
    display: 'flex',
    flexDirection: 'row'
  },
  statusSection: {
    gridArea: 'status'
  },
  headerSection: {
    gridArea: 'header',
    borderBottom: '1px solid #dadce0'
  },
  noteSection: {
    gridArea: 'notes'
  },
  languagesSection: {
    gridArea: 'languages'
  },
  uploadedSection: {
    gridArea: 'uploaded'
  },
  notes: {
    border: 'solid 1px #dadce0',
    padding: '1vmin',
    minHeight: '10vh'
  },
  status: {
    padding: 0
  },
  stepDescription: {
    display: 'block',
    textAlign: 'center'
  },
  actionButtons: {
    marginLeft: 'auto'
  },
  total: { paddingRight: '1ch' },
  totalValue: { textDecoration: 'underline' }
}));

export default function Project() {
  const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
  const { t } = useTranslation();
  const classes = useStyles();
  let { id } = useParams();

  const userInfo = useContext(UserContext);

  const [statusStep, setStatusStep] = useState(0);
  const [projectSummary, setProjectSummary] = useState({});
  const [hash, setHash] = useState({});
  const ociFormEl = useRef(null);
  const [ociHookUrl, setOCIHookUrl] = useState('');
  const [ociItemFields, setOCIItemFields] = useState(null);
  const [toneIcons] = useState({
    1: <LocalCafe className={classes.toneIcon} />,
    2: <Work className={classes.toneIcon} />,
    3: <SentimentVerySatisfied className={classes.toneIcon} />,
    4: <Business className={classes.toneIcon} />
  });

  const [savingOpen, setSavingOpen] = React.useState(false);

  const handleSaving = () => {
    setSavingOpen(true);
  };

  const handleSavingClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSavingOpen(false);
  };

  const [savedOpen, setSavedOpen] = React.useState(false);

  const handleSaved = () => {
    setSavedOpen(true);
  };

  const handleSavedClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSavedOpen(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const getProjectSummary = async () => {
    const apiName = 'TransportAPI';
    const path = '/Test/order-services/salesorder/summary';
    const session = await Amplify.Auth.currentSession();
    const token = session.idToken.jwtToken;
    API.get(apiName, path, {
      headers: { Authorization: token },
      queryStringParameters: { orderid: id }
    })
      .then(response => {
        console.log(response);
        setProjectSummary(response[0]);
      })
      .catch(error => {
        console.log(error.response);
      });
  };

  const [quoteUpdate, setUpdatefield] = useState({
    salesorder_id: id,
    word_count: null,
    repitition_count: null,
    status_id: null,
    external_reference: '',
    quote_reference: '',
    creator_email: null
  });

  const setUpdate = (fieldID, value) => {
    quoteUpdate[fieldID] = value;
    setUpdatefield(quoteUpdate);
  };

  const updateQuoteExternalReference = async () => {
    const apiName = 'TransportAPI';
    const path = '/Test/order-services/salesorder/externalreference';
    let session = await Amplify.Auth.currentSession();
    let token = session.idToken.jwtToken;
    //  console.log(quoteUpdate);
    API.put(apiName, path, {
      body: quoteUpdate,
      headers: { Authorization: token }
    })
      .then(response => {
        //getLineItems();
      })
      .catch(error => {
        console.log(error.response);
      });
  };

  const contactPG = async () => {

    let session = await Amplify.Auth.currentSession();
    let token = session.idToken.jwtToken;

    const path = '/Test/payment-services/generatehash';
    const apiName = 'TransportAPI';

    let payBody = {
      //TIMESTAMP: '20200414000000',
      //MERCHANT_ID: 'globalvoices',
      //ACCOUNT: 'internet',
      salesorder_id: id,
      price : (Math.round(projectSummary.accepted_price * 100) / 100).toFixed(2),
      currency_code : 'EUR',
      // MERCHANT_RESPONSE_URL:'https://transport.globalvoices.co.uk',
      // HPP_TX_STATUS_URL: 'https://transport.globalvoices.co.uk',
      //PM_METHODS: 'cards|paypal|testpay'
      // AUTO_SETTLE_FLAG: '1',
      // HPP_CUSTOMER_COUNTRY:'UK',
      // HPP_CUSTOMER_FIRSTNAME:'Mark',
      // HPP_CUSTOMER_LASTNAME:'Squinto',


      // HPP_CUSTOMER_EMAIL: 'test@example.com',
      // HPP_CUSTOMER_PHONENUMBER_MOBILE:'44|789456123',
      // HPP_BILLING_STREET1:'Flat 123',
      // HPP_BILLING_STREET2:'House 456',
      // HPP_BILLING_STREET3:'Unit 4',
      // HPP_BILLING_CITY:'Halifax',
      // HPP_BILLING_POSTALCODE:'W5 9HR',
      // HPP_BILLING_COUNTRY:'826',
      // HPP_SHIPPING_STREET1:'Apartment 852',
      // HPP_SHIPPING_STREET2:'Complex 741',
      // HPP_SHIPPING_STREET3:'House 963',
      // HPP_SHIPPING_CITY:'Chicago',
      // HPP_SHIPPING_POSTALCODE:'50001',
      // HPP_SHIPPING_COUNTRY:'840',
      // HPP_ADDRESS_MATCH_INDICATOR:'FALSE',
      // HPP_CHALLENGE_REQUEST_INDICATOR:'NO_PREFERENCE',

      // BILLING_CODE:'59|123',
      // BILLING_CO:'GB',
      // SHIPPING_CODE:'50001|Apartment 852',
      // SHIPPING_CO:'US',
      // CUST_NUM:'6e027928-c477-4689-a45f-4e138a1f208a',
      // VAR_REF:'Acme Corporation',
      // PROD_ID:'SKU1000054',
    };
    API.post(apiName, path, {
      body: payBody,
      headers: { Authorization: token }
    })
    .then(response => {
        
        console.log (response);
        window.open(response);
       
    })

    .catch(error => {
      console.log ('ERROR');
      console.log(error);
    });


  };

  const updateStatus = async () => {
    const apiName = 'TransportAPI';
    const path = '/Test/order-services/salesorder/status';
    let session = await Amplify.Auth.currentSession();
    let token = session.idToken.jwtToken;
    //  console.log(quoteUpdate);

    handleSaving();

    setUpdate('creator_email', projectSummary.creator_email);

    if (projectSummary.orderstatus_name === 'Awaiting Authorisation') {
      updateQuoteExternalReference();
      setUpdate('status_id', 4);
    }

    if (projectSummary.orderstatus_name === 'Quote') {
      if (!userInfo.groups.includes ('candy')) {
          setUpdate('status_id', 9);
      }
      else
        {
        setUpdate('status_id', 4);
        }
    }

    API.put(apiName, path, {
      body: quoteUpdate,
      headers: { Authorization: token }
    })
      .then(response => {
        getProjectSummary();
        handleSaved();
      })
      .catch(error => {
        console.log(error.response);
      });
  };

  const setRejectStatus = async () => {
    const apiName = 'TransportAPI';
    const path = '/Test/order-services/salesorder/status';
    let session = await Amplify.Auth.currentSession();
    let token = session.idToken.jwtToken;
    //  console.log(quoteUpdate);

    handleSaving();

    setUpdate('creator_email', projectSummary.creator_email);

    setUpdate('status_id', 11);

    API.put(apiName, path, {
      body: quoteUpdate,
      headers: { Authorization: token }
    })
      .then(response => {
        getProjectSummary();
        handleSaved();
      })
      .catch(error => {
        console.log(error.response);
      });
  };

  const getOCIHookUrl = async () => {
    const apiName = 'TransportAPI';
    const path = '/Test/oci/hook';
    let session = await Amplify.Auth.currentSession();
    let token = session.idToken.jwtToken;
    await API.get(apiName, path, {
      headers: { Authorization: token }
    })
      .then(url => {
        setOCIHookUrl(url);
      })
      .catch(error => {
        console.log(error.response);
      });
  };
  const ociGetData = async () => {
    const apiName = 'TransportAPI';
    const path = '/Test/external-api';
    let session = await Amplify.Auth.currentSession();
    let token = session.idToken.jwtToken;
    API.get(apiName, path, {
      headers: { Authorization: token },
      queryStringParameters: { id: id }
    })
      .then(fields => {
        setOCIItemFields(fields[0]);
      })
      .catch(error => {
        console.log(error.response);
      });
  };

  const ociAddtoBasket = async () => {
    handleSaving();
    await getOCIHookUrl();
    ociGetData();
  };

  useEffect(() => {
    if (ociItemFields) {
      ociFormEl.current.submit();
      updateStatus();
    }
  }, [ociItemFields]);

  const validUUID = uuid => {
    let uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    if (uuidPattern.test(uuid)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (Object.entries(projectSummary).length !== 0) {
      return;
    }
    if (!validUUID(id)) {
      return;
    }
    getProjectSummary();
  }, []);

  //this wont work multilingual
  useEffect(() => {
    switch (projectSummary.orderstatus_name) {
      case 'Quote':
        setStatusStep(1);
        break;
      case 'Awaiting Authorisation':
        setStatusStep(2);
        break;
      case 'In Progress':
        setStatusStep(3);
        break;
      case 'Review':
        setStatusStep(4);
        break;
      case 'Completed':
        setStatusStep(6);
        break;
      default:
        break;
    }
  }, [projectSummary]);

  if (!validUUID(id)) {
    return <NotFound />;
  }

  return (
    <Box
      className={classes.projectContainer}
      display="flex"
      flexDirection="column"
    >
      <Snackbar
        open={savingOpen}
        autoHideDuration={6000}
        onClose={handleSavingClose}
      >
        <Alert onClose={handleSavingClose} severity="success">
          Saving
        </Alert>
      </Snackbar>
      <Snackbar
        open={savedOpen}
        autoHideDuration={6000}
        onClose={handleSavedClose}
      >
        <Alert onClose={handleSavedClose} severity="success">
          Saved
        </Alert>
      </Snackbar>

      <Typography variant="h2" color="secondary" gutterBottom>
        {projectSummary.ordertype_name}
        {(projectSummary.orderstatus_name === 'Quote Requested' ||
          projectSummary.orderstatus_name === 'Quote' ||
          projectSummary.orderstatus_name === 'Awaiting Authorisation') &&
          ' Quote'}
        {(projectSummary.orderstatus_name === 'In Progress' ||
          projectSummary.orderstatus_name === 'Awaiting Authorisation') &&
          ' Project'}
      </Typography>
      <Paper
        className={clsx(
          {
            [classes.mainSplitIE11Fallback]: isIE11
          },
          { [classes.mainSplit]: !isIE11 }
        )}
      >
        <section className={classes.headerSection}>
          <Box display="flex">
            <Typography variant="h3" color="primary" gutterBottom>
              {projectSummary.order_name ? projectSummary.order_name : id}
            </Typography>
            <Box className={classes.actionButtons}>
            {userInfo.groups.includes('general_public') ? 
            <Button
                  variant="contained"
                  color="secondary"
                  onClick={contactPG}
                >
                  Click to Pay
                </Button>
              : null }
              { userInfo.groups.includes('candy') &&  projectSummary.orderstatus_name === 'Quote' && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={updateStatus}
                >
                  Accept Quote
                </Button>
              )}
              { !userInfo.groups.includes('general_public') && !userInfo.groups.includes('candy') && projectSummary.orderstatus_name === 'Quote' && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={ociAddtoBasket}
                >
                  Add to Cart
                </Button>
              )}
              { !userInfo.groups.includes('general_public') && !userInfo.groups.includes('candy') && projectSummary.orderstatus_name === 'Quote' && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={setRejectStatus}
                >
                  Reject
                </Button>
              )}
              {/* {projectSummary.orderstatus_name === 'Awaiting Authorisation' && (
                <TextField
                  variant="outlined"
                  label="Purchase Order No"
                  onChange={event => {
                    setUpdate('external_reference', event.target.value);
                  }}
                ></TextField>
              )} */}
              { !userInfo.groups.includes('general_public') && !userInfo.groups.includes('candy') && projectSummary.orderstatus_name === 'Awaiting Authorisation' && (
                <Button
                  variant="contained"
                  color="secondary"

                >
                  <Typography>AWAITING AUTHORISATION</Typography>
                </Button>
              )}
            </Box>
          </Box>
        </section>
        <section className={classes.infoSection}>
          {projectSummary.tone_name && (
            <Chip label={projectSummary.tone_name} />
          )}
          {projectSummary.service_name && (
            <Chip label={projectSummary.service_name} />
          )}
        </section>
        <section className={classes.statusSection}>
          <Typography variant="h4" color="primary">
            Status
          </Typography>
          <Stepper
            className={classes.status}
            activeStep={statusStep}
            alternativeLabel
          >
            <Step color="secondary">
              <StepLabel
                optional={
                  <Typography
                    className={classes.stepDescription}
                    variant="caption"
                  >
                    Requested{' '}
                    {moment(projectSummary.created_date).format('D MMMM YYYY')}
                  </Typography>
                }
              >
                Quote Requested
              </StepLabel>
            </Step>
            <Step>
              <StepLabel>Quoted</StepLabel>
            </Step>
            <Step>
              <StepLabel>Pending Authorization</StepLabel>
            </Step>
            <Step>
              <StepLabel>Translation In Progress</StepLabel>
            </Step>
            <Step>
              <StepLabel>Review</StepLabel>
            </Step>
            <Step>
              <StepLabel>Completed</StepLabel>
              <Typography className={classes.stepDescription} variant="caption">
                {(projectSummary.orderstatus_name === 'Quote' ||
                  projectSummary.orderstatus_name ===
                    'Awaiting Authorisation' ||
                  projectSummary.orderstatus_name === 'In Progress' ||
                  projectSummary.orderstatus_name === 'Completed') &&
                  moment(projectSummary.deadline_date).format(
                    '[Expected] D MMMM YYYY [at] HH:mm'
                  )}
              </Typography>
            </Step>
          </Stepper>
        </section>
        <section className={classes.languagesSection}>
          <Typography variant="h4" color="primary">
            Languages
          </Typography>
          {(!userInfo.groups.includes('general_public') && !userInfo.groups.includes('candy')) &&
          <OrderLineItems orderId={id} /> }
          {(userInfo.groups.includes('general_public') || userInfo.groups.includes('candy')) &&
          <LeanOrderLineItems orderId={id} /> }
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
            padding="1vmin"
          >
            {projectSummary.total_cost !== null && (
              <React.Fragment>
                <Typography
                  variant="h5"
                  color="primary"
                  className={classes.total}
                >
                  {t('project:total')}
                </Typography>
                {(!userInfo.groups.includes('general_public') && !userInfo.groups.includes('candy')) &&
                <Typography variant="h6" className={classes.totalValue}>
                  {'€' + projectSummary.total_cost} 
                </Typography>}
                {(userInfo.groups.includes('general_public') || userInfo.groups.includes('candy')) &&
                <Typography variant="h6" className={classes.totalValue}>
                {'£' + projectSummary.accepted_price}
              </Typography> }
              </React.Fragment>
            )}
          </Box>
        </section>
        <section className={classes.notesSection}>
          <Typography variant="h4" color="primary">
            Notes
          </Typography>
          {projectSummary.notes && (
            <Typography className={classes.notes} variant="body1">
              {projectSummary.notes}
            </Typography>
          )}
          {projectSummary.notes === null && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              className={classes.notes}
            >
              <Typography variant="h6">No notes have been given</Typography>
            </Box>
          )}
        </section>
        <section className={classes.uploadedSection}>
          <Typography variant="h4" color="primary">
            Uploaded Files
          </Typography>
          <FileList orderId={id} type="source" />
        </section>
      </Paper>

      {(projectSummary.orderstatus_name === 'Quote' ||
        projectSummary.orderstatus_name === 'Awaiting Authorisation' ||
        projectSummary.orderstatus_name === 'In Progress' ||
        projectSummary.orderstatus_name === 'Review' ||
        projectSummary.orderstatus_name === 'Completed') && (
        <DropDownSection title="Quote Document" open>
          <FileList orderId={id} type="quote" />
        </DropDownSection>
      )}

      {projectSummary.orderstatus_name === 'Completed' && (
        <DropDownSection title="Translated Files" open>
          <FileList orderId={id} type="delivery" />
        </DropDownSection>
      )}
      <form action={ociHookUrl} method="post" ref={ociFormEl}>
        {ociItemFields &&
          Object.keys(ociItemFields).map(key => (
            <input
              key={key}
              type="hidden"
              name={key + '[1]'}
              value={ociItemFields[key]}
            />
          ))}
      </form>
    </Box>
  );
}
