import React, { useEffect } from 'react';

import { useTranslation, Trans } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import ProjectList from 'components/projectList.js';

const useStyles = makeStyles(() => ({
  container: {
    padding: '2vh 2vw'
  }
}));

export default function Projects() {
  useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography color="secondary" variant="h2" gutterBottom>
        <Trans i18nKey="glossary:quotes" />
      </Typography>
      <ProjectList
        lookupType="quote"
        isEmptyMessage={'You dont have any quotes yet'}
        isEmptyButton={{ text: 'Create a Quote', link: '/project/new' }}
      />
      <Typography color="secondary" variant="h2" gutterBottom>
        <Trans i18nKey="glossary:projects" />
      </Typography>
      <ProjectList
        lookupType="project"
        isEmptyMessage={'You dont have any projects'}
      />
    </Box>
  );
}
