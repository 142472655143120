import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Box, Typography, CircularProgress} from '@material-ui/core';
import {Refresh} from '@material-ui/icons';
import { useHistory } from "react-router-dom";
import Fab from 'GVComponents/Fab'
import Button from 'GVComponents/Button'

const useStyles = makeStyles(theme => ({
    retryButton: {
        marginTop: "2vmin",
    }
}))

export default function SubmitTracker({submitted, submitting, submitError, projectID}){
    const classes = useStyles();
    let history = useHistory();

    return(
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100%" height="100%">
            <Typography color="primary" variant="h3" gutterBottom>{submitted ? "Your quote request has been sent!" : submitError.length === 0 ? "Submitting" : "Somthing went wrong"}</Typography>
            {submitting && <CircularProgress color="secondary" size={140}/>}
            <Typography color="primary" variant="body1">{submitError}</Typography>
            { submitError.length > 0 &&
                <Fab className={classes.retryButton} color="secondary">
                    <Refresh/>
                </Fab>
            }
            { submitted &&
                <Box display="flex">
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={()=>{
                            history.push('/project/' + projectID)
                        }
                        }>
                        View Quote
                    </Button>
                    <Button variant="contained" color="secondary" onClick={()=>{history.push('/projects')}}>
                        Back to Quotes & Projects
                    </Button>
                </Box>
            }
        </Box>
    )
}
