import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Icon,
  Tooltip,
  CircularProgress
} from '@material-ui/core';
import clsx from 'clsx';

//make each expanding button its own container make the container take into account the hover explosion
const useStyles = makeStyles(theme => ({
  radioContainer: {
    height: '19vmin',
    cursor: 'pointer',
    '& > *:not(:last-child)': {
      marginRight: '1vmin'
    }
  },
  radioButtonContainer: {
    height: '19vmin',
    width: '19vmin'
  },
  item: {
    height: '14vmin',
    width: '14vmin',
    padding: '0.5vmin',
    color: 'white',
    backgroundColor: '#a8a9ad',
    boxShadow: '0px 3px 10px 0px rgba(0,0,0,0.75)',
    transition: '0.4s ease-out',
    '&:hover': {
      backgroundColor: '#f48335',
      height: '15vmin',
      width: '15vmin',
      transition: '0.1s'
    }
  },
  itemDisabled: {
    opacity: '0.5',
    filter: 'blur(2px)',
    '&:hover': {
      backgroundColor: '#a8a9ad',
      height: '14vmin',
      width: '14vmin'
    }
  },
  label: {
    marginTop: '1vmin',
    textAlign: 'center'
  },
  labelDisabled: {
    filter: 'blur(0.5px)'
  },
  //copy of hover tone. dont know how to not duplicate
  itemSelected: {
    backgroundColor: '#f48335',
    height: '15vmin',
    width: '15vmin',
    transition: '0.1s'
  },
  itemIcon: {
    fontSize: '2.5rem'
  }
}));

const GVTooltip = withStyles(theme => ({
  tooltip: {
    maxWidth: '25vmin',
    padding: '2vmin',
    borderRadius: 0,
    backgroundColor: '#f48335',
    color: 'white',
    fontSize: '1rem'
  }
}))(Tooltip);

export default function BigRadio(props) {
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState({});
  const [items, setItems] = useState([]);

  const updateSelectedItem = newSelectedItem => {
    if (newSelectedItem.disabled !== true) {
      if (selectedItem === newSelectedItem) {
        return;
      }
      setSelectedItem(newSelectedItem);

      props.onChange(newSelectedItem);
    }
  };

  useEffect(() => {
    setItems(props.items);
    setSelectedItem(props.selectedItem);
  }, [props.items, props.selectedItem]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="noWrap"
      justifyContent="space-between"
      alignItems="center"
      marginTop="auto"
      className={clsx(classes.radioContainer, props.className)}
    >
      {items.length === 0 && (
        <Box
          display="flex"
          justifyContent="center"
          margin="2vmin"
          width="100%"
          height="100%"
        >
          <CircularProgress size={80} />
        </Box>
      )}
      {items.map(item => (
        <GVTooltip
          key={item.id}
          title={item.tooltip && item.tooltip}
          placement="bottom-end"
          open={item.tooltip ? null : false}
        >
          <Box
            className={classes.radioButtonContainer}
            display="flex"
            flexDirection="column"
            alignItems="center"
            onClick={() => {
              updateSelectedItem(item);
            }}
          >
            <Box
              className={clsx(
                classes.item,
                {
                  [classes.itemSelected]:
                    selectedItem && item.id === selectedItem.id
                },
                {
                  [classes.itemDisabled]: item.disabled
                }
              )}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {typeof item.icon === 'string' ? (
                <Icon className={clsx(item.icon, classes.itemIcon)} />
              ) : (
                React.cloneElement(item.icon, { className: [classes.itemIcon] })
              )}
            </Box>
            <Typography
              className={clsx(classes.label, {
                [classes.labelDisabled]: item.disabled
              })}
              color="primary"
            >
              <b>{item.label}</b>
            </Typography>
          </Box>
        </GVTooltip>
      ))}
    </Box>
  );
}
